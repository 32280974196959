import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetPaginatedResupplyQuery } from '../../../app/services/admin/orders';
import Pagination from '../Pagination';
import { formatDate } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

const columnLabels = ['Date', 'User', 'Status', 'Next Shipment', 'Updated'];

function ResupplyList() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const { user } = useSelector((state) => state.auth);
    const { data: resupplyList, isFetching } = useGetPaginatedResupplyQuery(
        {
            userId: user?.id,
            skip: page * 10,
        },
        {
            skip: !user,
        },
    );
    return (
        <>
            <div
                className={`grid grid-cols-${columnLabels.length} text-left max-w-5xl`}
                key={`orders-table-labels`}>
                {columnLabels.map((label) => (
                    <p key={`resupply-label-${label}`} className="text-gray flex items-center">
                        {label}
                    </p>
                ))}
                {resupplyList?.results.length === 0 ? (
                    <p>There are no resupplies</p>
                ) : (
                    resupplyList?.results.map((result) => (
                        <div
                            onClick={() => {
                                navigate(`/admin/patients/${result.userId}/resupply`, {});
                            }}
                            className={`col-span-5 grid grid-cols-${columnLabels.length} text-left py-2 hover:cursor-pointer hover:bg-gray-darker`}
                            key={'resupply' + result.id}>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {formatDate(result.createdAt)}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                <span className="text-gray">{result.userId}</span>
                                {' ' + result.user?.firstName + ' ' + result.user?.lastName}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.enrollmentStatus}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.nextShipmentDate && formatDate(result.nextShipmentDate)}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.updatedAt && formatDate(result.updatedAt)}
                            </p>
                        </div>
                    ))
                )}
            </div>
            {resupplyList?.metadata.total > 10 && (
                <Pagination
                    page={page}
                    disabled={isFetching}
                    setPage={setPage}
                    totalPages={Math.floor(resupplyList.metadata.total / 10)}
                />
            )}
        </>
    );
}

export default ResupplyList;
