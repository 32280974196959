import { Formik, Form } from 'formik';
import { FiArrowUpRight } from 'react-icons/fi';
import { BigInput } from '.';

const TextInput = ({ validationSchema, submitHandler, placeholder, defaultValue, ...props }) => {
    return (
        <Formik
            initialValues={{ ipt: defaultValue }}
            validationSchema={validationSchema}
            onSubmit={(data) => {
                submitHandler(data.ipt);
            }}>
            {({ dirty, isValid }) => (
                <Form className="max-w-lg mx-auto mb-10 relative pb-1 lg:pb-2 flex items-end justify-center space-x-5">
                    <BigInput
                        name="ipt"
                        type="text"
                        placeholder={placeholder}
                        className="w-full max-w-[90%] lg:max-w-none"
                        {...props}
                        autoFocus
                    />
                    <button
                        type="submit"
                        disabled={(!dirty && defaultValue === '') || !isValid}
                        className="text-lg bg-green-400 rounded-full w-8 h-8 lg:w-12 lg:h-12 absolute right-0 bottom-2 lg:top-1/2 lg:-translate-y-1/2 disabled:opacity-50 translate-x-3 lg:translate-x-0">
                        <FiArrowUpRight className="absolute-center" />
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default TextInput;
