import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import bundleImage from '../../images/cpap/cpap-bundle.png';
import afterpayLogo from '../../images/AfterPayLogo.png';
import './style/BundleDescription.scss';

const BundleDescription = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalsOpen, pendingMaskFitReview } = useSelector((state) => state.ui);

    useEffect(() => {
        if (pendingMaskFitReview && modalsOpen.indexOf('sovaSageCompleted') === -1) {
            navigate('/dashboard/treatment/');
        }
    }, [pendingMaskFitReview, navigate, modalsOpen]);

    const BundleItems = [
        {
            name: 'CPAP Machine',
            price: {
                range: false,
                value: '$1,075.00',
            },
            description:
                'Choose from a variety of best-in-class CPAP machines. Available to ship immediately.',
            actions: [],
        },
        {
            name: 'Mask',
            price: {
                range: true,
                value: '$128.00 - $172.00',
            },
            description:
                "Use our virtual mask fitting tool to get a customized recommendation of the best mask type (full face, nasal or nasal pillow), size and brand for you. You'll get the complete mask system including mask frame, cushion and headgear.",
            actions: [
                <div className="mb-2">
                    <Link
                        className="border-b border-gem-green text-xs font-bold"
                        to="/mask-comparison"
                        target={`_blank`}>
                        View Mask Options
                    </Link>
                </div>,
            ],
        },
        {
            name: '90-Day Supply Replacement Parts',
            price: {
                range: true,
                value: '$120.00 - $185.00',
            },
            description:
                'Supplies include everything you need to use your CPAP for a full 90 days of treatment, including replacement cushions and filters.',
            actions: [],
        },
        {
            name: 'GEM Coaching',
            price: {
                range: true,
                value: 'FREE',
            },
            description:
                'You’ll have a dedicated GEM SLEEP Coach available to support you through the first 90 days of treatment.',
            actions: [],
        },
    ];

    return (
        <>
            <div className="mb-12 max-w-7xl lg:mb-20 lg:flex lg:items-start">
                <div className="mb-10 lg:mb-0 lg:w-1/2 lg:pr-24">
                    <img src={bundleImage} alt="GEM Bundle" />
                </div>

                <div className="lg:w-1/2">
                    <h3 className="font-heading text-5xl text-gray-darker lg:text-6xl">
                        GEM SLEEP CPAP STARTER BUNDLE
                    </h3>
                    <h3 className="mb-3 mt-4 font-heading text-4xl text-gray-darker">
                        HOW IT WORKS
                    </h3>

                    <p className="mb-6 text-sm lg:mb-7 lg:text-lg">
                        We make it easy to get started with CPAP by guiding you through picking
                        everything you need. And by ordering a complete bundle, you’ll receive 5%
                        off the individual retail price. You can also select from multiple payment
                        options, including applying insurance, using HSA/FSA, or create a payment
                        plan using
                        <img
                            className="inline w-[85px] lg:w-[100px]"
                            src={afterpayLogo}
                            alt=" Afterpay."
                        />
                    </p>

                    <div className="mb-8 flex items-center justify-between">
                        <button
                            className="btn-primary"
                            onClick={() => {
                                dispatch(toggleModal('maskFit'));
                            }}>
                            Build My Bundle
                        </button>
                        <div className="bundle-price-breakdown text-right">
                            <span className="font-heading text-3xl font-bold lg:text-3xl">
                                $940-$1,319 (5% discount)
                            </span>
                            <span className="block font-heading text-lg font-bold text-gray-six lg:text-lg">
                                RETAIL: $990-$1385
                                <br />
                                FREE SHIPPING
                            </span>
                            <div className="mt-2">
                                <Link
                                    to="/dashboard/resources/blog/gem-cpap-pricing"
                                    className="border-b border-gem-green text-xs font-bold">
                                    How do GEM's Prices Stack Up?
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col flex-wrap lg:flex-row gap-3 mb-3 tracking-wider text-sm lg:text-base font-heading leading-relaxed text-black">
                            <div>
                                <span className="rounded-xl bg-purple-lightest py-1 px-4 ">
                                    Payment plans available
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-gem-blue py-1 px-4 ">
                                    Free Shipping
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-gem-green py-1 px-4 ">
                                    Use FSA/HSA dollars
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-purple-fair py-1 px-4 ">
                                    Some insurance accepted
                                </span>
                            </div>
                        </div>
                    </div>

                    <section className="bundle-items">
                        <div className="items-title mb-4">
                            <h4 className="font-heading">WHAT'S INCLUDED:</h4>
                            <p>Bundled items can not be sold separately.</p>
                        </div>
                        <ul className="flex flex-col gap-y-5 pt-2">
                            {BundleItems.map((item, index) => (
                                <li className="bg-white" key={`bundle-item-${index}`}>
                                    <div className="item-card p-5">
                                        {item.label && <p className="item-label">{item.label}:</p>}
                                        <h4 className="item-name">{item.name}</h4>
                                        <p className="item-desc">{item.description}</p>
                                        {item.actions?.length > 0 && (
                                            <div className="bundle-item-actions mt-4 flex flex-col items-start">
                                                {item.actions.map((a, i) => (
                                                    <div key={`action-${index}-${i}`}>{a}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>
                </div>
            </div>
            <section>
                <div className="more-bundle-details max-w-7xl border-t border-b border-gray-medium py-6">
                    <h3 className="mb-3 font-heading text-3xl text-gray-darker lg:text-4xl">
                        MORE DETAILS
                    </h3>
                    <p>
                        The bundle includes a machine, your choice of mask system (including
                        cushions and headgear), as well as 90- day supply of replacement parts.
                        Don't worry we will help you find the right mask for you during the checkout
                        process.
                    </p>
                    <p>
                        Concerned about cost? GEM offers multiple payment options: use an HSA/FSA
                        card or create a payment plan using
                        <img
                            className="inline w-[90px] lg:w-[100px]"
                            src={afterpayLogo}
                            alt=" Afterpay"
                        />{' '}
                        (split the cost into 4 interest free payments). All GEM Masks come with a
                        30-day guarantee, if the first one does not work out, we will swap it out
                        for a different one, no cost to you.
                    </p>
                    <p>
                        Have insurance? You can add your insurance at checkout, we will provide an
                        estimate of what your plan will cover towards your CPAP bundle purchase.
                    </p>
                    <p>
                        Specific requirements of employers and/or health plans for managing Sleep
                        Apnea may vary.
                    </p>
                </div>
            </section>
        </>
    );
};

export default BundleDescription;
