import PatientFilter from './PatientFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
    deleteStatusFilter,
    setAvailableFilters,
    setFilterComponents,
    setStatusFilter,
} from '../../../features/admin/adminSlice';
import {
    useGetPartnerOrganizationsQuery,
    useGetPayersQuery,
} from '../../../app/services/insurance';
import { useGetCoachesQuery } from '../../../app/services/admin/careteam';
import { usePostAdminQueryMutation } from '../../../app/services/admin/search';
import Modal from '../../Modal/Modal';
import { otherFilters, patientStatusesWithOptions } from '../../../utils/constants';

const PatientFilters = ({ setPage, dataLoading }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const {
        patientFilterComponents,
        availablePatientFilters,
        activePatientFilters,
        careTeamOptions,
        payerOptions,
        partnersOptions,
    } = useSelector((state) => state.admin);
    //Iterate ID of each filter to have unique keys for removal
    const [filterId, setFilterId] = useState(1);

    //Get list of partners, payers, and careteam users
    const { isLoading: partnersLoading } = useGetPartnerOrganizationsQuery({ paginated: false });
    const { isLoading: payersLoading } = useGetPayersQuery();
    const { isLoading: careTeamLoading } = useGetCoachesQuery();

    //Query API call, name, and modal toggle
    const [postAdminQuery] = usePostAdminQueryMutation(activePatientFilters);
    const [queryName, setQueryName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [isPublic, setIsPublic] = useState(false);

    function loadAdditionalFilters() {
        dispatch(
            setAvailableFilters({
                key: 'availablePatientFilters',
                value: [
                    ...patientStatusesWithOptions,
                    {
                        available: true,
                        value: 'insurance',
                        label: 'Insurance',
                        statusOptions: payerOptions,
                    },
                    {
                        available: true,
                        value: 'careTeam',
                        label: 'Care Team',
                        statusOptions: careTeamOptions,
                    },
                    {
                        available: true,
                        value: 'referFromOrganization',
                        label: 'Refer From Organization',
                        statusOptions: partnersOptions,
                    },
                ],
            }),
        );
    }

    // Once isLoading is resolved, this will add partners to the available filters
    useEffect(() => {
        if (!partnersLoading && !payersLoading && !careTeamLoading) loadAdditionalFilters();
        // eslint-disable-next-line
    }, [partnersLoading, payersLoading, careTeamLoading]);

    function removeFilter(id) {
        //Find the filter to deleted
        const filterToDelete = patientFilterComponents.filter(
            (item, index) => item.filterId === id,
        );

        //If it exists, proceed with deleting it
        if (filterToDelete[0].status.value) {
            setPage(0);
            const newAvailableFilters = availablePatientFilters.filter((filter) => {
                if (filter !== filterToDelete[0].status) {
                    return filter;
                } else return null;
            });
            newAvailableFilters.push({ ...filterToDelete[0].status, available: true });
            //If the filter is not a patient profile search, set that search to null upon removal
            if (otherFilters.includes(filterToDelete[0].status.value)) {
                dispatch(
                    setStatusFilter({
                        type: 'activePatientFilters',
                        key: filterToDelete[0].status.value,
                        value: null,
                    }),
                );
                //Otherwise, call delete filter
            } else {
                dispatch(
                    deleteStatusFilter({
                        type: 'activePatientFilters',
                        key: 'patientProfileSearch',
                        value: filterToDelete[0].status.value,
                    }),
                );
            }
            //Set available filters to include the filter which was deleted
            dispatch(
                setAvailableFilters({ key: 'availablePatientFilters', value: newAvailableFilters }),
            );
        }

        //Create an array without the filter needing removal and set state to this new array
        const newStatusFilters = patientFilterComponents.filter(
            (item, index) => item.filterId !== id,
        );
        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: newStatusFilters }));
    }

    //Add the filter with an associated id from the state value, increment the state value so each has a unique id
    function addFilter() {
        const newFilters = [
            ...patientFilterComponents,
            {
                filterId: filterId,
                status: {},
                previousStatus: undefined,
                filters: [],
            },
        ];

        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: newFilters }));
        setFilterId(filterId + 1);
    }

    //Clears all patient filters and removes them from their array, resets available filters
    function clearFilters() {
        //Remove any admin filters that were set from the filters
        dispatch(
            setStatusFilter([
                {
                    type: 'activePatientFilters',
                    key: 'patientProfileSearch',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'careTeam',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'insurance',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'appointments',
                    value: null,
                },
                {
                    type: 'activePatientFilters',
                    key: 'state',
                    value: null,
                },
            ]),
        );
        //Set all status filters to be available
        loadAdditionalFilters();
        //Remove all status filter components
        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: [] }));
        setPage(0);
    }

    //Handle query submission then close modal
    const querySubmitHandler = async (ev) => {
        ev.preventDefault();
        await postAdminQuery({
            ...activePatientFilters,
            userId: user?.id,
            queryName: queryName,
            publicQuery: isPublic,
        });
        setModalOpen(false);
    };

    return (
        <>
            <div className="col-start-1 col-span-6">
                <p className="mb-3 font-semibold pl-2 pt-2 w-full">Filter by:</p>

                <div className="w-full flex flex-wrap items-center pl-2">
                    {patientFilterComponents.map((obj, index) => (
                        <div
                            className="basis-full lg:basis-1/2 flex flex-wrap items-center md:mb-2"
                            key={obj.filterId + 'div'}>
                            <PatientFilter
                                setPage={setPage}
                                key={obj.filterId + 'filter'}
                                id={obj.filterId}
                                index={index}
                                status={obj.status}
                                previousStatus={obj.previousStatus}
                                filters={obj.filters}
                            />
                            <button
                                className="ml-2 md:ml-0 md:basis-[6%] text-red font-bold"
                                key={obj.filterId + 'remove button'}
                                onClick={() => {
                                    removeFilter(obj.filterId);
                                }}>
                                X
                            </button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap items-center space-x-4 pl-2">
                    {
                        //Do not show more filters than can be set
                        patientFilterComponents.length < availablePatientFilters.length ? (
                            <button
                                //Disabled when querySearch is in use
                                disabled={activePatientFilters.querySearch}
                                onClick={addFilter}
                                className="btn-primary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                Add filter
                            </button>
                        ) : null
                    }
                    {
                        //Only show when a filter has been added
                        patientFilterComponents.length > 0 && !activePatientFilters.querySearch ? (
                            <>
                                <button
                                    disabled={dataLoading}
                                    onClick={() => clearFilters()}
                                    className="btn-secondary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                    Clear Filters
                                </button>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    className="btn-small bg-gray basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                    Save Query
                                </button>
                            </>
                        ) : null
                    }
                    <Modal
                        showClose={true}
                        isOpen={modalOpen}
                        closeHandler={() => {
                            setQueryName('');
                            setModalOpen(false);
                        }}>
                        <span className="font-heading font-bold text-3xl block">
                            SAVE YOUR CURRENT QUERY
                        </span>
                        <div className="flex p-2">
                            <input
                                type="checkbox"
                                onChange={() => setIsPublic(!isPublic)}
                                checked={isPublic}
                            />
                            <span className="font-heading text-xl block p-2">
                                Make this query Public
                            </span>
                        </div>
                        <form
                            onSubmit={querySubmitHandler}
                            className="relative w-full flex flex-wrap items-center mb-4">
                            <input
                                name="name"
                                type="text"
                                value={queryName}
                                onChange={(e) => setQueryName(e.currentTarget.value)}
                                placeholder="Enter a name for your query"
                                className="block basis-full sm:basis-[calc(50%-8px)] appearance-none border border-gray bg-transparent p-2 text-sm text-black mb-4 sm:mb-0 sm:mr-2"
                            />
                            <button
                                type="submit"
                                disabled={queryName?.length < 1}
                                className="btn-primary-small block basis-full sm:basis-[calc(50%-8px)] text-base lg:text-lg sm:ml-2">
                                Save Query
                            </button>
                        </form>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default PatientFilters;
