import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { IoCloseSharp } from 'react-icons/io5';
import { ReactComponent as FileUploadIcon } from '../../images/file-upload-icon.svg';
import { isValidUploadFile, returnFileSize } from '../../utils/constants'; //FileUploader
import UploadSpinner from '../UploadFile/UploadSpinner';
import axios from 'axios';

const UploadProductImages = ({
    uploadSuccessCallback,
    theme,
    multipleUploads = false,
    message = 'Click or drag and drop here to select your file to upload.',
}) => {
    const { storageToken } = useSelector((state) => state.auth);
    const formRef = useRef(null);
    const [progress, setProgress] = useState(0); //setProgress
    const [isUploading, setIsUploading] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false); //setUploadSuccess

    const onChangeHandler = (e) => {
        const files = Array.from(e.target.files).filter((file) => isValidUploadFile(file));
        if (!files) {
            setUploadError('Invalid file type.');
            return;
        }

        setUploadError(null);
        setCurrentFiles(files);
    };

    const submitHandler = async () => {
        if (currentFiles.length === 0) return;

        setIsUploading(true);

        const formData = new FormData();

        for (const key of Object.keys(currentFiles)) {
            formData.append('file', currentFiles[key]);
        }

        const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';

        try {
            const res = await axios.post(`${url}/files/public/${storageToken}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setUploadSuccess(true);
            uploadSuccessCallback(res.data.map((file) => file.Location));
            setProgress(100);

            setTimeout(() => {
                setCurrentFiles([]);
                setIsUploading(false);
                setUploadSuccess(false);
            }, 2000);
        } catch (error) {
            // TO DO: show error to admin
            setUploadError('an error occurred while uploading your file. Please contact support.');
        }
    };

    const removeFile = () => {
        setIsUploading(false);
        setCurrentFiles([]);
        formRef.current.elements.files.value = null;
    };

    return !storageToken ? (
        <div className="py-10 lg:py-14 px-8 relative border border-dashed border-black bg-white text-black">
            Storage token not found.
        </div>
    ) : (
        <>
            {uploadError && <p className="font-bold text-red text-sm mb-4">{uploadError}</p>}
            <div className="overflow-hidden" ref={formRef}>
                <div
                    className={`${
                        theme === 'slim' ? 'p-4 lg:py-8' : 'px-8 py-10 lg:py-14'
                    } relative border border-dashed border-black bg-white text-black group`}>
                    <input
                        className="block absolute w-full top-0 left-0 right-0 bottom-0 z-20 appearance-none opacity-0 cursor-pointer"
                        type="file"
                        name="files"
                        id="files"
                        accept=".pdf,.jpg,.png"
                        multiple={true}
                        onChange={onChangeHandler}
                    />
                    <AnimatePresence mode="wait">
                        {currentFiles.length === 0 && !isUploading && (
                            <motion.div
                                key="file-upload-section"
                                className="text-center relative z-10"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>
                                {theme !== 'slim' && (
                                    <FileUploadIcon className="inline-block mb-4" />
                                )}
                                {theme === 'slim' ? (
                                    <p className="text-lg hidden lg:block lg:mb-5">{message}</p>
                                ) : (
                                    <>
                                        <p className="transition font-bold text-green-400 text-xl lg:text-3xl mb-5 lg:mb-1 group-hover:text-green-hover">
                                            {`Select your files to upload`}
                                        </p>
                                        <p className="text-lg hidden lg:block lg:mb-5">{`or drag and drop them here.`}</p>
                                    </>
                                )}
                                <p className="text-xs lg:text-sm text-gray-six">
                                    Supported: PDF, JPG, PNG
                                </p>
                            </motion.div>
                        )}
                        {currentFiles.length > 0 &&
                            !isUploading &&
                            currentFiles.map((currentFile, idx) => (
                                <motion.div
                                    key={`file-info-section-${idx}`}
                                    className="flex items-center max-w-2xl mx-auto relative z-30"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <FileUploadIcon className="mr-4" />
                                    <p className="text-lg">
                                        {currentFile.name}{' '}
                                        <span className="text-gray-six text-sm">
                                            ({returnFileSize(currentFile.size)})
                                        </span>
                                    </p>

                                    <button
                                        type="button"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            removeFile();
                                        }}
                                        className="w-5 h-5 lg:w-8 lg:h-8 absolute right-0 top-1/2 -translate-y-1/2 rounded-full bg-salmon text-white text-center lg:text-xl">
                                        <IoCloseSharp className="absolute-center" />
                                    </button>
                                </motion.div>
                            ))}
                        {isUploading && (
                            <motion.div
                                key="file-upload-progress-section"
                                className="text-center relative z-30"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>                               
                                <div className="relative block w-[102px] mx-auto">
                                <UploadSpinner className="pointer-events-none" progress={progress}/>
                                    <span className="absolute-center font-heading text-4xl">
                                        {progress.toFixed(1)}%
                                    </span>
                                </div>

                                <p className="text-lg hidden lg:block lg:mb-5">
                                    {uploadError ? (
                                        <span className="text-red font-bold">Error!</span>
                                    ) : uploadSuccess ? (
                                        'Done.'
                                    ) : (
                                        'Uploading file...'
                                    )}
                                </p>

                                {!uploadSuccess && (
                                    <button
                                        className="btn-shell flex items-center lg:py-4 mx-auto"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            removeFile();
                                            setIsUploading(false);
                                            setUploadError(false);
                                        }}>
                                        <span className="text-sm">Cancel</span>
                                        <IoCloseSharp className="ml-3" />
                                    </button>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                {currentFiles.length > 0 && !isUploading && (
                    <button
                        className="btn-primary w-full mt-4 lg:mt-5"
                        type="button"
                        onClick={submitHandler}>
                        {'Upload Product Images'}
                    </button>
                )}
            </div>
        </>
    );
};

export default UploadProductImages;
