import { useSelector } from 'react-redux';
import { formatDate, formatSquarePrice, getVariationDataFromId } from '../../../../utils/constants';
import { useGetSquareOrdersQuery } from '../../../../app/services/admin/orders';

function formatSquareHistory(orderHistory, catalogItems) {
    if (!orderHistory || !catalogItems) return null;
    return orderHistory?.map((order) => ({
        ...order,
        lineItems: order?.lineItems?.map((orderItem) => ({
            ...orderItem,
            variationData: getVariationDataFromId(orderItem.variantId, catalogItems).data,
        })),
    }));
}

const SquareOrders = ({ patient }) => {
    const { catalogItems } = useSelector((state) => state.catalog);
    const { data: orderHistory, isLoading } = useGetSquareOrdersQuery(patient.id);

    const formattedHistory = formatSquareHistory(orderHistory, catalogItems);

    return isLoading ? (
        <p>Loading...</p>
    ) : !formattedHistory ? (
        <p>No Orders Found</p>
    ) : (
        <table className="table-fixed">
            <thead>
                <tr className="border-b">
                    <th className="w-1/6 p-2 text-left">Date</th>
                    <th className="w-1/6 p-2 text-left">Order #</th>
                    <th className="w-1/4 p-2 text-left">Items</th>
                    <th className="w-1/4 p-2 text-left">Price</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-dark text-sm">
                {formattedHistory?.length > 0 &&
                    formattedHistory
                        .filter((o) => o.tenders?.length > 0)
                        .map((order) => (
                            <tr key={order.id} className="even:bg-gray-darker">
                                <td className="p-2">{formatDate(order.createdAt)}</td>
                                <td className="p-2">{order.id}</td>
                                <td className="p-2">
                                    {order.lineItems?.length > 0 && (
                                        <ul className="list-disc space-y-6 pl-4">
                                            {order.lineItems.map((item) => (
                                                <li key={item.uid} className="space-y-2">
                                                    <p>{item.name}</p>
                                                    <p>
                                                        <span className="text-gray-light">
                                                            Catalog ID:
                                                        </span>{' '}
                                                        {item.catalogObjectId}
                                                    </p>
                                                    <p>
                                                        <span className="text-gray-light">
                                                            SKU:
                                                        </span>
                                                        :{' '}
                                                        {
                                                            item.variationData?.variation
                                                                ?.itemVariationData?.sku
                                                        }
                                                    </p>
                                                    {item.variationData?.variation
                                                        ?.customAttributeValues && (
                                                        <ul className="mt-2 space-y-2">
                                                            {Object.values(
                                                                item.variationData?.variation
                                                                    ?.customAttributeValues,
                                                            )?.map((att) => (
                                                                <li key={att.key}>
                                                                    <span className="text-gray-light">
                                                                        {att.name}:
                                                                    </span>{' '}
                                                                    {att.stringValue}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </td>
                                <td className="p-2">
                                    {(order?.metadata?.orderType === 'INSURANCE' ||
                                        order?.metadata?.orderType === 'COST_SHARING') && (
                                        <p>
                                            <span className="mb-3 inline-block rounded-full bg-gem-green px-3 py-1 font-bold">
                                                Used Insurance
                                            </span>
                                        </p>
                                    )}
                                    <p>Total: ${formatSquarePrice(order.totalMoney.amount)}</p>
                                    <p>Tax: ${formatSquarePrice(order.totalTaxMoney.amount)}</p>
                                </td>
                            </tr>
                        ))}
            </tbody>
        </table>
    );
};

export default SquareOrders;
