import { useNavigate } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import {
    colorByDateUpdated,
    colorInsuranceStatus,
    determineItemCategoryTags,
    determineOrderCategories,
    estimateStatuses,
    formatDate,
    priorAuthStatuses,
} from '../../../utils/constants';

const statusesToColor = [
    'ESTIMATE_NEEDED',
    'ESTIMATE_PENDING',
    'ESTIMATE_WITH_PATIENT',
    'ESTIMATE_PATIENT_APPROVED',
    'PRIOR_AUTH_AFTER_CONSULT',
    'PRIOR_AUTH_NEEDED',
    'PRIOR_AUTH_DENIED',
    'PRIOR_AUTH_PENDING',
    'SUPPLIES_ORDERED',
    'FULFILLMENT_ORDER',
    'SHIPPED',
    'STUDY_ORDERED',
    'ITAMAR_ACCOUNT_CREATED',
    'HST_FULFILLMENT_ORDERED',
    'HST_FULFILLMENT_SHIPPED',
    'HST_FULFILLMENT_DELIVERED',
    'SERIAL_NUMBER_ADDED',
    'STUDY_COMPLETED',
    'REORDER',
    'HST_RECEIVED_IN_OFFICE',
];

const AdminOrderListItem = ({ order, patientId, gridLayout }) => {
    const navigate = useNavigate();

    const { isCpapOrder, isHSTOrder, isSuppliesOrder } = determineOrderCategories(order);

    const itemTypes = determineItemCategoryTags(order);

    return (
        <div
            key={'order-list-item-' + order.id}
            onClick={() => {
                navigate(`/admin/patients/${order.userId}/orders/${order.id}`, {
                    state: {
                        from: patientId
                            ? `/admin/patients/${patientId}/orderHistory`
                            : `/admin/orders`,
                    },
                });
            }}
            className={`w-[1270px] hover:cursor-pointer relative grid ${gridLayout} items-center text-white text-left border-b border-purple-light/30`}>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {format(order.orderDate, 'MM/dd/yyyy')}
            </p>
            {!patientId && (
                <div
                    className={`daisy-tooltip daisy-tooltip-right text-left`}
                    data-tip={order.user.firstName + ' ' + order.user.lastName}>
                    <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                        <span className="text-gray">{order.userId}</span>{' '}
                        {order.user.firstName + ' ' + order.user.lastName}
                    </p>
                </div>
            )}
            <p className="py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                {order.orderType}
            </p>
            <p className="py-4 whitespace-nowrap overflow-hidden text-ellipsis">{itemTypes}</p>
            {/* <p className="whitespace-nowrap overflow-hidden text-ellipsis">Shipping</p> */}
            <p className="py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                {formatDate(order.updatedAt)}
            </p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{order.id}</p>
            {!patientId && (
                <>
                    <div
                        className={`daisy-tooltip daisy-tooltip-right text-left`}
                        data-tip={order.insurance?.payer.name}>
                        <p className="py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                            {order.insurance && order.insurance?.payer.name}
                        </p>
                    </div>

                    <p className="py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                        {colorInsuranceStatus(order.insurance && order.insurance?.insuranceStatus)}
                    </p>
                </>
            )}
            <div
                className={`daisy-tooltip daisy-tooltip-left text-left`}
                data-tip={formatDistance(order.updatedAt, new Date()) + ' since last updated'}>
                <p
                    className={`py-4 whitespace-nowrap overflow-hidden text-ellipsis ${statusesToColor.includes(order.estimateStatus) && colorByDateUpdated(order.updatedAt)}`}>
                    {order.estimateStatus ? (
                        estimateStatuses.find((item) => item.value === order.estimateStatus).label
                    ) : (
                        <span className="text-gray">Not Required</span>
                    )}
                </p>
            </div>
            <div
                className={`daisy-tooltip daisy-tooltip-left text-left`}
                data-tip={formatDistance(order.updatedAt, new Date()) + ' since last updated'}>
                <p
                    className={`py-4 whitespace-nowrap overflow-hidden text-ellipsis ${statusesToColor.includes(order.priorAuthStatus) && colorByDateUpdated(order.updatedAt)}`}>
                    {order.priorAuthStatus ? (
                        priorAuthStatuses.find((item) => item.value === order.priorAuthStatus).label
                    ) : (
                        <span className="text-gray">Not Indicated</span>
                    )}
                </p>
            </div>
            <div
                className={`daisy-tooltip daisy-tooltip-left text-left`}
                data-tip={
                    formatDistance(order.user.patientProfile.updatedAt, new Date()) +
                    ' since last updated'
                }>
                <p
                    className={`py-4 whitespace-nowrap overflow-hidden text-ellipsis ${
                        ((isCpapOrder &&
                            statusesToColor.includes(order.user.patientProfile.cpapOrderStatus)) ||
                            (isHSTOrder &&
                                statusesToColor.includes(
                                    order.user.patientProfile.homeSleepStudyStatus,
                                ))) &&
                        colorByDateUpdated(order.user.patientProfile.updatedAt)
                    }`}>
                    {isCpapOrder
                        ? order.user.patientProfile.cpapOrderStatus
                        : isHSTOrder
                          ? order.user.patientProfile.homeSleepStudyStatus
                          : isSuppliesOrder
                            ? order.user.patientProfile.supplyOrdersStatus
                            : null}
                </p>
            </div>
        </div>
    );
};

export default AdminOrderListItem;
