import { Field, Form, Formik } from 'formik';
import { CustomCheckbox, CustomInput } from '../../InputComponents';
import { createAdminUserSchema } from '../../../utils/schemas';
import { useUpdateAdminUserMutation } from '../../../app/services/admin/admin';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminUserInfo = ({ adminUser }) => {
    const navigate = useNavigate();
    const [updateAdminUser] = useUpdateAdminUserMutation();

    const initialValues = adminUser
        ? {
              firstName: adminUser.firstName,
              lastName: adminUser.lastName,
              email: adminUser.unverifiedEmail || adminUser.email,
              phone: adminUser.phone ?? '',
              textNumber: adminUser.textNumber ?? '',
              organization: adminUser.organization ?? '',
              credentials: adminUser.credentials,
              imageUrl: adminUser.imageUrl,
              quote: adminUser.quote,
              roleMD: adminUser.userRoles.some((r) => r.role === 'MD'),
              roleCoach: adminUser.userRoles.some((r) => r.role === 'COACH'),
          }
        : undefined;

    const submitHandler = async (formData) => {
        formData.userRoles = [];
        if (formData.roleMD) formData.userRoles.push({ role: 'MD' });
        if (formData.roleCoach) formData.userRoles.push({ role: 'COACH' });
        await updateAdminUser({ id: adminUser.id, ...formData });
        navigate('/admin/administrators');
        toast.success('Submitted!', { theme: 'dark' });
    };

    return (
        <>
            <p className="mb-4 text-xl font-bold">User info:</p>
            <Formik
                validationSchema={createAdminUserSchema}
                initialValues={initialValues}
                onSubmit={submitHandler}
                enableReinitialize={true}>
                {({ dirty, isSubmitting, isValid }) => (
                    <Form>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">First name</span>
                                <CustomInput
                                    name="firstName"
                                    label="First Name"
                                    rootClass="flex-1"
                                />
                            </div>
                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Last name</span>
                                <CustomInput name="lastName" label="Last Name" rootClass="flex-1" />
                            </div>
                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">
                                    {adminUser.unverifiedEmail ? 'Email (unverified)' : 'Email'}
                                </span>
                                <CustomInput
                                    type="email"
                                    name="email"
                                    label="Email"
                                    autoComplete="username"
                                    rootClass="flex-1"
                                />
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Organization</span>
                                <CustomInput
                                    name="organization"
                                    label="Organization"
                                    rootClass="flex-1"
                                />
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Phone(Voice)</span>
                                <CustomInput name="phone" label="Phone(Voice)" rootClass="flex-1" />
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Phone(SMS/text)</span>
                                <CustomInput
                                    name="textNumber"
                                    label="Phone(SMS/text)"
                                    rootClass="flex-1"
                                />
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Credentials</span>
                                <CustomInput
                                    name="credentials"
                                    label="Credentials"
                                    rootClass="flex-1"
                                />
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Image URL</span>
                                <CustomInput name="imageUrl" label="Image Url" rootClass="flex-1" />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 pt-4 ">
                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Quote</span>
                                <Field
                                    as="textarea"
                                    id="quote"
                                    name="quote"
                                    className="mb-8 h-40 w-full border border-white bg-gray-darker p-5 lg:mb-12 lg:px-6"
                                    placeholder="Enter quote"></Field>
                            </div>

                            <div className="flex items-center space-x-4">
                                <span className="w-32 font-heading text-gray">Roles</span>
                                <CustomCheckbox name="roleMD" id="roleMD" label="MD" />
                                <CustomCheckbox name="roleCoach" id="roleCoach" label="COACH" />
                            </div>
                            <div className="flex items-center space-x-4"></div>
                        </div>
                        <div className="grid grid-cols-1 justify-items-center">
                            <button
                                className="btn-primary mt-6 w-4/5 "
                                type="submit"
                                disabled={!dirty || !isValid || isSubmitting}>
                                Update User
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AdminUserInfo;
