import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const AccountCreationSuccess = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.fromSaveAssessment) {
            ReactGA.event({
                category: 'User',
                action: 'Created an account',
            });
        }
    }, [location.state?.fromSaveAssessment]);

    return (
        <div className="container max-w-2xl lg:mb-24">
            <h2 className="mb-6 font-heading text-[44px] text-offwhite lg:text-7xl">
                <span>Check your email to confirm your account.</span>
            </h2>
            <p className="mb-4 leading-normal text-gray-light lg:text-xl">
                You’ve set up your profile. Check your inbox to validate your email address. (We
                want to be sure it's really you!) Once you validate your email address, you will be
                directed to log in.
            </p>
        </div>
    );
};

export default AccountCreationSuccess;
