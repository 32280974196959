import { useDispatch, useSelector } from 'react-redux';
import { useAddToCartMutation } from '../../app/services/cart';
import { toggleCart } from '../../features/ui/uiSlice';
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg';
import { useInsuranceContext } from '../Insurance';

const DMETherapy = () => {
    const dispatch = useDispatch();
    const [addToCart] = useAddToCartMutation();
    const { insurance } = useInsuranceContext();
    const { user } = useSelector((state) => state.auth);

    const inDMETreatment = user?.patientProfile?.treatments.find((treatment) => treatment.isActive);

    return (
        <div className="max-w-xl mb-4">
            <h4 className="mb-2 lg:mb-7 flex items-center font-heading text-3xl lg:text-5xl">
                <span className="mr-4">cpap therapy</span>
            </h4>
            <h5 className="mb-2 flex items-center font-heading text-xl lg:text-3xl">
                {inDMETreatment
                    ? `You're currently in CPAP treatment with ${insurance?.payer.dmeReferral.name}.`
                    : `You've been referred to ${insurance?.payer.dmeReferral.name}`}
            </h5>
            <p className="mb-2 lg:mb-4">
                GEM SLEEP will remain your sleep provider during treatment. If you need a follow-up
                visit with a GEM clinician, please find a time below.{' '}
            </p>
            <button
                className="btn-primary w-full lg:w-auto flex items-center space-x-2"
                onClick={() => {
                    addToCart([
                        {
                            quantity: 1,
                            sku: 'COMPLIANCE',
                            single: true,
                        },
                    ]);

                    dispatch(toggleCart(true));
                }}>
                <span>Schedule an appointment</span>
                <CalendarIcon />
            </button>
        </div>
    );
};

export default DMETherapy;
