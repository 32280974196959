import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
    useCreatePatientInsuranceMutation,
    useGetPayersQuery,
    useUpdatePatientInsuranceMutation,
} from '../../../app/services/insurance';
import { useGetInsurancesQuery } from '../../../app/services/admin/insurance';
import { Modal } from '../..';
import { formatDate } from '../../../utils/constants';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { ReactComponent as Download } from '../../../images/icons/download.svg';

const fieldError = ({ children }) => <p className="ml-4 text-sm font-bold text-red">{children}</p>;

const colorInsuranceStatus = (status) => {
    switch (status) {
        case 'Active':
            return 'font-bold text-green-400';
        case 'Inactive':
            return 'font-bold text-gray';
        case 'Review':
            return 'font-bold text-yellow';
        case 'Failed':
            return 'font-bold text-red';
        default:
            return 'font-bold text-gray';
    }
};

const relationshipTypes = ['Self', 'Spouse', 'Employer', 'Parent'];
const genderTypes = ['Male', 'Female'];

const createInsuranceSchema = yup.object().shape({
    payerId: yup.string().required('Required'),
    planId: yup.string().notRequired(),
    insuranceStatus: yup.string().notRequired(),
    memberId: yup.string().notRequired(),
    groupId: yup.string().notRequired(),
    effectiveDate: yup.string().notRequired(),
    terminationDate: yup.string().notRequired(),
    policyHolderRelationship: yup.string().required('Required').oneOf(relationshipTypes),
    policyHolderFirstName: yup.string().when('policyHolderRelationship', {
        is: (val) => val !== 'Self' && val,
        then: (schema) => schema.required('Required'),
    }),
    policyHolderLastName: yup.string().when('policyHolderRelationship', {
        is: (val) => val !== 'Self' && val,
        then: (schema) => schema.required('Required'),
    }),
    policyHolderGender: yup.string().when('policyHolderRelationship', {
        is: (val) => val !== 'Self' && val,
        then: (schema) => schema.required('Required').oneOf(genderTypes),
    }),
    policyHolderDob: yup.string().when('policyHolderRelationship', {
        is: (val) => val !== 'Self' && val,
        then: (schema) => schema.required('Required'),
    }),
});

const InsuranceCardModal = ({ modalOpen, setModalOpen, imageUrl, downloadUrl }) => {
    // display image in modal
    return (
        <Modal
            showClose={true}
            isOpen={modalOpen}
            closeHandler={() => setModalOpen(false)}
            width="w-[280px] lg:w-[550px]">
            {imageUrl?.includes('.pdf') ? (
                <iframe
                    src={imageUrl}
                    height="500px"
                    width="100%"
                    title="insurance_card_image"
                    className="my-4"
                />
            ) : (
                <Zoom>
                    <img
                        src={imageUrl}
                        alt="insurance card"
                        className="max-w-[250px] lg:max-w-[450px] my-4"
                    />
                </Zoom>
            )}
            <a
                className="border-white-500 file-download-btn inline-flex items-center rounded border bg-transparent py-1 px-3 text-base"
                href={downloadUrl}
                download
                target="_blank"
                rel="noreferrer">
                Download
                <Download />
            </a>
        </Modal>
    );
};

const PatientInsurance = ({ patient }) => {
    const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';
    const { storageToken } = useSelector((state) => state.auth);
    const { data: insurances, isLoading } = useGetInsurancesQuery(patient.id);
    const [updatePatientInsurance, { isSuccess: updateSuccess, error: updateError }] =
        useUpdatePatientInsuranceMutation();
    const [createPatientInsurance, { isSuccess: createSuccess, error: createError }] =
        useCreatePatientInsuranceMutation();
    const { data: payers } = useGetPayersQuery();
    const [editingInsurance, setEditingInsurance] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState(null);

    useEffect(() => {
        if (updateSuccess || createSuccess) {
            toast.success('Patient Insurance Saved', {
                theme: 'colored',
            });
            setModalOpen(false);
        }
    }, [updateSuccess, createSuccess]);

    const handleError = (error) => {
        if (error) {
            if (error.data) {
                toast.error(error.data.message, { theme: 'colored' });
            } else {
                toast.error('Error Adding/Modifying Insurance', { theme: 'colored' });
            }
        }
    };

    useEffect(() => {
        handleError(updateError ? updateError : createError);
    }, [updateError, createError]);

    const insuranceStatuses = ['Active', 'Inactive', 'Review', 'Failed'];

    if (isLoading) return <div>Loading...</div>;

    return (
        <div>
            <header className="mb-6 flex justify-between space-x-2">
                <h2 className="font-heading text-3xl lg:text-5xl">Patient Insurance</h2>
                <button
                    className="btn-primary py-2 px-2 sm:px-4"
                    onClick={() => {
                        setEditingInsurance(null);
                        setModalOpen(true);
                    }}>
                    Add New Insurance
                </button>
            </header>
            {insurances && insurances.length > 0 ? (
                <ul className="grid max-w-[1000px] grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4  opacity-100 transition">
                    {insurances.map((insurance, idx) => (
                        <li
                            key={`insurance-${insurance.id}`}
                            className="relative rounded-lg border border-gray p-2 min-w-[250px]">
                            <p>
                                <span className="text-gray-light">Payer: </span>
                                <span className="font-bold">{insurance.payer.name}</span>
                            </p>
                            <p>
                                <span className="text-gray-light">Other Payer Name: </span>
                                <span className="font-bold">{insurance.otherPayerName}</span>
                            </p>
                            <p>
                                <span className="text-gray-light">Plan Name: </span>
                                <span className="font-bold">
                                    {insurance.plan ? insurance.plan.name : 'Unknown'}
                                </span>
                            </p>
                            <p>
                                <span className="text-gray-light">Status: </span>
                                <span className={colorInsuranceStatus(insurance.insuranceStatus)}>
                                    {insurance.insuranceStatus}
                                </span>
                            </p>
                            <p>
                                <span className="text-gray-light">Member ID: </span>
                                <span className="font-bold">{insurance.memberId}</span>
                            </p>
                            <p>
                                <span className="text-gray-light">Group ID: </span>
                                <span className="font-bold">{insurance.groupId}</span>
                            </p>
                            <p>
                                <span className="text-gray-light">Effective Date: </span>
                                <span className="font-bold">
                                    {formatDate(insurance.effectiveDate)}
                                </span>
                            </p>

                            <p>
                                <span className="text-gray-light">Termination Date: </span>
                                <span className="font-bold">
                                    {formatDate(insurance.terminationDate)}
                                </span>
                            </p>
                            <br />
                            <p>
                                <span className="text-gray-light">Policy Holder: </span>
                                <span className="font-bold">
                                    {insurance.policyHolderRelationship}
                                </span>
                            </p>
                            {insurance.policyHolderRelationship !== 'Self' && (
                                <>
                                    <p>
                                        <span className="text-gray-light">
                                            Policy Holder Name:{' '}
                                        </span>
                                        <span className="font-bold">
                                            {insurance.policyHolderFirstName}{' '}
                                            {insurance.policyHolderLastName}
                                        </span>
                                    </p>
                                    <p>
                                        <span className="text-gray-light">
                                            Policy Holder Gender:{' '}
                                        </span>
                                        <span className="font-bold">
                                            {insurance.policyHolderGender}
                                        </span>
                                    </p>
                                    <p>
                                        <span className="text-gray-light">Policy Holder DOB: </span>
                                        <span className="font-bold">
                                            {formatDate(insurance.policyHolderDob)}
                                        </span>
                                    </p>
                                </>
                            )}
                            <div className="flex space-x-2 py-2">
                                {insurance.cardImageFrontId && (
                                    <button
                                        className="btn-primary block px-2 py-4 text-left"
                                        onClick={async () => {
                                            const response = await axios.get(
                                                `${url}/files/${insurance.cardImageFrontId}/url?storageToken=${storageToken}`,
                                            );
                                            setDownloadUrl(
                                                `${url}/files/${insurance.cardImageFrontId}?storageToken=${storageToken}`,
                                            );
                                            setImageUrl(response.data.url);
                                            setInsuranceModalOpen(true);
                                        }}>
                                        Show Card Front
                                    </button>
                                )}
                                {insurance.cardImageBackId && (
                                    <button
                                        className="btn-primary block px-2 py-4 text-left"
                                        onClick={async () => {
                                            const response = await axios.get(
                                                `${url}/files/${insurance.cardImageBackId}/url?storageToken=${storageToken}`,
                                            );
                                            setDownloadUrl(
                                                `${url}/files/${insurance.cardImageBackId}?storageToken=${storageToken}`,
                                            );
                                            setImageUrl(response.data.url);
                                            setInsuranceModalOpen(true);
                                        }}>
                                        Show Card Back
                                    </button>
                                )}
                            </div>
                            <button
                                className="btn-primary block p-4 text-left"
                                onClick={() => {
                                    setEditingInsurance(insurance);
                                    setModalOpen(true);
                                }}>
                                Edit
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="font-heading text-3xl">No insurances found for this patient.</p>
            )}
            <Modal showClose={true} isOpen={modalOpen} closeHandler={() => setModalOpen(false)}>
                <h2 className="mb-4 text-3xl font-bold">{`${
                    editingInsurance ? 'Edit' : 'Add'
                } Insurance`}</h2>
                <Formik
                    validationSchema={createInsuranceSchema}
                    initialValues={{
                        payerId: editingInsurance?.payer?.id || '',
                        otherPayerName: editingInsurance?.payer?.otherPayerName || '',
                        planId: editingInsurance?.plan?.id || '',
                        insuranceStatus: editingInsurance?.insuranceStatus || 'Review',
                        memberId: editingInsurance?.memberId || '',
                        groupId: editingInsurance?.groupId || '',
                        effectiveDate: editingInsurance?.effectiveDate
                            ? formatDate(editingInsurance?.effectiveDate, true)
                            : '',
                        terminationDate: editingInsurance?.terminationDate
                            ? formatDate(editingInsurance?.terminationDate, true)
                            : '',
                        policyHolderRelationship: editingInsurance?.policyHolderRelationship,
                        policyHolderFirstName: editingInsurance?.policyHolderFirstName || '',
                        policyHolderLastName: editingInsurance?.policyHolderLastName || '',
                        policyHolderGender: editingInsurance?.policyHolderGender || '',
                        policyHolderDob: editingInsurance?.policyHolderDob
                            ? formatDate(editingInsurance?.policyHolderDob, true)
                            : '',
                        multiplan: editingInsurance?.multiplan || false,
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        const {
                            payerId,
                            planId,
                            effectiveDate,
                            terminationDate,
                            policyHolderDob,
                            ...rest
                        } = values;

                        const payload = {
                            payerId: parseInt(payerId),
                            planId: parseInt(planId),
                            effectiveDate:
                                (effectiveDate.length && new Date(effectiveDate).toISOString()) ||
                                undefined,
                            terminationDate:
                                (terminationDate && new Date(terminationDate).toISOString()) ||
                                undefined,
                            policyHolderDob:
                                (policyHolderDob && new Date(policyHolderDob).toISOString()) ||
                                undefined,
                            ...rest,
                        };

                        if (editingInsurance) {
                            //update
                            updatePatientInsurance({
                                userId: patient.id,
                                insuranceId: editingInsurance.id,
                                data: payload,
                            });
                        } else {
                            //create
                            createPatientInsurance({
                                userId: patient.id,
                                data: payload,
                            });
                        }

                        actions.setSubmitting(false);
                    }}>
                    {({ isSubmitting, values }) => (
                        <Form className="flex flex-col gap-4">
                            <div>
                                <label>Payer</label>
                                <Field
                                    name="payerId"
                                    as="select"
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow">
                                    <option value="">Select a payer</option>
                                    {payers &&
                                        payers?.map((payer, index) => (
                                            <option key={`payer-${index}`} value={payer.id}>
                                                {payer.name}
                                            </option>
                                        ))}
                                </Field>
                                <ErrorMessage component={fieldError} name="payerId" />
                            </div>

                            <div>
                                <label>Plan ID</label>
                                <Field
                                    name="planId"
                                    readOnly
                                    disabled
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                />
                            </div>

                            {parseInt(values.payerId) === 11 && (
                                <div>
                                    <label>Other Payer Name</label>
                                    <Field
                                        name="otherPayerName"
                                        className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                    />
                                    <ErrorMessage component={fieldError} name="otherPayerName" />
                                </div>
                            )}

                            <div>
                                <label>Insurance Status</label>
                                <Field
                                    name="insuranceStatus"
                                    as="select"
                                    readOnly
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow">
                                    <option value="">Select a status</option>
                                    {insuranceStatuses &&
                                        insuranceStatuses?.map((status, index) => (
                                            <option key={`status-${index}`} value={status}>
                                                {status}
                                            </option>
                                        ))}
                                </Field>
                            </div>
                            <div>
                                <label>Member ID</label>
                                <Field
                                    name="memberId"
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                />
                                <ErrorMessage component={fieldError} name="memberId" />
                            </div>
                            <div>
                                <label>Group ID</label>
                                <Field
                                    name="groupId"
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                />
                            </div>
                            <div>
                                <label>Effective Date</label>
                                <Field
                                    name="effectiveDate"
                                    type="date"
                                    className="border-[#abc9f0] w-full appearance-none rounded border bg-transparent py-2 px-3 leading-tight shadow"
                                />
                            </div>

                            <div>
                                <label>Termination Date</label>
                                <Field
                                    name="terminationDate"
                                    type="date"
                                    className="border-[#abc9f0] w-full appearance-none rounded border bg-transparent py-2 px-3 leading-tight shadow"
                                />
                            </div>
                            <div>
                                <label>MultiPlan</label>{' '}
                                <Field
                                    type="checkbox"
                                    name="multiplan"
                                    className="appearance-none flex-shrink-0 h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer"
                                />
                            </div>
                            <div>
                                <label>Policy Holder Relationship</label>
                                <Field
                                    name="policyHolderRelationship"
                                    as="select"
                                    className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow">
                                    <option value="">Select a relationship</option>
                                    {relationshipTypes.map((relationship, index) => (
                                        <option key={`relationship-${index}`} value={relationship}>
                                            {relationship}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    component={fieldError}
                                    name="policyHolderRelationship"
                                />
                            </div>
                            {values.policyHolderRelationship !== 'Self' && (
                                <>
                                    <div>
                                        <label>Policy Holder First Name</label>
                                        <Field
                                            name="policyHolderFirstName"
                                            className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                        />
                                        <ErrorMessage
                                            component={fieldError}
                                            name="policyHolderFirstName"
                                        />
                                    </div>
                                    <div>
                                        <label>Policy Holder Last Name</label>
                                        <Field
                                            name="policyHolderLastName"
                                            className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                        />
                                        <ErrorMessage
                                            component={fieldError}
                                            name="policyHolderLastName"
                                        />
                                    </div>
                                    <div>
                                        <label>Policy Holder Gender</label>
                                        <Field
                                            name="policyHolderGender"
                                            className="border-[#abc9f0] w-full rounded border py-2 px-3 leading-tight shadow"
                                            as="select">
                                            <option value="">Select a relationship</option>
                                            {genderTypes.map((gender, index) => (
                                                <option key={`gender-${index}`} value={gender}>
                                                    {gender}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            component={fieldError}
                                            name="policyHolderGender"
                                        />
                                    </div>
                                    <div>
                                        <label>Policy Holder DOB</label>
                                        <Field
                                            name="policyHolderDob"
                                            type="date"
                                            className="border-[#abc9f0] w-full appearance-none rounded border bg-transparent py-2 px-3 leading-tight shadow"
                                        />
                                        <ErrorMessage
                                            component={fieldError}
                                            name="policyHolderDob"
                                        />
                                    </div>
                                </>
                            )}
                            <footer className="mt-4">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary w-full">
                                    {' '}
                                    Save
                                </button>
                            </footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <InsuranceCardModal
                modalOpen={insuranceModalOpen}
                imageUrl={imageUrl}
                setModalOpen={setInsuranceModalOpen}
                downloadUrl={downloadUrl}
            />
        </div>
    );
};

export default PatientInsurance;
