import { useState } from 'react';
import {
    useCreateOrderMutation,
    useGetPatientOrderHistoryQuery,
} from '../../../app/services/admin/orders';
import { AdminOrderList } from '../Orders';
import { SquareOrders } from './TabComponents';

const PatientOrderHistory = ({ patient }) => {
    const { data: orderHistory, isLoading, error } = useGetPatientOrderHistoryQuery(patient.id);
    const [createOrder] = useCreateOrderMutation();
    const [orderTab, setOrderTab] = useState('gem');

    if (error)
        return (
            <p className="text-sm font-bold text-red">
                {error.data?.statusCode}: {error.data?.message}
            </p>
        );

    if (isLoading) return <p key={`orderhistory-loading-${patient.id}`}>Loading...</p>;
    return (
        <div key={`orderhistory-${patient.id}`}>
            <h2 className="font-heading text-3xl lg:text-5xl">Order History</h2>
            <nav className="my-4">
                <button
                    onClick={(ev) => {
                        setOrderTab('gem');
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${orderTab === 'gem' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    GEM Orders
                </button>
                <button
                    onClick={(ev) => {
                        setOrderTab('square');
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${orderTab === 'square' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Square Orders
                </button>
            </nav>
            {orderTab === 'gem' && (
                <>
                    <button
                        key={`create-order-button-${patient.id}`}
                        className="btn-primary-small w-1/3 ml-[1%] mb-4"
                        onClick={() => {
                            createOrder({ userId: patient.id });
                        }}>
                        Create Order
                    </button>
                    <AdminOrderList orders={orderHistory} patientId={patient.id} />
                </>
            )}
            {orderTab === 'square' && <SquareOrders patient={patient} />}
        </div>
    );
};

export default PatientOrderHistory;
