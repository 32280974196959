import { BsArrowDown } from 'react-icons/bs';
import AdminOrderListItem from './AdminOrderListItem';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusFilter } from '../../../features/admin/adminSlice';

//Layout for grid to be passed both to Orders component and used in the header, adjust for patient view
const gridLayout = 'grid-cols-[80px_150px_90px_120px_80px_40px_150px_60px_100px_100px_200px] gap-4';
const patientGridLayout = 'grid-cols-[80px_90px_120px_80px_40px_100px_100px_200px] gap-4';

const sortableColumns = ['ID', 'Date', 'Updated'];

const AdminOrderList = ({ orders, patientId, setPage }) => {
    const dispatch = useDispatch();
    const { orderBy, orderDesc } = useSelector((state) => state.admin.activeOrderFilters);

    let columnLabels = [
        'Date',
        'User',
        'Type',
        'Item',
        // 'Shipping',
        'Updated',
        'ID',
        'Insurance',
        '',
        'Estimate',
        'Prior Auth',
        'GEM Status',
    ];

    const gridLayoutToUse = patientId ? patientGridLayout : gridLayout;

    //Remove name if showing patient view
    patientId && columnLabels.splice(1, 1) && columnLabels.splice(5, 2);

    return (
        <>
            <div key={`orders-table`} className="text-sm text-gray">
                <div className={`grid ${gridLayoutToUse} text-left`} key={`orders-table-labels`}>
                    {columnLabels.map((label) => (
                        <p
                            key={`label-${label}`}
                            className={`flex items-center ${!patientId && sortableColumns.includes(label) && 'hover:cursor-pointer'}`}
                            onClick={() => {
                                if (!patientId && sortableColumns.includes(label)) {
                                    dispatch(
                                        setStatusFilter(
                                            //If the orderBy is already set to this, adjust the direction
                                            orderBy === label
                                                ? {
                                                      type: 'activeOrderFilters',
                                                      key: 'orderDesc',
                                                      value: !orderDesc,
                                                  }
                                                : //Otherwise change the orderBy
                                                  {
                                                      type: 'activeOrderFilters',
                                                      key: 'orderBy',
                                                      value: label,
                                                  },
                                        ),
                                    );
                                    setPage(0);
                                }
                            }}>
                            <span
                                className={`font-semibold ${
                                    !patientId && orderBy === label
                                        ? 'text-gem-green'
                                        : !patientId && sortableColumns.includes(label)
                                          ? 'hover:text-gem-green'
                                          : null
                                }`}>
                                {label}
                            </span>
                            {!patientId && label === orderBy && (
                                <BsArrowDown
                                    className={`transition duration-200 ease-out-quad left-10 scale-110 text-white ${
                                        orderDesc ? 'rotate-180' : 'rotate-0'
                                    } `}
                                />
                            )}
                        </p>
                    ))}
                </div>
                {orders.length > 0 &&
                    orders.map((order) => (
                        <AdminOrderListItem
                            key={`order-component-${order.id}`}
                            order={order}
                            patientId={patientId}
                            gridLayout={gridLayoutToUse}
                        />
                    ))}
            </div>
            {orders.length === 0 && (
                <p key="no orders found" className="text-xl font-heading tracking-widest py-2">
                    No orders found.
                </p>
            )}
        </>
    );
};

export default AdminOrderList;
