import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as Tagline } from '../../images/gem-sleep-tagline-black.svg';
import { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import { toggleModal } from '../../features/ui/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useInsuranceContext } from '../../components/Insurance';
import { useCheckoutContext } from '../../components/Checkout/CheckoutContext';
import { toggleInsurance } from '../../features/cart/cartSlice';

function GemInfo({ header, text }) {
    return (
        <>
            <div className="col-start-1 block lg:hidden">
                <div className="flex items-center justify-center pb-2">
                    <Logo className="w-[28px] h-[28px]" />
                </div>
            </div>
            <div className="col-start-2 bg-white flex my-1 p-2 lg:p-4 h-full">
                <div className="my-auto ml-4">
                    <h3 className="font-bold text-sm pb-2">{header}</h3>
                    <p className="text-xs">{text}</p>
                </div>
            </div>
        </>
    );
}

function OtherInfo({ header, text, disclaimer }) {
    return (
        <>
            <div className="col-start-1 block lg:hidden text-center font-bold text-[15px]">
                <p>With Ins.</p>
            </div>
            <div className="col-start-2 lg:col-start-3 flex flex-col my-1 bg-[#F7F8FA] p-2 lg:p-4 h-full">
                <div className="my-auto ml-4">
                    <h3 className="font-bold text-sm pb-2">{header}</h3>
                    <p className="text-xs">{text}</p>
                </div>
                {disclaimer && <p className="ml-4 text-[8px] text-gray-500 mt-0">{disclaimer}</p>}
            </div>
        </>
    );
}

function Spacer({ label }) {
    return (
        <div className="col-span-2 lg:col-span-3 bg-[#D9D9D9] h-[35px]">
            {label && (
                <span className="block lg:hidden text-sm font-bold leading-[35px] pl-4">
                    {label}
                </span>
            )}
        </div>
    );
}

function getChartContent(insurance) {
    if (!insurance) return null;
    else if (insurance.payer?.dmeReferralId !== null)
        return {
            name: 'With Insurance',
            subheader: `Referred to ${insurance?.payer?.name}'s CPAP Supplier`,
            descriptiontext1: `There are two ways you can get going on CPAP. You can use your
                        ${insurance?.payer?.name} insurance by having GEM send a referral to
                        ${insurance?.payer?.name}'s CPAP provider (${insurance?.payer?.dmeReferral?.name}). Or you can purchase directly through
                        GEM SLEEP without using your insurance.`,
            descriptiontext2: ` The right choice for you will depend on your unique situation and how you'd
                        like to be supported with your CPAP therapy.`,
            usageRequirementsInfoText: `But we'll help you get the most out of your treatment!`,
            usageRequirementsOtherText: `Typically have minimum usage requirements set by your health plan`,
            paymentOptionsInfoText: `Payment plans are also available.`,
            paymentOptionsOtherText: `Copay, deductible and coinsurance costs will depend on your specific health plan.`,
            coachingSupportInfoText: `Free 90 days of GEM coaching support`,
            coachingSupportOtherText: `Coaching programs/length will vary.`,
            workWithInfoText: `Your coach and clinician will stick with you`,
            workWithOtherText: `${insurance?.payer?.dmeReferral?.name} will handle your CPAP, we continue to be your sleep clinic`,
            goodForInfoText: "If you're wanting flexibility and on-demand support",
            goodForOtherText: `If you would like your out of pocket costs to apply to your insurance deductible`,
        };
    else if (insurance.payer.CPAPCovered) {
        return {
            name: 'With Insurance',
            subheader: '',
            descriptiontext1: `   There are two payment options for your CPAP Therapy. You may use your
                        insurance or self-pay (including HSA/FSA). With either option GEM SLEEP will
                        support you with coaching as you adjust to treatment.`,
            descriptiontext2: ` The right choice will depend on your unique situation. If you choose
                        insurance, we will provide a detailed estimate prior to shipping your
                        device.`,
            usageRequirementsInfoText: `But we'll help you get the most out of your treatment!`,
            usageRequirementsOtherText: `Typically have minimum usage requirements set by your health plan`,
            paymentOptionsInfoText: `Payment plans are also available.`,
            paymentOptionsOtherText: `Copay, deductible and coinsurance costs will depend on your specific health plan.`,
            coachingSupportInfoText: `GEM Coaching support for first 90 days.`,
            coachingSupportOtherText: `Up to 90 days, including 1:1 calls with a dedicated coach.`,
            workWithInfoText: ``,
            workWithOtherText: ``,
            goodForInfoText: "If you're wanting flexibility and on-demand support",
            goodForOtherText: `If you would like your out of pocket costs to apply to your insurance deductible`,
        };
    }
}

const ComparisonCharts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Redux State Values
    const { cpapInCart, orderItems } = useSelector((state) => state.cart);
    const { onRecommended } = useSelector((state) => state.cart);
    const { selectedVariation } = useSelector((state) => state.catalog);

    //Context
    const { insurance } = useInsuranceContext();
    const { finishInsuranceCheck } = useCheckoutContext();

    //Local State
    const [modalOpen, setModalOpen] = useState(false);

    const chartContent = getChartContent(insurance);

    //The logic below here determines if scrolling has occurred to allow the header to sticky in the right position
    const { scrollY } = useScroll();
    const [userScrolled, setUserScrolled] = useState(true);

    useMotionValueEvent(scrollY, 'change', (latest) => {
        if (latest > 20 && !userScrolled) {
            setUserScrolled(true);
        } else if (latest < 20 && userScrolled) {
            setUserScrolled(false);
        }
    });

    function handleClose() {
        setModalOpen(false);
    }

    if (!chartContent) {
        return <p className="py-6 font-heading text-5xl">Loading...</p>;
    } else {
        return (
            <>
                <div className="sticky top-0 bg-[#f0f2f5] h-[60px] pt-3">
                    <h3
                        className={`font-heading text-3xl leading-none lg:text-5xl ${
                            userScrolled ? 'ml-10 lg:ml-0' : ''
                        }`}>
                        COMPARE PURCHASE OPTIONS
                    </h3>
                </div>
                <p className="font-medium text-[#6C757D] pb-4">{chartContent.descriptiontext1}</p>
                <p className="font-medium text-[#6C757D] pb-2">{chartContent.descriptiontext2}</p>
                <div className="sticky top-[60px] w-full bg-[#f0f2f5]">
                    <button
                        className="font-bold py-2"
                        onClick={() => dispatch(toggleModal('contact'))}>
                        <span className="link">Have questions?</span>
                    </button>
                </div>

                <div className="sticky top-[100px] w-full bg-[#f0f2f5] text-center lg:text-left pt-2 pb-4">
                    <button
                        className="btn-primary w-[calc(100%)] sm:w-[300px]"
                        onClick={() => {
                            if (
                                insurance &&
                                (insurance?.payer?.dmeReferral || insurance?.payer?.CPAPCovered)
                            ) {
                                setModalOpen(true);
                            } else {
                                navigate('/dashboard/treatment/gem-bundle');
                            }
                        }}>
                        CONTINUE
                    </button>
                </div>

                <div className="grid grid-cols-[60px,1fr] lg:grid-cols-3 items-center pt-4">
                    <div className="col-span-3 lg:grid lg:grid-cols-3 items-center">
                        <p className="pb-4 lg:pb-0 lg:col-start-1 lg:col-span-1 text-center font-bold text-[23px]">
                            COMPARE OPTIONS
                        </p>
                        {insurance?.payer?.dmeReferral && (
                            <div className="col-start-2 flex flex-col items-center justify-center hidden lg:block">
                                <div className="flex items-center justify-center pb-2">
                                    <Logo className="max-w-[40px] h-[28px] w-[28px]" />
                                    <Tagline className="ml-2 max-w-[168px] fill-black h-[28px]" />
                                </div>
                                <p className="font-bold text-[#5E5E5E] text-base pb-2 text-center">
                                    Self-Pay (including HSA/FSA)
                                </p>
                            </div>
                        )}
                        {insurance?.payer?.CPAPCovered && (
                            <div className="col-start-2 flex flex-col items-center justify-center hidden lg:block">
                                <p className="pb-4 lg:pb-0 lg:col-start-1 lg:col-span-1 text-center font-bold text-[30px]">
                                    Self-Pay
                                </p>
                                <p className="font-bold text-[#5E5E5E] text-base pb-2 text-center">
                                    (may use HSA/FSA)
                                </p>
                            </div>
                        )}
                        {insurance?.payer?.dmeReferral && (
                            <p className="col-start-3 font-bold text-[26px] text-[#5E5E5E] text-center pb-4 hidden lg:block">
                                {chartContent?.name}
                                <span className="block text-sm">{chartContent?.subheader}</span>
                            </p>
                        )}
                        {insurance?.payer?.CPAPCovered && (
                            <p className="col-start-3 font-bold text-[26px] text-[#5E5E5E] text-center pb-4 hidden lg:block">
                                Use Insurance
                            </p>
                        )}
                    </div>
                    <Spacer label="USAGE REQUIREMENTS" />

                    <p className="col-start-1 font-bold my-auto hidden lg:block">
                        USAGE REQUIREMENTS
                    </p>

                    <GemInfo
                        header="No required amount of usage"
                        text={chartContent.usageRequirementsInfoText}
                    />
                    <OtherInfo
                        header="Usage Minimums"
                        text={chartContent.usageRequirementsOtherText}
                    />
                    <Spacer label="PAYMENT OPTIONS" />

                    <p className="col-start-1 font-bold my-auto hidden lg:block">PAYMENT OPTIONS</p>

                    <GemInfo
                        header="Self-Pay (including HSA/FSA)"
                        text={chartContent.paymentOptionsInfoText}
                    />
                    <OtherInfo
                        header="Pay your responsibility out of pocket"
                        text={chartContent.paymentOptionsOtherText}
                    />
                    {/* Coaching and support section keeps conditional render due to disclaimer */}
                    <Spacer label="COACHING & SUPPORT" />
                    <p className="col-start-1 font-bold my-auto hidden lg:block">
                        COACHING & SUPPORT
                    </p>
                    {insurance?.payer?.dmeReferral && (
                        <>
                            <GemInfo
                                header="Coaching Included"
                                text={chartContent.coachingSupportInfoText}
                            />
                            <OtherInfo
                                header="Minimal treatment support "
                                text={chartContent.coachingSupportOtherText}
                            />
                        </>
                    )}
                    {insurance?.payer?.CPAPCovered && (
                        <>
                            <GemInfo
                                header="Free on Demand Coaching"
                                text={chartContent.coachingSupportInfoText}
                            />
                            <OtherInfo
                                header="Personalized Coaching"
                                text={chartContent.coachingSupportOtherText}
                                disclaimer="*Submitted to insurance and any patient responsibility invoiced to you."
                            />
                            <p className="text-sm text-gray-500 mt-1"></p>
                        </>
                    )}
                    {/* 'Who you'll work with' section only renders for DME payers */}
                    {insurance?.payer?.dmeReferral && (
                        <>
                            <Spacer label="WHO YOU'LL WORK WITH" />

                            <p className="col-start-1 font-bold my-auto hidden lg:block">
                                WHO YOU'LL WORK WITH
                            </p>

                            <GemInfo header="GEM SLEEP" text={chartContent.workWithInfoText} />
                            <OtherInfo
                                header={`${insurance?.payer?.dmeReferral?.name} and GEM SLEEP`}
                                text={chartContent.workWithOtherText}
                            />
                        </>
                    )}
                    <Spacer label="MIGHT BE GOOD FOR" />

                    <p className="col-start-1 font-bold my-auto hidden lg:block">
                        MIGHT BE GOOD FOR
                    </p>

                    <GemInfo header="Flexibility" text={chartContent.goodForInfoText} />
                    <OtherInfo
                        header="Applying to your deductible"
                        text={chartContent.goodForOtherText}
                    />
                    <Spacer />
                </div>
                {modalOpen && (
                    <Modal showClose={true} isOpen={modalOpen} closeHandler={() => handleClose()}>
                        <h5 className="xs:mb-3 font-heading text-2xl xs:text-4xl lg:text-5xl text-center">
                            PICK AN OPTION TO CONTINUE
                        </h5>
                        <h5 className="xs:mb-3 font-heading text-sm xs:text-base lg:text-xl text-center">
                            Don't worry, you can always change your mind later.
                        </h5>
                        <div className="grid grid-rows sm:grid-cols-2 gap-2 xs:gap-4">
                            <div className="row-start-1 sm:col-start-1 my-auto">
                                <div className="flex justify-center pb-2 pt-4">
                                    <Logo className="h-[20px] w-[20px] xs:h-[28px] xs:w-[28px]" />
                                    <Tagline className="ml-2 fill-black h-[20px] xs:h-[28px]" />
                                </div>
                                <p className="font-bold text-[#5E5E5E] text-sm xs:text-base text-center xs:pb-4">
                                    Self-Pay (including HSA/FSA)
                                </p>
                            </div>
                            <div className="row-start-4 sm:row-start-1 sm:col-start-2 my-auto">
                                <p className="col-start-3 font-bold text-sm xs:text-base sm:text-[26px] text-[#5E5E5E] text-center p-4">
                                    {chartContent.name}
                                    <span className="block text-xs lg:text-sm pt-2">
                                        {chartContent.subheader}
                                    </span>
                                </p>
                            </div>
                            <div className="row-start-3 sm:col-start-1">
                                <button
                                    className="btn-primary min-h-[50px] xs:min-h-[66px] w-full"
                                    onClick={() => {
                                        dispatch(toggleInsurance(false));
                                        if (insurance?.payer?.dmeReferral) {
                                            if (
                                                cpapInCart &&
                                                orderItems.some(
                                                    (p) => p.variantId === selectedVariation?.id,
                                                )
                                            ) {
                                                finishInsuranceCheck({
                                                    routeToRequiredBooking: false,
                                                });
                                                navigate('/dashboard/checkout');
                                            } else if (onRecommended === true) {
                                                // if they are already on the recommended screen and choose self pay here send them
                                                // to their selected bundle from the fitting
                                                navigate(
                                                    `/dashboard/treatment/bundle/${selectedVariation.id}`,
                                                );
                                            } else {
                                                navigate('/dashboard/treatment/gem-bundle');
                                            }
                                        } else {
                                            navigate('/dashboard/treatment/gem-bundle');
                                        }
                                    }}>
                                    SELF-PAY
                                </button>
                                <div className="bg-gem-green bg-opacity-10 w-full text-center p-2 mt-2">
                                    <p className="text-[#107E50] font-bold text-sm xs:text-base">
                                        Special Offer!
                                    </p>
                                    <p className="text-[#107E50] font-bold text-sm xs:text-base">
                                        $150 Discount when you Self-Pay
                                    </p>
                                </div>
                            </div>
                            <div className="row-start-6 sm:row-start-3 sm:col-start-2">
                                <button
                                    className="btn-primary min-h-[50px] xs:min-h-[66px] w-full"
                                    onClick={() => {
                                        handleClose();
                                        if (insurance?.payer?.dmeReferral) {
                                            dispatch(toggleModal('dmeReferral'));
                                        } else {
                                            dispatch(toggleInsurance(true));
                                            dispatch(toggleModal('maskFit'));
                                        }
                                    }}>
                                    PAY WITH INSURANCE
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </>
        );
    }
};

export default ComparisonCharts;
