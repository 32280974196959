// TODO: separate endpoints (itamar, square etc.)

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logOut, tokenReceived } from '../../features/auth/authSlice';
import { setLastRefresh } from '../../features/ui/uiSlice';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;

        if (token) headers.set('Authorization', `Bearer ${token}`);

        return headers;
    },
});

export const api = createApi({
    baseQuery: async (args, api, extraOptions) => {
        await mutex.waitForUnlock();

        if (
            api.endpoint === 'login' ||
            args.url === '/auth/refresh' ||
            args.url === '/auth/verifyCode'
        ) {
            args = { ...args, credentials: 'include' };
        }

        let result = await baseQuery(args, api, extraOptions);

        if (args.url !== '/auth/verifyCode' && result.error && result.error.status === 401) {
            // checking whether the mutex is locked
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const refreshResult = await baseQuery(
                        {
                            url: '/auth/refresh',
                            method: 'POST',
                            credentials: 'include',
                        },
                        api,
                        extraOptions,
                    );
                    if (refreshResult.data) {
                        api.dispatch(tokenReceived(refreshResult.data));
                        api.dispatch(setLastRefresh(Date.now()));
                        // retry the initial query
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.dispatch(logOut());
                    }
                } catch (error) {
                    api.dispatch(logOut());
                } finally {
                    // release must be called once the mutex should be released again.
                    release();
                }
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock();
                result = await baseQuery(args, api, extraOptions);
            }
        }
        return result;
    },
    tagTypes: [
        'AdminUsers',
        'CareTeamPool',
        'Cart',
        'Coaches',
        'Feedback',
        'Insurance',
        'Note',
        'OrderHistory',
        'OrderInfo',
        'PatientOrderHistory',
        'Payers',
        'Post',
        'Queries',
        'RecommendedCPAPBundles',
        'ResupplyStatus',
        'SovaSagePartRecommendations',
        'Treatment',
        'User',
    ],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...credentials,
                },
            }),
            providesTags: (result) =>
                result?.user.id ? [{ type: 'User', id: result.user.id }] : [],
        }),
        verifyCode: builder.mutation({
            query: (data) => ({
                url: '/auth/verifyCode',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...data,
                },
            }),
            providesTags: (result) =>
                result?.user.id ? [{ type: 'User', id: result.user.id }] : [],
        }),
        resendCode: builder.mutation({
            query: (credentials) => ({
                url: '/auth/resendCode',
                method: 'POST',
                body: {
                    ...credentials,
                },
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
        }),
        requestPasswordReset: builder.mutation({
            query: (passwordResetData) => ({
                url: '/auth/request-password-reset',
                method: 'POST',
                body: passwordResetData,
            }),
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: data,
            }),
        }),
        getMe: builder.query({
            queryFn: async (id, api, opts, fetchWithBQ) => {
                const userResult = await fetchWithBQ('/users/my');

                if (userResult.error) return { error: userResult.error };

                let user = userResult.data;

                if (user.patientProfile?.stopBangResultId) {
                    const stopBangResponses = await fetchWithBQ({
                        url: `/stop-bang/${user.patientProfile?.stopBangResultId}`,
                    });
                    if (stopBangResponses.data) user.stopBangResponses = stopBangResponses.data;
                }

                return { data: user };
            },
            providesTags: (result) => (result?.id ? [{ type: 'User', id: result.id }] : []),
        }),
        getStopBangResponses: builder.query({
            query: (stopBangResultId) => `/stop-bang/${stopBangResultId}`,
        }),
        refreshTokens: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'POST',
                credentials: 'include',
            }),
        }),
        createUser: builder.mutation({
            query: (userData) => ({
                url: '/users/patients',
                method: 'POST',
                body: {
                    ...{
                        currentTimezone:
                            Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
                            'America/New_York',
                    },
                    ...userData,
                },
            }),
        }),
        updateMe: builder.mutation({
            query: (userData) => ({
                url: '/users/my',
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: (result) => [{ type: 'User', id: result.id }],
        }),
        getUserTimeline: builder.query({
            query: (userId) => ({
                url: `/history/timeline/${userId}`,
                method: 'GET',
            }),
        }),
        getUserHistory: builder.query({
            queryFn: async (
                { userId, whereClause = { modelName: 'PatientProfile' } },
                api,
                extraOptions,
                fetchWithBQ,
            ) => {
                const getAllHistory = async (skip = 0) => {
                    const response = await fetchWithBQ(
                        `/history/user/${userId}?orderBy={"createdAt":"desc"}&where=${JSON.stringify(
                            whereClause,
                        )}&take=100&skip=${skip}`,
                    );

                    if (response.data?.error) return response;

                    const results = response.data?.results;

                    return skip < response.data?.metadata.total
                        ? results.concat(await getAllHistory(skip + 100))
                        : results;
                };

                const allHistory = await getAllHistory();

                return { data: allHistory };
            },
        }),
        updatePatientProfile: builder.mutation({
            query: ({ id, userId, orderId, patientFields }) => ({
                url: `/patient-profile/${id}`,
                method: 'PUT',
                body: { ...patientFields },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.userId },
                'OrderInfo',
                'OrderHistory',
                'PatientOrderHistory',
            ],
        }),
        getAllStateServices: builder.query({
            query: () => ({
                url: `/service`,
                method: 'GET',
            }),
        }),
        getPaymentUrl: builder.query({
            query: () => ({
                url: '/collectplus/paymentUrl',
                method: 'GET',
            }),
        }),
        createFeedback: builder.mutation({
            query: (body) => ({
                url: `/feedback`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Feedback'],
        }),
        getFeedback: builder.query({
            query: () => ({
                url: `/feedback`,
                method: 'GET',
            }),
            providesTags: ['Feedback'],
        }),
        assignSleepStudyAsPrescription: builder.mutation({
            query: ({ id }) => ({
                url: `/users/${id}/assignSleepStudyAsPrescription`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
        }),
    }),
});

export const {
    //Auth
    useCreateUserMutation,
    useGetAllStateServicesQuery,
    useLoginMutation,
    useLogoutMutation,
    useRequestPasswordResetMutation,
    useResendCodeMutation,
    useResetPasswordMutation,
    useRefreshTokensMutation,
    useVerifyCodeMutation,
    //Patient Data
    useGetMeQuery,
    useGetStopBangResponsesQuery,
    useGetUserHistoryQuery,
    useGetUserTimelineQuery,
    useUpdateMeMutation,
    useUpdatePatientProfileMutation,
    useLazyGetPaymentUrlQuery,
    useCreateFeedbackMutation,
    useGetFeedbackQuery,
    useAssignSleepStudyAsPrescriptionMutation,
} = api;
