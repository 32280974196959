import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { BsArrowDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setStatusFilter } from '../../features/admin/adminSlice';
import { formatBirthdate, formatDate } from '../../utils/constants';
import { useGetPayersQuery } from '../../app/services/insurance';
import { useGetUsersQuery } from '../../app/services/admin/search';
import {
    NameEmailIDSearch,
    PatientFilters,
    Queries,
} from '../../components/Admin/PatientSearchQueries';
import { Pagination } from '../../components/Admin';

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activePatientFilters, patientFilterComponents } = useSelector((state) => state.admin);
    const [page, setPage] = useState(activePatientFilters.skip / activePatientFilters.take);
    const [usersPerPage, setUsersPerPage] = useState(activePatientFilters.take);
    const [orderByField, setOrderByField] = useState('id');
    const [orderAsc, setOrderAsc] = useState(false);
    //Bring is payers to cross reference payerId from users query
    const { data: payers } = useGetPayersQuery();

    const payersList = new Map();

    payers?.forEach((payer) => payersList.set(payer.id, payer.name));

    //Used to allow the columns to be sorted in ascending or descending order
    function updateOrderBy(newOrderByField) {
        const newVal =
            newOrderByField in activePatientFilters.orderBy
                ? {
                      [newOrderByField]:
                          activePatientFilters.orderBy[newOrderByField] === 'asc' ? 'desc' : 'asc',
                  }
                : { [newOrderByField]: 'asc' };

        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: 'orderBy',
                value: newVal,
            }),
        );

        if (orderByField === newOrderByField) setOrderAsc(!orderAsc);
        else {
            setOrderByField(newOrderByField);
            setOrderAsc(true);
        }
    }

    //Gets the data for the table
    const { data, isLoading, isFetching, error } = useGetUsersQuery(activePatientFilters);

    const rowVariants = {
        hide: { opacity: 0, x: '2%' },
        show: { opacity: 1, x: '0%' },
    };

    //Fetch data on page change, alter the skip value when usersPerPage changes
    useEffect(() => {
        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: 'skip',
                value: page * usersPerPage,
            }),
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [page, dispatch, usersPerPage]);

    //Fetch appropriate data when usersPerPage changes
    useEffect(() => {
        dispatch(
            setStatusFilter({ type: 'activePatientFilters', key: 'take', value: usersPerPage }),
        );
    }, [dispatch, usersPerPage]);

    return (
        <div className="py-6">
            <h2 className="mb-5">
                <span className="font-heading text-5xl">Patients</span>
            </h2>
            <div className="grid grid-cols-3 lg:grid-cols-6">
                <p className="font-semibold col-start-1 col-span-6 p-2">
                    Search by Name, ID, Email:
                </p>
                <div className="col-start-1 col-span-6 ">
                    <NameEmailIDSearch activeFilters="activePatientFilters" setPage={setPage} />
                </div>

                <Queries setPage={setPage} />

                <PatientFilters setPage={setPage} dataLoading={isFetching} />

                {error && (
                    <p className="col-start-1 col-span-2 pl-2 text-sm font-bold text-red pt-2">
                        {error.data?.statusCode}: {error.data?.message}
                    </p>
                )}
            </div>
            <div className="overflow-x-auto lg:overflow-x-hidden">
                <ul
                    className={`min-w-[1000px] text-sm transition ${
                        isFetching || isLoading ? 'opacity-25' : 'opacity-100'
                    }`}>
                    <li className="flex items-end">
                        <span className="flex flex-none w-[71px] items-center space-x-2 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                            <button
                                className={`font-semibold ${
                                    orderByField === 'id'
                                        ? 'text-gem-green'
                                        : 'hover:text-gem-green'
                                }`}
                                onClick={() => updateOrderBy('id')}>
                                ID
                            </button>
                            <BsArrowDown
                                className={`transition duration-200 ease-out-quad ${
                                    orderByField === 'id' ? 'block' : 'hidden'
                                } left-10 scale-110 text-white ${
                                    !orderAsc && orderByField === 'id' ? 'rotate-180' : 'rotate-0'
                                } `}
                            />
                        </span>
                        <p className="flex-none w-[225px] lg:w-[250px] border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                            Patient Name <br />
                            <span className="inline-flex items-center text-xs">
                                (Sort:{' '}
                                <button
                                    className={`font-semibold ${
                                        orderByField === 'firstName'
                                            ? 'text-gem-green'
                                            : 'hover:text-gem-green'
                                    }`}
                                    onClick={() => updateOrderBy('firstName')}>
                                    First
                                </button>
                                <BsArrowDown
                                    className={`transition duration-200 ease-out-quad ${
                                        orderByField === 'firstName' ? 'block' : 'hidden'
                                    } left-10 scale-110 text-white ${
                                        !orderAsc && orderByField === 'firstName'
                                            ? 'rotate-180'
                                            : 'rotate-0'
                                    } `}
                                />
                                /
                                <button
                                    className={`font-semibold ${
                                        orderByField === 'lastName'
                                            ? 'text-gem-green'
                                            : 'hover:text-gem-green'
                                    }`}
                                    onClick={() => updateOrderBy('lastName')}>
                                    Last
                                </button>
                                <BsArrowDown
                                    className={`transition duration-200 ease-out-quad ${
                                        orderByField === 'lastName' ? 'block' : 'hidden'
                                    } left-10 scale-110 text-white ${
                                        !orderAsc && orderByField === 'lastName'
                                            ? 'rotate-180'
                                            : 'rotate-0'
                                    } `}
                                />
                                )
                            </span>
                        </p>
                        <span className="flex flex-none w-[135px] items-center space-x-2 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                            <button
                                className={`font-semibold ${
                                    orderByField === 'createdAt'
                                        ? 'text-gem-green'
                                        : 'hover:text-gem-green'
                                }`}
                                onClick={() => updateOrderBy('createdAt')}>
                                Registered
                            </button>
                            <BsArrowDown
                                className={`transition duration-200 ease-out-quad ${
                                    orderByField === 'createdAt' ? 'block' : 'hidden'
                                } left-10 scale-110 text-white ${
                                    !orderAsc && orderByField === 'createdAt'
                                        ? 'rotate-180'
                                        : 'rotate-0'
                                } `}
                            />
                        </span>
                        <span
                            className={`${
                                patientFilterComponents[0]?.status.value ? 'flex-none' : 'flex-1'
                            } flex w-[135px] items-center space-x-2 border-b border-purple-light/30 py-4 px-4 text-sm text-gray`}>
                            <button
                                className={`font-semibold ${
                                    orderByField === 'birthdate'
                                        ? 'text-gem-green'
                                        : 'hover:text-gem-green'
                                }`}
                                onClick={() => updateOrderBy('birthdate')}>
                                DOB
                            </button>
                            <BsArrowDown
                                className={`transition duration-200 ease-out-quad ${
                                    orderByField === 'birthdate' ? 'block' : 'hidden'
                                } left-10 scale-110 text-white ${
                                    !orderAsc && orderByField === 'birthdate'
                                        ? 'rotate-180'
                                        : 'rotate-0'
                                } `}
                            />
                        </span>
                        {patientFilterComponents.map((obj) =>
                            obj.status?.value ? (
                                <span
                                    key={obj.status.value + 'column header'}
                                    className="flex flex-1 items-center space-x-2 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                                    <p className="font-semibold">{obj.status.label}</p>
                                </span>
                            ) : null,
                        )}
                    </li>
                    {data?.results.length > 0 &&
                        data?.results.map((user, idx) => (
                            <motion.li
                                key={`user-${user.id}`}
                                className="relative flex items-end hover:cursor-pointer"
                                variants={rowVariants}
                                initial="hide"
                                animate="show"
                                exit="hide"
                                transition={{ ease: 'circOut', delay: 0.05 * idx }}
                                onClick={() => {
                                    navigate(`/admin/patients/${user.id}`, {
                                        state: { from: '/admin', name: 'Patients' },
                                    });
                                }}>
                                <p className="flex-none w-[71px] border-b border-purple-light/30 py-4 px-4">
                                    {user.id}
                                </p>
                                <div
                                    className={`daisy-tooltip daisy-tooltip-right text-left`}
                                    data-tip={user.firstName + ' ' + user.lastName}>
                                    <p className="flex-none w-[225px] lg:w-[250px] border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                        <span className="border-b-2 border-green-400 font-bold">{`${user.firstName} ${user.lastName}`}</span>
                                    </p>
                                </div>
                                <p className="flex-none w-[135px] border-b border-purple-light/30 py-4 px-4">
                                    {formatDate(user.createdAt)}
                                </p>
                                <p
                                    className={`${
                                        patientFilterComponents[0]?.status.value
                                            ? 'flex-none'
                                            : 'flex-1'
                                    } w-[135px] border-b border-purple-light/30 py-4 px-4`}>
                                    {formatBirthdate(user.birthdate)}
                                </p>

                                {patientFilterComponents.map((obj) =>
                                    obj.status?.value ? (
                                        obj.status?.value === 'insurance' ? (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {payersList.get(user[obj.status.value][0]?.payerId)}
                                            </p>
                                        ) : obj.status?.value === 'careTeam' ? (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {user[obj.status.value][0]?.careTeamUser.firstName +
                                                    ' ' +
                                                    user[obj.status.value][0]?.careTeamUser
                                                        .lastName}
                                            </p>
                                        ) : obj.status?.value === 'state' ? (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {user[obj.status.value]}
                                            </p>
                                        ) : obj.status?.value === 'appointments' ? (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {user[obj.status.value][0]?.appointmentStatus}
                                            </p>
                                        ) : obj.status?.value === 'resupply' ? (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {user[obj.status.value][0]?.enrollmentStatus}
                                            </p>
                                        ) : (
                                            <p
                                                key={user + obj.status.value}
                                                className="flex-1 border-b border-purple-light/30 py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                                {user.patientProfile[obj.status.value]?.name
                                                    ? user.patientProfile[obj.status.value]?.name
                                                    : user.patientProfile[
                                                          obj.status.value
                                                      ]?.toString()}
                                            </p>
                                        )
                                    ) : null,
                                )}
                            </motion.li>
                        ))}

                    {data?.results.length === 0 && (
                        <h2 className="mb-10 font-heading text-3xl p-2">No patients found.</h2>
                    )}
                </ul>
            </div>

            <div className="flex flex-wrap justify-center items-center">
                {data?.metadata && data.metadata.total > usersPerPage && (
                    <Pagination
                        page={page}
                        disabled={isFetching}
                        setPage={setPage}
                        totalPages={Math.floor(data.metadata.total / usersPerPage)}
                    />
                )}
                <div className="flex flex-wrap justify-center sm:py-10">
                    <span className="basis-full text-center mb-2 font-semibold">
                        Users per page:
                    </span>
                    <div className="flex justify-center">
                        <button
                            onClick={() => {
                                setPage(Math.floor((usersPerPage * page) / 5));
                                setUsersPerPage(5);
                            }}
                            disabled={usersPerPage === 5}
                            className="font-heading pr-4 text-2xl tracking-widest text-gem-green disabled:opacity-25 min-w-[24px]">
                            5
                        </button>
                        <button
                            onClick={() => {
                                setPage(Math.floor((usersPerPage * page) / 10));
                                setUsersPerPage(10);
                            }}
                            disabled={usersPerPage === 10}
                            className="font-heading pr-4 text-2xl tracking-widest text-gem-green disabled:opacity-25 min-w-[24px]">
                            10
                        </button>
                        <button
                            onClick={() => {
                                setPage(Math.floor((usersPerPage * page) / 20));
                                setUsersPerPage(20);
                            }}
                            disabled={usersPerPage === 20}
                            className="font-heading text-2xl tracking-widest text-gem-green disabled:opacity-25 min-w-[24px]">
                            20
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
