import { BsInfoCircle, BsQuestionCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useEnrollInResupplyMutation } from '../../app/services/orders';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Modal from '../Modal/Modal';
import { useInsuranceContext } from '../Insurance';
import { formatDate } from '../../utils/constants';

function ResupplyStatus() {
    const { user } = useSelector((state) => state.auth);
    const { insurance } = useInsuranceContext();
    const [enroll] = useEnrollInResupplyMutation();

    const [modalOpen, setModalOpen] = useState(false);

    const currentlyResupply = user?.resupply?.some(
        (resupply) => resupply.enrollmentStatus === 'ENROLLED',
    );

    async function handleEnrollClick() {
        const { data: resupply } = await enroll({
            id: user?.id,
        });

        if (resupply?.enrollmentStatus) {
            setModalOpen(false);
            toast.success('You are now enrolled in our resupply program');
        } else toast.error('Something went wrong, please try again');
    }

    return currentlyResupply ? (
        <div className="mb-7 bg-black lg:flex">
            <div className="flex-1 bg-black flex items-center flex-col sm:flex-row justify-center p-9 lg:p-4 gap-4">
                <p className="font-bold text-white text-center lg:text-lg">
                    You are Enrolled in GEM's SUPPLY AUTO-SHIP program
                </p>
                <BsInfoCircle
                    className="text-white scale-150 hover:cursor-pointer hover:text-gem-green"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="btn bg-gem-green text-white w-full lg:w-[250px]">
                <div className="text-center w-full space-y-4">
                    {user?.resupply?.nextShipmentDate ? (
                        <>
                            <p>Your next shipment date is approximately</p>
                            <p>{formatDate(user?.resupply?.nextShipmentDate)}</p>
                        </>
                    ) : (
                        <p>Check back for your next shipment date</p>
                    )}
                </div>
            </div>
            <Modal showClose={true} isOpen={modalOpen} closeHandler={() => setModalOpen(false)}>
                <h3 className="mb-4 font-heading text-2xl">Reschedule</h3>
                <p className="mb-4">To reschedule or cancel your order, contact us.</p>
                <p className="mb-2">
                    <a href="tel:+18339464436" className="border-b-2 border-b-green-400 font-bold">
                        1-833-946-4GEM
                    </a>
                </p>
                <p>
                    <a
                        href="mailto:info@gem.health"
                        className="border-b-2 border-b-green-400 font-bold">
                        info@gem.health
                    </a>
                </p>
            </Modal>
        </div>
    ) : (
        <div className="mb-7 lg:flex">
            <div className="flex-1 bg-black flex items-center flex-col sm:flex-row justify-center p-9 lg:p-4 gap-4">
                <p className="font-bold text-white text-center lg:text-lg">
                    Make receiving supplies a breeze, enroll in GEM's Auto-Ship program.
                </p>
                <BsQuestionCircle
                    className="text-white scale-150 hover:cursor-pointer hover:text-gem-green"
                    onClick={() => {
                        setModalOpen(true);
                    }}
                />
            </div>
            <button className="btn-primary w-full lg:w-[250px]" onClick={() => setModalOpen(true)}>
                Enroll Now
            </button>
            <Modal showClose={true} isOpen={modalOpen} closeHandler={() => setModalOpen(false)}>
                <h2 className="font-heading text-[44px] leading-none lg:mb-3 lg:text-4xl">
                    Enroll in the GEM Supply Auto-Ship Program?
                </h2>
                <div className="space-y-4 mb-10">
                    <p>
                        Make getting new supplies a breeze, we will send you new supplies every 90
                        days, keeping your machine parts clean and you sleeping your best.
                    </p>
                    {insurance?.payer?.SuppliesCovered && (
                        <p>Good news! Your insurance covers supplies.</p>
                    )}

                    <p className="font-bold">How it works:</p>

                    <ul className="list-disc list-outside pl-4">
                        {insurance?.payer?.SuppliesCovered ? (
                            <>
                                <li>We will verify coverage and get an estimate*</li>
                                <li>
                                    We will send you an email with any estimated out of pocket cost
                                </li>
                                <li>Once you approve, items ship directly to your door</li>
                                <li>
                                    After claim submission, you will receive an emailed invoice for
                                    any remaining balance
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    We will send you an email to confirm your order and address.
                                </li>
                                <li>If everything looks good, you can pay the invoice.</li>
                                <li>
                                    Once we receive payment on the invoice, items ship directly to
                                    your door.
                                </li>
                            </>
                        )}
                    </ul>

                    <div className="space-y-2 lg:flex lg:gap-4">
                        <button
                            className="btn-primary w-full"
                            onClick={async () => handleEnrollClick()}>
                            Yes, send me supplies every 90 days.
                        </button>
                    </div>

                    <p className="text-xs sm:text-sm italic text-center">
                        (we will never send a shipment without your approval)
                    </p>

                    {insurance?.payer?.SuppliesCovered && (
                        <p className="text-sm sm:text-base">
                            *Insurance coverage for supplies will vary, to qualify you may be
                            required to use your machine a certain amount and/or complete a
                            compliance visit.
                        </p>
                    )}

                    {!insurance?.payer?.SuppliesCovered && (
                        <p className="text-sm sm:text-base">
                            Bonus: If you enroll in the auto-ship program you will receive 20% off
                            your order and all items ship for FREE.
                        </p>
                    )}

                    <button className="btn-shell-small w-full" onClick={() => setModalOpen(false)}>
                        No thanks
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ResupplyStatus;
