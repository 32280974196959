import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useSendHSTOrderSignOffMutation } from '../../../app/services/admin/treatment';
import { useGetUserQuery } from '../../../app/services/admin/admin';
import {
    PatientDiagnosis,
    PatientInfo,
    PatientNotes,
    PatientReferralAttribution,
} from './TabComponents';
import { formatDate } from '../../../utils/constants';

const MedicalTab = ({ patient }) => {
    const { storageToken, user } = useSelector((state) => state.auth);

    //Queries
    const [sendHSTOrderSignOff, { isLoading: signOffLoading }] = useSendHSTOrderSignOffMutation();
    const { data: doctor } = useGetUserQuery(patient.patientProfile.hstLabOrderUserId, {
        skip:
            !patient.patientProfile.hstLabOrderUserId ||
            patient.patientProfile.hstLabOrderUserId === user.id,
    });

    const getMostRecentFileByType = (fileType) => {
        if (fileType === 'SLEEP_STUDY') {
            return (
                patient?.files
                    .filter(
                        (file) =>
                            (file.fileType === 'ITAMAR_SLEEP_STUDY' ||
                                file.fileType === 'UPLOADED_SLEEP_STUDY') &&
                            file.status === 'APPROVED',
                    )
                    // sort by most recent
                    .sort((f1, f2) => {
                        const d1 = new Date(f1.createdAt);
                        const d2 = new Date(f2.createdAt);
                        if (d1 > d2) return -1;
                        if (d1 < d2) return 1;
                        return 0;
                    })
                    .shift()
            );
        } else {
            return patient?.files
                .filter((file) => file.fileType === fileType)
                .sort((f1, f2) => {
                    const d1 = new Date(f1.createdAt);
                    const d2 = new Date(f2.createdAt);
                    if (d1 > d2) return -1;
                    if (d1 < d2) return 1;
                    return 0;
                })
                .shift();
        }
    };

    const downloadableStudyId = getMostRecentFileByType('SLEEP_STUDY')?.id;
    const downloadableReferralId = getMostRecentFileByType('REFERRAL')?.id;
    const downloadablePrescriptionId = getMostRecentFileByType('PRESCRIPTION')?.id;

    const baseDownloadUrl = process.env.REACT_APP_API_BASE_URL
        ? process.env.REACT_APP_API_BASE_URL
        : '';

    return (
        <motion.div
            className="space-y-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="rounded-xl bg-[#22252e] p-5">
                <PatientInfo patient={patient} />
            </div>

            <div className="grid sm:grid-cols-3 gap-4">
                <PatientReferralAttribution
                    userId={patient.id}
                    patientProfile={patient.patientProfile}
                />

                <div className="rounded-xl bg-gray-darker p-5">
                    <p className="mb-3 text-xl font-bold">
                        Lab Order Approval: Home Sleep Study (CPT:95800)
                    </p>
                    {!patient.patientProfile.hstLabOrderUserId && (
                        <div className="flex space-x-4">
                            <button
                                className="btn-primary-small"
                                disabled={
                                    signOffLoading ||
                                    patient.patientProfile.homeSleepStudyStatus ===
                                        'STUDY_IN_CART' ||
                                    patient.patientProfile.homeSleepStudyStatus === 'NOT_INITIATED'
                                }
                                onClick={async () => {
                                    await sendHSTOrderSignOff({
                                        patientProfileId: patient.patientProfile.id,
                                        userId: patient.id,
                                    });
                                }}>
                                Verify HST Order
                            </button>
                        </div>
                    )}
                    {patient.patientProfile.hstLabOrderUserId && (
                        <p>
                            Electronically Signed By:{' '}
                            {patient.patientProfile.hstLabOrderUserId === user.id
                                ? `${user.firstName} ${user.lastName} ${
                                      user.credentials ? `, ${user.credentials}` : ''
                                  }`
                                : doctor
                                  ? `${doctor.firstName} ${doctor.lastName} ${
                                        doctor.credentials?.length > 0
                                            ? `, ${doctor.credentials}`
                                            : ''
                                    }`
                                  : patient.patientProfile.hstLabOrderUserId}{' '}
                            <br />
                            Date: {formatDate(patient.patientProfile.hstLabOrderDate)}
                        </p>
                    )}
                </div>

                <div className="rounded-xl bg-gray-darker p-5">
                    <p className="mb-3 text-xl font-bold">Documents:</p>
                    <div className="grid grid-rows gap-4">
                        {' '}
                        {downloadableStudyId && (
                            <a
                                className="btn-primary-small text-base lg:text-lg"
                                href={`${baseDownloadUrl}/files/sleep-study/${downloadableStudyId}?storageToken=${storageToken}`}
                                download
                                target="_blank"
                                rel="noreferrer">
                                Download sleep study
                            </a>
                        )}
                        {downloadableReferralId && (
                            <a
                                className="btn-primary-small text-base lg:text-lg"
                                href={`${baseDownloadUrl}/files/referral/${downloadableReferralId}?storageToken=${storageToken}`}
                                download
                                target="_blank"
                                rel="noreferrer">
                                Dental Sleep Medicine Written Order
                            </a>
                        )}
                        {downloadablePrescriptionId && (
                            <a
                                className="btn-primary-small text-base lg:text-lg"
                                href={`${baseDownloadUrl}/files/prescription/${downloadablePrescriptionId}?storageToken=${storageToken}`}
                                download
                                target="_blank"
                                rel="noreferrer">
                                Prescription File
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className="rounded-xl bg-gray-darker p-5">
                <p className="mb-3 text-xl font-bold border-b border-gray pb-3">
                    Patient Diagnosis:
                </p>
                <PatientDiagnosis patient={patient} />
            </div>

            <div className="rounded-xl bg-gray-darker p-5">
                <p className="mb-3 text-xl font-bold">Clinical notes:</p>
                <PatientNotes
                    patientId={patient.id}
                    itamarReports={patient.patientProfile.itamarReports}
                />
            </div>
        </motion.div>
    );
};

export default MedicalTab;
