import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import './TextCheckList.scss';

const TextCheckList = ({ choices, choiceHandler, multiple = false, uniqueChoice = false }) => {
    const [selectedChoices, setSelectedChoices] = useState([]);

    const isDisabled = (choice) => {
        if (uniqueChoice !== false && selectedChoices.includes(uniqueChoice)) {
            if (choice.value !== uniqueChoice) {
                return true;
            }
        } else if (
            uniqueChoice !== false &&
            selectedChoices.length > 0 &&
            !selectedChoices.includes(uniqueChoice)
        ) {
            if (choice.value === uniqueChoice) {
                return true;
            }
        }
        return false;
    };

    const multipleChoiceContinueHandler = (ev) => {
        choiceHandler(selectedChoices);
    };

    const clickHandler = (choice, choiceIdx) => {
        let newChoices = selectedChoices;

        if (multiple && selectedChoices.includes(choice)) {
            newChoices = newChoices.filter((el) => el !== choice);
        } else {
            newChoices = multiple ? [...new Set([...selectedChoices, choice])] : [choice];
        }

        setSelectedChoices(newChoices);
    };

    return (
        <>
            <ul className="divide-y divide-purple-medium border border-purple-medium gem-text-answer-list">
                {choices.map((choice, idx) => (
                    <li
                        key={`tal-choice-${idx}`}
                        className={`choice-item overflow-hidden ${
                            selectedChoices.includes(choice.value) ? 'selected-choice' : ''
                        } ${isDisabled(choice) ? 'disabled-choice' : ''}`}
                        onClick={() => {
                            if (!isDisabled(choice)) {
                                clickHandler(choice.value, idx);
                            }
                        }}>
                        <span className="choice-checkbox">
                            <FaCheck className="checkmark transition absolute-center w-2 scale-150 pointer-events-none text-white" />
                        </span>
                        <span className="choice-label relative leading-normal text-xs xs:text-sm sm:text-base">
                            {choice.label}
                        </span>
                    </li>
                ))}
            </ul>
            {multiple && (
                <button
                    className="btn-primary mt-5 block text-center w-full mx-auto disabled:opacity-25 disabled:pointer-events-none"
                    disabled={selectedChoices.length === 0}
                    onClick={multipleChoiceContinueHandler}>
                    Proceed to final question
                </button>
            )}
        </>
    );
};

export default TextCheckList;
