import { api } from '../auth';
import { postPaginatedQuery } from '../../../utils/constants';

function providesPartialList(resultsWithIds, tagType) {
    return resultsWithIds?.results
        ? [
              { type: tagType, id: 'PARTIAL_LIST' },
              ...resultsWithIds.results.map(({ id }) => ({ type: tagType, id })),
          ]
        : [{ type: tagType, id: 'PARTIAL_LIST' }];
}

const searchAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        deleteAdminQuery: builder.mutation({
            query: (id) => ({
                url: `/admin/queries/${id}`,
                method: 'DELETE',
                body: { id: id },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Queries' }],
        }),
        getAdminQueries: builder.query({
            query: () => ({
                url: '/admin/queries',
                method: 'GET',
            }),
            providesTags: (result) => providesPartialList(result, 'Queries'),
        }),
        getUsers: builder.query({
            query: ({
                appointments,
                careTeam,
                insurance,
                orderBy,
                patientProfileSearch,
                querySearch,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                state,
                take,
            }) => {
                const formattedQuery = postPaginatedQuery({
                    appointments,
                    careTeam,
                    insurance,
                    orderBy,
                    patientProfileSearch,
                    querySearch,
                    resupply,
                    searchTermType,
                    searchTermValue,
                    skip,
                    state,
                    take,
                });
                return {
                    url: '/users',
                    method: 'POST',
                    body: formattedQuery,
                };
            },
            providesTags: (result) => providesPartialList(result, 'User'),
        }),
        postAdminQuery: builder.mutation({
            query: ({
                skip,
                take,
                patientProfileSearch,
                careTeam,
                resupply,
                searchTermValue,
                searchTermType,
                orderBy,
                insurance,
                querySearch,
                appointments,
                userId,
                queryName,
                publicQuery,
                state,
            }) => {
                const queryToPost = postPaginatedQuery({
                    appointments,
                    careTeam,
                    insurance,
                    orderBy,
                    patientProfileSearch,
                    querySearch,
                    resupply,
                    searchTermType,
                    searchTermValue,
                    skip,
                    state,
                    take,
                });
                return {
                    url: '/admin/queries',
                    method: 'POST',
                    body: {
                        userId: userId,
                        queryName: queryName,
                        queryString: queryToPost,
                        publicQuery: publicQuery,
                    },
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Queries', id: 'PARTIAL_LIST' }],
        }),
        updateAdminQuery: builder.mutation({
            query: ({
                skip,
                take,
                patientProfileSearch,
                careTeam,
                searchTermValue,
                searchTermType,
                orderBy,
                insurance,
                querySearch,
                appointments,
                userId,
                queryName,
                queryId,
                state,
            }) => {
                const queryToPost = postPaginatedQuery({
                    skip,
                    take,
                    patientProfileSearch,
                    careTeam,
                    searchTermValue,
                    searchTermType,
                    orderBy,
                    insurance,
                    querySearch,
                    appointments,
                    state,
                });
                return {
                    url: `/admin/queries/${queryId}`,
                    method: 'PUT',
                    body: {
                        userId: userId,
                        queryName: queryName,
                        queryString: queryToPost,
                        publicQuery: false,
                    },
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdminQueriesQuery,
    useGetUsersQuery,
    useDeleteAdminQueryMutation,
    usePostAdminQueryMutation,
    useUpdateAdminQueryMutation,
} = searchAPI;
