import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateAdminUserMutation } from '../../app/services/admin/admin';
import { CustomCheckbox, CustomInput } from '../../components/InputComponents';
import { createAdminUserSchema } from '../../utils/schemas';

const CreateUser = () => {
    const navigate = useNavigate();
    const [createAdminUser] = useCreateAdminUserMutation();

    const submitHandler = async (formData) => {
        formData.redirectUrl =
            window.location.origin + '/reset-password?fromNewAdminVerification=true';
        formData.userRoles = [];
        if (formData.roleMD) formData.userRoles.push({ role: 'MD' });
        if (formData.roleCoach) formData.userRoles.push({ role: 'COACH' });
        if (formData.phone) formData.phone = formData.phone.replace(/\D/g, '');
        if (formData.textNumber) formData.textNumber = formData.textNumber.replace(/\D/g, '');
        await createAdminUser(formData)
            .unwrap()
            .then((res) => {
                navigate('/admin/administrators');
                toast.success('New Admin User Created!', {
                    theme: 'dark',
                });
            })
            .catch((error) => {
                toast.error(error.data?.message, { theme: 'dark' });
            });
    };
    return (
        <div className="py-12">
            <div className="flex justify-between">
            <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Create new user</h2>
            <button
                className="font-heading text-lg tracking-widest text-gray transition hover:text-white"
                onClick={(ev) => {
                    navigate('/admin/administrators');
                }}>
                back to administrators
            </button>
            </div>
            
            <Formik
                validationSchema={createAdminUserSchema}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    organization: '',
                    roleMD: true,
                    roleCoach: false,
                    credentials: '',
                    imageUrl: '',
                    quote: '',
                    phone: '',
                    textNumber: '',
                }}
                onSubmit={submitHandler}>
                {({ dirty, isValid, isSubmitting }) => (
                    <Form className="space-y-6">
                        <div className="grid grid-cols-2 gap-8">
                            <CustomInput name="firstName" label="First Name" />
                            <CustomInput name="lastName" label="Last Name" />
                            <CustomInput
                                type="email"
                                name="email"
                                label="Email"
                                autoComplete="username"
                            />
                            <CustomInput name="organization" label="Organization" />
                            <CustomInput name="phone" label="Phone(Voice)" />
                            <CustomInput name="textNumber" label="Phone(SMS/text)" />
                            <CustomInput name="credentials" label="Credentials" />
                            <CustomInput name="imageUrl" label="Image Url" />
                            <Field
                                as="textarea"
                                id="quote"
                                name="quote"
                                className="mb-8 h-40 w-full border border-white bg-gray-darker p-5 lg:mb-12 lg:px-6"
                                placeholder="Enter quote"></Field>
                        </div>
                        <div id="checkbox-group">Roles</div>
                        <div className="grid grid-cols-1 gap-8">
                            <CustomCheckbox name="roleMD" id="roleMD" label="MD" />
                            <CustomCheckbox name="roleCoach" id="roleCoach" label="COACH" />
                        </div>
                        <button
                            className="btn-primary w-full"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}>
                            Create user
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateUser;
