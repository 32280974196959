import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddToCartMutation } from '../../app/services/cart';
import { PageHeader } from '../../components/LayoutComponents';
import { toggleCart } from '../../features/ui/uiSlice';
import Howell from '../../images/headshots/dr_Howell.jpg';
import Khranke from '../../images/headshots/dr_Krahnke.jpg';
import Vining from '../../images/headshots/dr_vining.jpg';
import Mindy from '../../images/headshots/Mindy-Headshot.jpg';
import Suzanne from '../../images/headshots/Suzanne-Headshot-final.jpg';
import Sam from '../../images/headshots/Sam-Headshot.jpg';
import { ReactComponent as Calendar } from '../../images/icons/calendar.svg';
import Logo from '../../images/logo.svg';
import './CareTeam.css';

const CareTeam = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [addToCart] = useAddToCartMutation();
    const [coach, setCoach] = useState(user?.careTeam.find((member) => member.role === 'COACH'));

    useEffect(() => {
        if (user?.careTeam?.length > 0) {
            setCoach(user?.careTeam.find((member) => member.role === 'COACH'));
        }
    }, [user]);

    return (
        <>
            <div className="layout-outlet-root">
                <header className="mb-4 max-w-4xl">
                    <PageHeader>
                        <>
                            <span className="text-gray-darker">
                                {user ? `${user.firstName}'s` : `...`}
                            </span>{' '}
                            <span className="text-gem-purple">Care team</span>
                        </>
                    </PageHeader>
                </header>
                <section className="mt-2 max-w-4xl">
                    <h3 className="mb-2 font-heading text-4xl">Your Patient Experience Team</h3>
                    <p>
                        This group of individuals will help you navigate the GEM experience from
                        ordering an HST through adjusting to therapy. Once you order a CPAP your
                        assigned coach will reach out to you.
                    </p>
                    <ul className="clinical-team-list mt-5">
                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image"
                                            style={{
                                                backgroundImage: `${`url(${Suzanne})`}`,
                                            }}></div>
                                    </div>
                                </div>
                                <p className="text-lg">
                                    Coach
                                    {coach &&
                                        user.careTeam[0].careTeamUser.firstName === 'Suzanne' && (
                                            <span>
                                                {' - '}
                                                {user.careTeam[0].careTeamUser.firstName}{' '}
                                                <span className="inline md:block">
                                                    (Your Assigned Coach)
                                                </span>
                                            </span>
                                        )}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image"
                                            style={{ backgroundImage: `url(${Sam})` }}></div>
                                    </div>
                                </div>
                                <p className="text-lg">
                                    Coach
                                    {coach && user.careTeam[0].careTeamUser.firstName === 'Sam' && (
                                        <span>
                                            {' - '}
                                            {user.careTeam[0].careTeamUser.firstName}{' '}
                                            <span className="inline md:block">
                                                (Your Assigned Coach)
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                        </li>

                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image bg-white"
                                            style={{ backgroundImage: `url(${Mindy})` }}></div>
                                    </div>
                                </div>
                                <p className="text-lg">Order Specialist</p>
                            </div>
                        </li>

                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image bg-white"
                                            style={{ backgroundImage: `url(${Logo})` }}></div>
                                    </div>
                                </div>
                                <p className="text-lg">Patient Care Specialist</p>
                            </div>
                        </li>
                    </ul>
                </section>
                <section className="clinical-team max-w-4xl">
                    <header className="clinical-team mb-2 max-w-4xl">
                        <h3 className="mb-2 font-heading text-4xl">Your Clinical Team</h3>
                        <p>
                            Your GEM clinicians specialize in sleep apnea. They follow you through
                            your journey, including reviewing sleep study results, creating
                            personalized treatment recommendations, writing prescriptions and
                            overseeing your treatment. If you'd like to meet them virtually please
                            schedule a consult.
                        </p>
                        <div className="grid grid-cols-2 gap-x-2">
                            {(user?.patientProfile.homeSleepStudyStatus === 'STUDY_REVIEWED' ||
                                user?.patientProfile.mdStatus ===
                                    'UPLOADED_SLEEP_STUDY_APPROVED') && (
                                <button
                                    onClick={() => {
                                        addToCart([
                                            {
                                                quantity: 1,
                                                sku: 'DIACLINICAL',
                                                single: true,
                                            },
                                        ]);

                                        dispatch(toggleCart(true));
                                    }}
                                    className="btn-primary inline-flex items-center mt-4 w-full">
                                    Schedule a consult{' '}
                                    <Calendar style={{ color: 'white', marginLeft: '0.75rem' }} />
                                </button>
                            )}
                            {(user.patientProfile.treatments?.find(
                                (treatment) =>
                                    treatment.isActive && treatment.treatmentType === 'CPAP',
                            ) ||
                                user?.patientProfile?.cpapOrderStatus === 'REFERRED') && (
                                <button
                                    onClick={() => {
                                        addToCart([
                                            {
                                                quantity: 1,
                                                sku: 'COMPLIANCE',
                                                single: true,
                                            },
                                        ]);

                                        dispatch(toggleCart(true));
                                    }}
                                    className="btn-primary inline-flex items-center mt-4 w-full">
                                    Schedule a compliance visit{' '}
                                    <Calendar style={{ color: 'white', marginLeft: '0.75rem' }} />
                                </button>
                            )}
                        </div>
                    </header>
                    <ul className="clinical-team-list mt-5">
                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image"
                                            style={{ backgroundImage: `url(${Khranke})` }}></div>
                                    </div>
                                </div>
                                <p className="font-heading text-2xl">Dr. Jason Krahnke</p>
                                <p className="text-lg">DO</p>
                            </div>
                        </li>

                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image"
                                            style={{ backgroundImage: `url(${Howell})` }}></div>
                                    </div>
                                </div>
                                <p className="font-heading text-2xl">Dr. Mike Howell</p>
                                <p className="text-lg">MD</p>
                            </div>
                        </li>

                        <li>
                            <div>
                                <div>
                                    <div className="care-team-member-image-frame">
                                        <div
                                            className="care-team-member-image bg-white"
                                            style={{ backgroundImage: `url(${Vining})` }}></div>
                                    </div>
                                </div>
                                <p className="font-heading text-2xl">Julie Vining</p>
                                <p className="text-lg">NP</p>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </>
    );
};

export default CareTeam;
