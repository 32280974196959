import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useGetRecommendedCPAPBundlesQuery } from '../../app/services/orders';
import { useGetBlogPostsQuery } from '../../app/services/webflow';
import { toggleModal } from '../../features/ui/uiSlice';
import { patientInDMEReferral } from '../../utils/constants';
//Images
import { FaCheck } from 'react-icons/fa';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
//Components
import { CpapCoverageModal, useInsuranceContext } from '../../components/Insurance';
import { PageHeader } from '../../components/LayoutComponents';
import {
    CPAPTherapyReorder,
    CareTeamCTA,
    DMETherapy,
    OralTherapyTreatment,
    RetestNeeded,
    InPersonClinicNeeded,
    RecommendedCPAPs,
    ResupplyStatus,
    DentalReferral,
} from '../../components/Treatment';
import { AppointmentReminder } from '../../components/Appointments';
import { ArticlesSlider, ProductImage } from '../../components';

const Home = () => {
    const dispatch = useDispatch();

    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { recommendedSku } = useSelector((state) => state.catalog);
    const { pendingMaskFitReview, inTreatmentSupportOnly } = useSelector((state) => state.ui);

    //Context
    const { insurance } = useInsuranceContext();

    //Queries
    const { data: recommendedCPAPBundles, refetch: refetchBundles } =
        useGetRecommendedCPAPBundlesQuery();
    const { data: blogPosts } = useGetBlogPostsQuery();

    //User not yet referred for cpap or in treatment
    const showPreRecCondition =
        !user?.patientProfile?.isDentalReferred &&
        !user?.patientProfile?.referredForCpap &&
        !user?.patientProfile?.needsSleepStudyRetest &&
        !user?.patientProfile?.referToSleepClinic &&
        !inTreatmentSupportOnly;

    //Used to flag that the cpap is ordered
    const cpapOrderedButNotDelivered = [
        'SUPPLIES_ORDERED',
        'SHIPPED',
        'FULFILLMENT_ORDER',
    ].includes(user?.patientProfile?.cpapOrderStatus);

    //Patient chose to be referred to DME and hasn't changed insurance
    const patientReferredToDME =
        patientInDMEReferral.includes(user?.patientProfile?.cpapOrderStatus) &&
        insurance?.payer?.dmeReferralId !== null &&
        recommendedCPAPBundles?.length > 0;

    //Patient in treatment with DME
    const inDMETreatment =
        user?.patientProfile?.treatments.find((treatment) => treatment.isActive) &&
        patientReferredToDME;

    //User is in dental treatment or dental treatment support
    const inDentalTreatment =
        user?.patientProfile.dentalReferralStatus === 'IN_TREATMENT' || inTreatmentSupportOnly;

    //User is in treatment with GEM
    const inTreatment =
        user?.patientProfile.cpapOrderStatus === 'DELIVERED' && !inTreatmentSupportOnly;

    //User not yet in treatment
    const userNotInTreatment = !inTreatment && !inDentalTreatment && !inDMETreatment;

    //Refetch the bundles if their mask fit review finishes
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        refetchBundles();
    }, [pendingMaskFitReview, refetchBundles]);

    return user?.patientProfile?.homeSleepStudyStatus === 'HST_RECEIVED_IN_OFFICE' ? (
        <Navigate to="dentist-confirmation" />
    ) : showPreRecCondition ? (
        <Navigate to="treatment-options" />
    ) : (
        <>
            <PageHeader>
                {user?.firstName ?? '_USER_'}'s <span className="text-gray-six">treatment</span>
            </PageHeader>
            {inDMETreatment ? null : inTreatment ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    You can check back here anytime to get support with your current treatment,
                    order additional supplies or explore additional treatment options. We have your
                    back the whole time.
                </p>
            ) : inDentalTreatment ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    You can check back here anytime to get support with your current treatment,
                    review your dentist's contact information or explore additional treatment
                    options. We have your back the whole time.
                </p>
            ) : patientReferredToDME ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    Below are your treatment recommendations from GEM SLEEP. NOTE: We've submitted a
                    referral to {insurance?.payer.dmeReferral?.name}, {insurance?.payer.name}'s
                    preferred CPAP supplier. If you wish to cancel your order and want to pay on
                    your own (including HSA/FSA) you may proceed forward with ordering through GEM
                    SLEEP. Click 'order now' to complete the checkout process. .
                </p>
            ) : user?.patientProfile?.needsSleepStudyRetest ||
              user?.patientProfile?.referToSleepClinic ? null : (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    GEM SLEEP has created a personalized treatment recommendation for you. Read more
                    about each option below and decide which will work best to get you back on track
                    to restful sleep. GEM SLEEP will have your back the whole time. From supplies to
                    support, we are here for you!
                </p>
            )}
            <AppointmentReminder />

            {user?.patientProfile?.needsSleepStudyRetest && <RetestNeeded />}

            {user?.patientProfile?.referToSleepClinic && <InPersonClinicNeeded />}

            {inTreatment && (
                <>
                    <ResupplyStatus />
                    <CPAPTherapyReorder />
                </>
            )}

            {inDentalTreatment && <OralTherapyTreatment />}

            {user?.patientProfile?.cpapOrderStatus === 'REFERRED' && <DMETherapy />}

            {userNotInTreatment && (
                <>
                    {recommendedCPAPBundles?.length > 0 && !cpapOrderedButNotDelivered && (
                        <RecommendedCPAPs
                            cpaps={recommendedCPAPBundles}
                            user={user}
                            patientReferredToDME={patientReferredToDME}
                        />
                    )}

                    {(recommendedCPAPBundles?.length === 0 || cpapOrderedButNotDelivered) &&
                        user.patientProfile.referredForCpap && (
                            <div
                                className={`relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4 ${
                                    cpapOrderedButNotDelivered ? 'border border-gem-green' : ''
                                }`}>
                                {cpapOrderedButNotDelivered && (
                                    <div className="pointer-events-none absolute top-0 right-0 z-10 inline-block w-11 overflow-hidden transition">
                                        <div className="relative h-16 origin-top-left -rotate-45 transform bg-gem-green">
                                            <FaCheck className="absolute left-3 top-5 origin-top-left rotate-45 text-white" />
                                        </div>
                                    </div>
                                )}
                                <ProductImage
                                    sku={recommendedSku}
                                    alt="A CPAP bundle mask"
                                    className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
                                />

                                <div className="w-full sm:w-3/4 max-w-xl">
                                    <span
                                        className={`mb-3 inline-block px-1 font-heading text-sm tracking-widest ${
                                            cpapOrderedButNotDelivered
                                                ? 'bg-green-200'
                                                : 'bg-purple-lightest'
                                        }`}>
                                        {cpapOrderedButNotDelivered ? 'Ordered' : 'Recommended'}
                                    </span>
                                    <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                                        GEM SLEEP CPAP STARTER BUNDLE
                                    </h5>
                                    <p className="mb-4 text-[13px] leading-normal lg:text-base">
                                        All the items you need to get started on CPAP, including a
                                        90-day supply of replacement parts, delivered directly to
                                        your door!
                                    </p>
                                    <div className="items-center space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                                        {!pendingMaskFitReview && !recommendedSku && (
                                            <CpapCoverageModal />
                                        )}
                                        {pendingMaskFitReview && (
                                            <button
                                                className="btn-primary flex items-center w-full xl:w-[280px] max-h-[68px] "
                                                onClick={() => {
                                                    dispatch(toggleModal('maskFitLinkPending'));
                                                }}>
                                                Pending size review
                                            </button>
                                        )}

                                        <button
                                            className="btn-shell flex w-full items-center justify-between space-x-4 xl:w-[280px] max-h-[68px]"
                                            onClick={(ev) => {
                                                dispatch(toggleModal('contact'));
                                            }}>
                                            <span>Talk to someone</span>
                                            <PhoneIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    {user.patientProfile.isDentalReferred && <DentalReferral />}
                </>
            )}
            {!user?.patientProfile?.referToSleepClinic && <CareTeamCTA />}
            {blogPosts && (
                <ArticlesSlider
                    className="mb-10"
                    label="Sleep Resources"
                    slidesPerViewDesktop={3}
                    slides={blogPosts}
                />
            )}
        </>
    );
};

export default Home;
