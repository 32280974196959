import { Link } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg';
import denturesSrc from '../../images/dentures.jpg';

function DentalReferral() {
    return (
        <div className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4">
            <img
                src={denturesSrc}
                alt="Dentures"
                className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
            />
            <div className="w-full sm:w-3/4 max-w-xl">
                <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                    Referral to Dental Sleep Medicine
                </h5>
                <p className="mb-4 text-[13px] lg:text-base max-w-xl">
                    You may be a great candidate for an Oral Appliance used to treat Sleep Apnea.
                </p>
                <p className="mb-4 text-xs font-semibold lg:text-sm">
                    * Oral Appliance treatment requires lower teeth.
                </p>
                <Link
                    to={'../appointments/dental-appointment'}
                    className="btn-primary w-full flex justify-between max-w-xl">
                    <span className="pt-0.5 sm:pt-0">Schedule a free phone consult</span>
                    <CalendarIcon />
                </Link>
            </div>
        </div>
    );
}

export default DentalReferral;
