import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppointmentBoxListItems } from '../../components/Appointments';

const AppointmentConfirmed = () => {
    const { currentAppointment } = useSelector((state) => state.ui);

    return (
        <>
            <h3 className="mb-3 text-center font-heading text-[44px] lg:text-7xl">
                You're booked!
            </h3>
            <div className="mx-auto max-w-lg bg-white p-16 text-gray-darker">
                <div>
                    <p className="mb-5 text-sm font-bold text-gem-purple">Session Details</p>
                    {currentAppointment ? (
                        <ul className="mb-8 space-y-4 text-lg">
                            <AppointmentBoxListItems appointment={currentAppointment} />
                        </ul>
                    ) : (
                        <p>Loading...</p>
                    )}
                    <Link to="/dashboard" className="btn-primary mx-auto block">
                        Back to home
                    </Link>
                </div>
            </div>
        </>
    );
};

export default AppointmentConfirmed;
