import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';

const NoSleepApnea = () => {
    ReactGA.event({
        category: 'User',
        action: 'Assessment Result',
        label: 'disqualified_waitlist',
    });

    return (
        <motion.div
            key="screen-0"
            initial={{ opacity: 0, translateX: '20%' }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: '-20%' }}
            transition={{ ease: 'linear' }}
            className="mx-auto">
            <div className="mx-auto max-w-5xl text-center">
                <h4 className="mb-6 font-heading text-3xl text-purple-fair">ASSESSMENT RESULTS:</h4>
                <p className="text-xl">Based on your answers,</p>
                <h2 className="mb-4 font-heading text-5xl font-bold text-white lg:mb-5 lg:text-8xl">
                    you do not appear to have the typical signs of sleep apnea.
                </h2>

                <div className="mx-auto mt-8 mb-2 max-w-3xl">
                    <p className="mb-4 text-lg text-gray-light">
                        But we understand that even if you may not have sleep apnea, you still are
                        not getting the rest you deserve. We recommend the{' '}
                        <a
                            href="https://www.sleepperformanceinstitute.com/"
                            className="border-b border-gem-green"
                            target="_blank"
                            rel="noreferrer">
                            Sleep Journey App
                        </a>{' '}
                        to understand other potential causes and solutions for sleep disturbances.{' '}
                    </p>
                    <p className="mb-4 text-lg text-gray-light">
                        GEM SLEEP will be continuing to expand our SLEEP product offerings. Provide
                        your email to stay up to date on our solutions.
                    </p>
                    <div className="mt-16">
                        <iframe
                            className="mx-auto w-full"
                            src={`${process.env.PUBLIC_URL}/ac-no-apnea-capture.html`}
                            title="No apnea AC capture form"
                            width="500"
                            height="400"
                            frameBorder="0"></iframe>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default NoSleepApnea;
