import { useState, useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';

const TextAnswerList = ({ choices, choiceHandler, multiple = false, uniqueChoice = false }) => {
	const [selectedChoices, setSelectedChoices] = useState([]);
	const controls = useAnimation();

	useEffect(() => {
		controls.set({ y: '100%' });
	}, [controls]);

	const multipleChoiceContinueHandler = (ev) => {
		choiceHandler(selectedChoices);
	};

	const clickHandler = (choice, choiceIdx) => {
		let newChoices = selectedChoices;

		if (multiple && selectedChoices.indexOf(choice) > -1) {
			newChoices = newChoices.filter((el) => el !== choice);
		} else {
			newChoices = multiple ? [...new Set([...selectedChoices, choice])] : [choice];
		}

		setSelectedChoices(newChoices);

		if (!multiple) {
			controls
				.start({ y: ['100%', '0%', '-100%'], transition: { ease: 'easeInOut', duration: 0.85 } })
				.then(() => {
					choiceHandler(choice, choiceIdx);
				});
		}
	};

	return (
		<>
			<ul className="divide-y divide-purple-medium border border-purple-medium">
				{choices.map((choice, idx) => (
					<li
						key={`tal-choice-${idx}`}
						className={`transition ease-out-quad hover:cursor-pointer duration-300 font-semibold text-lg lg:text-[28px] tracking-tighter text-center py-4 lg:py-8 hover:bg-purple-medium/20 relative overflow-hidden ${
							(selectedChoices.length > 0 && selectedChoices.indexOf(choice.value) < 0) ||
							selectedChoices.length === 0
								? 'opacity-50'
								: multiple
								? 'bg-green-400 hover:bg-green-400'
								: ''
						} ${
							uniqueChoice !== false &&
							((selectedChoices[0] === uniqueChoice && choice.value !== uniqueChoice) ||
								(selectedChoices.length > 0 &&
									selectedChoices.indexOf(uniqueChoice) < 0 &&
									choice.value === uniqueChoice))
								? '!opacity-25 pointer-events-none'
								: ''
						}`}
						onClick={() => {
							clickHandler(choice.value, idx);
						}}>
						<motion.div
							className={`absolute bottom-0 left-0 pointer-events-none bg-green-400 w-full h-full ${
								selectedChoices.indexOf(choice.value) > -1 ? 'block' : 'hidden'
							}`}
							animate={controls}></motion.div>
						<span className="relative leading-normal">{choice.label}</span>
					</li>
				))}
			</ul>
			{multiple && (
				<button
					className="mt-10 block text-center w-auto mx-auto border-b-2 border-green-400 disabled:opacity-25 disabled:pointer-events-none"
					disabled={selectedChoices.length === 0}
					onClick={multipleChoiceContinueHandler}>
					Continue
				</button>
			)}
		</>
	);
};

export default TextAnswerList;
