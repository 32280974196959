import { useState } from 'react';
import {
    useCreateTreatmentMutation,
    useDeleteTreatmentMutation,
    useGetTreatmentsQuery,
    useUpdateTreatmentMutation,
} from '../../../../app/services/admin/treatment';
import { useGetPartnerOrganizationsQuery } from '../../../../app/services/insurance';

const TreatmentTypes = ['CPAP', 'DENTAL'];

const cpapDevices = {
    ResMed: ['AirSense 11'],
    'React Health': ['Luna G3'],
    ResVent: ['iBreeze-20A'],
};

const formatTreatmentDate = (d) => {
    const dateString = d.toISOString().substring(0, 10);
    const dateParts = dateString.split('-');
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
};

const CurrentTreatments = ({ patient }) => {
    const { data: treatments, isFetching } = useGetTreatmentsQuery(patient.id, {
        skip: !patient.id,
    });
    const [createTreatment, { error: createTreatmentError }] = useCreateTreatmentMutation();
    const [updateTreatment, { error: updateTreatmentError }] = useUpdateTreatmentMutation();
    const [deleteTreatment] = useDeleteTreatmentMutation();
    const { data: treatmentProviders } = useGetPartnerOrganizationsQuery({ paginated: false });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addFormActive, setAddFormActive] = useState(false);

    const defaultTreatmentFields = {
        treatmentType: 'CPAP',
        treatmentProviderId: null,
        startDate: null,
        endDate: null,
        isActive: true,
    };

    const addTreatmentHandler = async (treatmentFields) => {
        if (!isSubmitting) {
            setIsSubmitting(true);

            await createTreatment({
                userId: patient.id,
                treatmentFields: treatmentFields,
            });

            setIsSubmitting(false);
            setAddFormActive(false);
        }
    };

    const updateTreatmentHandler = async (treatmentFields) => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            const { id, ...rest } = treatmentFields;
            await updateTreatment({ userId: patient.id, treatmentId: id, treatmentFields: rest });
            setIsSubmitting(false);
        }
    };

    const deleteTreatmentHandler = async (treatmentId) => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            await deleteTreatment({ userId: patient.id, treatmentId });
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            {(treatments && treatments.length > 0) || addFormActive ? (
                <ul>
                    {treatments.map((treatment) => (
                        <li key={treatment.id}>
                            <TreatmentLineItem
                                treatment={treatment}
                                addOrUpdateTreatmentHandler={updateTreatmentHandler}
                                deleteOrCancelTreatmentHandler={deleteTreatmentHandler}
                                treatmentProviders={treatmentProviders}
                            />
                        </li>
                    ))}
                    {addFormActive && (
                        <li key="new">
                            <TreatmentLineItem
                                treatment={defaultTreatmentFields}
                                addOrUpdateTreatmentHandler={addTreatmentHandler}
                                deleteOrCancelTreatmentHandler={() => setAddFormActive(false)}
                                treatmentProviders={treatmentProviders}
                                setAddFormActive={setAddFormActive}
                            />
                        </li>
                    )}
                </ul>
            ) : (
                <div className="my-4">
                    <p>No current treatments found.</p>
                </div>
            )}

            {!addFormActive && !isFetching && (
                <button
                    className={`mt-4 btn-primary-small`}
                    value="Add Treatment"
                    onClick={() => {
                        setAddFormActive(true);
                    }}>{`Add Treatment`}</button>
            )}

            {isFetching && <div className="p-8">Loading...</div>}

            <div>
                {createTreatmentError && (
                    <p className="text-red-500">{`Error creating new treatment: ${createTreatmentError}`}</p>
                )}
                {updateTreatmentError && (
                    <p className="text-red-500">{`Error updating treatment: ${updateTreatmentError}`}</p>
                )}
            </div>
        </div>
    );
};

const TreatmentLineItem = ({
    treatment,
    addOrUpdateTreatmentHandler,
    deleteOrCancelTreatmentHandler,
    treatmentProviders,
    setAddFormActive,
}) => {
    const [isEditing, setIsEditing] = useState(treatment.id ? false : true);
    const initialValues = { ...treatment };
    const [values, setValues] = useState({ ...treatment });

    const buttonStyle = {
        display: 'block',
        width: '100%',
        marginBottom: '0.5rem',
    };

    const deleteTreatment = () => {
        // open the modal to confirm the deletion of the treatment
        if (!treatment.id) {
            // call cancel handler
            deleteOrCancelTreatmentHandler(undefined);
        } else {
            const confirmDelete = window.confirm('Are you sure you want to delete this treatment?');
            if (confirmDelete) {
                deleteOrCancelTreatmentHandler(treatment.id);
            }
        }
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                addOrUpdateTreatmentHandler(values);
                setIsEditing(false);
            }}
            className="relative px-4 py-4 border rounded"
            style={{ borderColor: 'rgba(250,250,250,0.2)' }}>
            <div className="flex flex-col space-y-4" style={{ width: '80%' }}>
                <div>
                    <label className="text-gray mr-4">Active: </label>
                    <span>{!isEditing && treatment.isActive.toString()}</span>
                </div>
                <div>
                    <label className="text-gray mr-4">Treatment: </label>

                    {!isEditing ? (
                        <span>{treatment.treatmentType}</span>
                    ) : (
                        <div className="relative flex-1 border-b border-b-gray pb-2">
                            <select
                                name="treatmentType"
                                className="appearance-none bg-transparent w-full"
                                defaultValue={initialValues.treatmentType}
                                onChange={(e) => {
                                    setValues({ ...values, treatmentType: e.target.value });
                                }}>
                                {TreatmentTypes.map((tt, index) => (
                                    <option key={`${tt}-${index}`} value={tt}>
                                        {tt}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>

                <div>
                    <label className="text-gray mr-4">Provider: </label>

                    {!isEditing ? (
                        <span>
                            {treatment.treatmentProvider?.name
                                ? treatment.treatmentProvider?.name
                                : 'GEM'}
                        </span>
                    ) : (
                        <div className="relative flex-1 border-b border-b-gray pb-2">
                            <select
                                name="treatmentProvider"
                                className="appearance-none bg-transparent w-full"
                                defaultValue={initialValues.treatmentProviderId}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        treatmentProviderId: e.target.value,
                                    });
                                }}>
                                <option key="GEM" value="">
                                    GEM
                                </option>
                                {treatmentProviders?.results
                                    .filter((provider) => {
                                        if (values.treatmentType === 'CPAP') {
                                            return provider.organizationType === 'DME_PROVIDER';
                                        } else {
                                            return provider.organizationType === 'DENTAL';
                                        }
                                    })
                                    .map((provider) => (
                                        <option key={provider.id} value={provider.id}>
                                            {provider.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                </div>
                <div>
                    <label className="text-gray mr-4">Start Date: </label>
                    {!isEditing ? (
                        <span>
                            {treatment.startDate
                                ? formatTreatmentDate(new Date(treatment.startDate))
                                : 'None'}
                        </span>
                    ) : (
                        <input
                            type="date"
                            name="startDate"
                            defaultValue={
                                treatment.startDate
                                    ? new Date(treatment.startDate).toISOString().substring(0, 10)
                                    : null
                            }
                            className="appearance-none bg-transparent text-white"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, startDate: new Date(e.target.value) });
                            }}
                        />
                    )}
                </div>

                <div>
                    <label className="text-gray mr-4">End Date: </label>
                    {!isEditing ? (
                        <span>
                            {treatment.endDate
                                ? formatTreatmentDate(new Date(treatment.endDate))
                                : 'None'}
                        </span>
                    ) : (
                        <input
                            type="date"
                            name="endDate"
                            defaultValue={
                                treatment.endDate
                                    ? new Date(treatment.endDate).toISOString().substring(0, 10)
                                    : null
                            }
                            className="appearance-none bg-transparent text-white"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, endDate: new Date(e.target.value) });
                            }}
                        />
                    )}
                </div>

                <div>
                    <label className="text-gray mr-4">Manufacturer: </label>
                    {!isEditing ? (
                        <span>{treatment.deviceManufacturer}</span>
                    ) : (
                        <select
                            name="deviceModel"
                            defaultValue={treatment.deviceManufacturer}
                            className="appearance-none bg-transparent text-white"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    deviceManufacturer: e.target.value,
                                    deviceModel: '',
                                });
                            }}>
                            <option disabled selected value>
                                {' '}
                                -- Select Manufacturer --{' '}
                            </option>
                            {Object.keys(cpapDevices).map((manufacturer) => (
                                <option key={manufacturer} value={manufacturer}>
                                    {manufacturer}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <div>
                    <label className="text-gray mr-4">Model: </label>
                    {!isEditing ? (
                        <span>{values.deviceModel}</span>
                    ) : (
                        <select
                            name="deviceModel"
                            value={values.deviceModel}
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, deviceModel: e.target.value });
                            }}>
                            <option disabled selected value="">
                                {' '}
                                -- Select Model --{' '}
                            </option>
                            {cpapDevices[values.deviceManufacturer]?.map((model) => (
                                <option key={model} value={model}>
                                    {model}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div>
                    <label className="text-gray mr-4">Serial Number: </label>
                    {!isEditing ? (
                        <span>{treatment.deviceSerial}</span>
                    ) : (
                        <input
                            type="string"
                            name="deviceSerial"
                            defaultValue={treatment.deviceSerial}
                            className="appearance-none bg-transparent text-white border rounded px-2"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, deviceSerial: e.target.value });
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="absolute p-2" style={{ top: 0, right: 0 }}>
                {!isEditing && (
                    <div className="flex space-x-2">
                        <button
                            className="btn-secondary-small bg-transparent"
                            type="button"
                            onClick={() => setIsEditing(true)}
                            style={{
                                top: '8px',
                                right: '8px',
                                border: '1px solid rgba(255,255,255,0.1)',
                            }}>
                            {treatment.id ? 'Edit' : 'Add'}
                        </button>
                        <button
                            className="btn-secondary-small"
                            type="button"
                            onClick={() => deleteTreatment()}
                            style={{
                                top: '8px',
                                right: '8px',
                                border: '1px solid rgba(255,255,255,0.1)',
                            }}>
                            {treatment.id ? 'Delete' : 'Cancel'}
                        </button>
                    </div>
                )}

                {isEditing && (
                    <div className="flex flex-row space-x-2">
                        <button type="submit" className="btn-primary-small" style={buttonStyle}>
                            Save
                        </button>
                        <button
                            className="block btn-secondary-small"
                            style={buttonStyle}
                            onClick={() => {
                                setIsEditing(false);
                                if (setAddFormActive) setAddFormActive(false);
                            }}>
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </form>
    );
};

export default CurrentTreatments;
