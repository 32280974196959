import { useEffect } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    nextQuestion,
    reset,
    setAnswer,
    setPartnerId,
    setQuestions,
} from '../../features/assessment/assessmentSlice';
import { apneaQuestions } from '../../utils/constants';

const AssessmentHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { partnerId } = useParams();

    const beginClickHandler = async (diagnosisStatus) => {
        // save previously diagnosed status on click
        await dispatch(setAnswer(diagnosisStatus));
        await dispatch(nextQuestion());
        navigate('/assessment');
    };

    useEffect(() => {
        dispatch(reset());
        dispatch(setQuestions(apneaQuestions));
    }, [dispatch]);

    useEffect(() => {
        if (!partnerId) return;
        dispatch(setPartnerId(+partnerId));
    }, [partnerId, dispatch]);

    return (
        <div className="container text-gray-light">
            <div className="relative flex-col items-center text-center">
                <div className="relative mx-auto max-w-4xl lg:flex-1">
                    <h1 className="mt-5 font-heading text-5xl text-gray-light lg:mt-0 lg:mb-3 lg:text-9xl lg:leading-[1]">
                        Think you might have <span className="text-white">sleep apnea?</span>
                    </h1>
                    <span className="mb-8 mt-4 block text-base leading-normal text-gray-light lg:mb-12 lg:mr-6 lg:text-[21px]">
                        By answering a few questions, we can assess your risk of having Obstructive
                        Sleep Apnea (OSA). The assessment takes one minute. A doctor asks these same
                        questions in most sleep clinics.
                    </span>
                </div>
                <div className="mx-auto flex max-w-3xl items-center">
                    <button
                        className="btn-primary w-full text-xl"
                        onClick={() => {
                            beginClickHandler(false);
                        }}>
                        Take sleep quiz
                    </button>
                </div>

                <div className="mx-auto flex max-w-4xl items-center mb-12">
                    <button
                        className="flex w-full items-center justify-between mt-8 p-4 md:mt-16 md:p-8 rounded-3xl bg-[#1a1c27] hover:bg-[#14161f] border-[#b2b6d966] border-[1px]"
                        onClick={() => {
                            beginClickHandler(true);
                        }}>
                        <span className="text-sm font-bold text-purple-lightest md:text-xl">
                            Already diagnosed with sleep apnea?
                        </span>
                        <span className="tracking-widest flex items-center font-heading text-xl text-[19px] text-white">
                            <span className="hidden md:inline">Start Here</span>{' '}
                            <b className="inline-block w-[3rem] h-[3rem] bg-[#726AD3] rounded-[50%] ml-4 relative">
                                <FiArrowUpRight className="absolute inset-0 m-auto" />
                            </b>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssessmentHome;
