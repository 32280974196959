import { BsCheckLg } from 'react-icons/bs';

function Line({ width, height }) {
    return <div className={`${width} ${height} bg-[#6C757D]`} />;
}

function CompletedStepDot({ end, virtualVisit }) {
    return (
        <>
            <span className="flex h-[33px] w-[33px] items-center justify-center rounded-full bg-green-400 text-white border-[#484646] rounded-[50%] border-[2px]">
                <BsCheckLg className="h-[14px] w-[14px]" />
            </span>
            {!end && (
                <Line
                    height={`${virtualVisit ? 'h-[82px] lg:h-[1px]' : 'h-[130px] lg:h-[1px]'}`}
                    width={`${
                        virtualVisit
                            ? 'w-[1px] lg:w-[107px] xl:w-[202px]'
                            : 'w-[1px] lg:w-[157px] xl:w-[230px]'
                    }`}
                />
            )}
        </>
    );
}

function CurrentStepDot({ end, virtualVisit }) {
    return (
        <>
            <div className="h-[33px] w-[33px] border-[#484646] rounded-[50%] border-[2px]">
                <div className="relative top-[3%] left-[3%] w-[94%] h-[94%] border-white rounded-[50%] border-[2px]">
                    <div className="relative top-[3%] left-[3%] w-[94%] h-[94%] bg-transparent rounded-[50%]" />
                </div>
            </div>
            {!end && (
                <Line
                    height={`${virtualVisit ? 'h-[82px] lg:h-[1px]' : 'h-[130px] lg:h-[1px]'}`}
                    width={`${
                        virtualVisit
                            ? 'w-[1px] lg:w-[107px] xl:w-[202px]'
                            : 'w-[1px] lg:w-[157px] xl:w-[230px]'
                    }`}
                />
            )}
        </>
    );
}

function OtherDots({ end, virtualVisit }) {
    return (
        <>
            <div className="h-[33px] w-[33px] flex flex-col lg:flex-row items-center">
                <Line height="h-[8px] lg:h-[1px]" width="w-[1px] lg:w-[8px]" />
                <span className="h-[17px] w-[17px] border-[#484646] bg-[#A3ACB5] rounded-[50%] border-[2px]" />
                {!end && <Line height="h-[8px] lg:h-[1px]" width="w-[1px] lg:w-[8px]" />}
            </div>
            {!end && (
                <Line
                    height={`${virtualVisit ? 'h-[82px] lg:h-[1px]' : 'h-[130px] lg:h-[1px]'}`}
                    width={`${
                        virtualVisit
                            ? 'w-[1px] lg:w-[107px] xl:w-[202px]'
                            : 'w-[1px] lg:w-[157px] xl:w-[230px]'
                    }`}
                />
            )}
        </>
    );
}

function StepLabel({ text, centered, textColor }) {
    return (
        <div className="flex h-[66px] w-[90px] items-center">
            <p
                className={`font-heading text-[22px] ${textColor} ${
                    centered ? 'text-center' : 'text-left pl-6'
                }`}>
                {text}
            </p>
        </div>
    );
}

function GemSleepStudyPath({ stepsCompleted, virtualVisit }) {
    let totalSteps = 4;

    const pathLabels = [
        { text: 'Sleep apnea assessment', centered: true, current: false },

        { text: 'Sleep* Study', centered: false, current: false },
        { text: 'The right treatment', centered: true, current: false },
        { text: 'Personalized support', centered: true, current: false },
    ];

    //If virtual visit was required, splice in to the array
    if (virtualVisit) {
        pathLabels.splice(
            1,
            1,
            { text: 'Virtual Visit', centered: true, current: true },
            { text: 'Sleep Study', centered: true, current: false },
        );
        totalSteps = 5;
    }

    const steps = [];

    //Start at one to skip first completed step
    for (let i = 0; i < totalSteps; i++) {
        if (i < stepsCompleted) steps.push({ completed: true, current: false });
        else if (i === stepsCompleted) steps.push({ completed: false, current: true });
        else steps.push({ completed: false, current: false });
    }

    return (
        <div className="lg:block bg-gray-darker mt-4 text-gray-light w-full">
            <div className="p-12 lg:p-18 lg:ml-6">
                <h5 className="text-center lg:text-left text-white font-heading text-5xl mb-2">
                    The path to better sleep
                </h5>
                {virtualVisit ? (
                    <p className="text-center lg:text-left">
                        Once you complete your virtual visit we will ship your Home Sleep Study.{' '}
                    </p>
                ) : (
                    <p className="text-center lg:text-left">
                        The next step on your path is getting an official diagnosis through a home
                        sleep study
                    </p>
                )}
                <div className="flex mt-6 lg:mt-0 lg:block">
                    <div className="basis-1/2 flex flex-col items-center pt-5 pb-5 lg:pl-10 lg:pt-20 lg:flex-row ">
                        {steps.map((step, idx) =>
                            step.completed ? (
                                <CompletedStepDot
                                    virtualVisit={virtualVisit}
                                    end={idx === steps.length - 1}
                                    key={`${idx}-dot`}
                                />
                            ) : step.current ? (
                                <CurrentStepDot
                                    virtualVisit={virtualVisit}
                                    end={idx === steps.length - 1}
                                    key={`${idx}-dot`}
                                />
                            ) : (
                                <OtherDots
                                    virtualVisit={virtualVisit}
                                    end={idx === steps.length - 1}
                                    key={`${idx}-dot`}
                                />
                            ),
                        )}
                    </div>
                    <div
                        className={`basis-1/2 w-full flex flex-col mb-10 lg:flex-row lg:pl-3 lg:mb-20 ${
                            virtualVisit
                                ? 'gap-[50px] xl:gap-[145px]'
                                : 'gap-[100px] xl:gap-[173px]'
                        } `}>
                        {pathLabels.map((label, idx) => (
                            <StepLabel
                                key={`${idx}-label`}
                                centered={label.centered}
                                text={label.text}
                                textColor={label.current ? 'text-white' : ''}
                            />
                        ))}
                    </div>
                </div>
                {!virtualVisit && (
                    <p className="lg:w-[700px] xl:w-[900px]">
                        <span className="font-bold">*Virtual Visit may be required.</span> Insurance
                        health plans require a clinician visit prior to completing a sleep study.
                        It's a quick virtual visit and can be scheduled when you place your sleep
                        study order. We will submit a claim to insurance for both the visit and the
                        home sleep study.
                    </p>
                )}
            </div>
        </div>
    );
}

export default GemSleepStudyPath;
