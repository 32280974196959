import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import { getAppointmentObject } from '../../utils/constants';
import { hasRole } from '../../utils/roleUtils';

const initialState = {
    modalsOpen: [],
    accountOpen: false,
    cartOpen: false,
    navOpen: false,
    editScreenOpen: false, // Unused
    passwordResetScreenOpen: false, // Unused
    orderHistoryOpen: false,
    pendingMaskFitReview: false,
    showFeedbackBox: false,
    wore7days: null,
    wore15days: null,
    wore30days: null,
    inTreatmentSupportOnly: null,
    lastRefresh: null,
    appointmentBooking: {},
    currentAppointment: null,
    appointmentWasCancelled: false,
    agreedToInsuranceBenefits: false,
    isReschedulingAppointment: false,
    supportFilters: {
        categories: [],
        type: [],
        dropdownsOpen: [],
    },
    simpleDynamicModalMessage: '',
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        setReschedulingFlow: (state, { payload }) => {
            state.isReschedulingAppointment = payload;
        },
        toggleModal: (state, { payload: modalId }) => {
            const targetModalIdIndex = state.modalsOpen.indexOf(modalId);

            targetModalIdIndex > -1
                ? state.modalsOpen.splice(targetModalIdIndex, 1)
                : state.modalsOpen.push(modalId);
        },
        toggleCart: (state, { payload: override }) => {
            const newState = override !== undefined ? override : !state.cartOpen;

            state.cartOpen = override ? override : !state.cartOpen;

            newState
                ? document.body.classList.add('overflow-hidden')
                : document.body.classList.remove('overflow-hidden');
        },
        toggleNav: (state, { payload: override }) => {
            const newState = override !== undefined ? override : !state.navOpen;

            state.navOpen = newState;

            newState
                ? document.body.classList.add('overflow-hidden')
                : document.body.classList.remove('overflow-hidden');
        },
        toggleAccount: (state, { payload: override }) => {
            const newState = override !== undefined ? override : !state.accountOpen;

            state.accountOpen = override !== undefined ? override : !state.accountOpen;

            newState
                ? document.body.classList.add('overflow-hidden')
                : document.body.classList.remove('overflow-hidden');
        },
        toggle: (state, { payload }) => {
            if (typeof payload === 'string') {
                state[payload] = !state[payload];
            }

            if (typeof payload === 'object') {
                const { key, override } = payload;

                if (typeof state[key] === 'undefined') return;

                state[key] = override !== undefined ? override : !state[key];
            }
        },
        setLastRefresh: (state, { payload }) => {
            state.lastRefresh = payload;
        },
        setAppointmentBookingData: (state, { payload }) => {
            if (Array.isArray(payload)) {
                payload.forEach((item) => {
                    state.appointmentBooking[item.key] = item.value;
                });
            } else {
                state.appointmentBooking[payload.key] = payload.value;
                if (payload.key === 'bookedAppointment') {
                    state.currentAppointment = getAppointmentObject(payload.value);
                }
            }
        },
        confirmCurrentAppointment: (state) => {
            if (state.currentAppointment) {
                state.currentAppointment.confirmed = true;
            }
        },
        setSupportFilters: (state, { payload }) => {
            state.supportFilters[payload.key] = payload.value;
        },
        setSimpleDynamicModalMessage: (state, { payload: message }) => {
            state.simpleDynamicModalMessage = message;
            state.modalsOpen = ['ghSimpleDynamicModal'];
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state, action) => {
                return initialState;
            })
            .addMatcher(api.endpoints.createSovasageConsult.matchFulfilled, (state) => {
                state.pendingMaskFitReview = true;
            })
            .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
                // it would be nice to consolidate this with the getMe fulfilled matcher
                state.lastRefresh = Date.now();
                if (!payload.user || hasRole(payload.user.userRoles, 'MD')) return;

                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_7_DAYS')
                    state.wore7days = true;
                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_15_DAYS')
                    state.wore15days = true;
                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_30_DAYS')
                    state.wore30days = true;
                if (
                    payload.user.patientProfile?.treatmentSupportStatus === 'TREATMENT_SUPPORT_ONLY'
                )
                    state.inTreatmentSupportOnly = true;

                const scheduledAppointments = payload.user.appointments
                    .filter((a) => a.appointmentStatus === 'SCHEDULED' && a.confirmed)
                    .sort((a1, a2) => new Date(a1.startAt) - new Date(a2.startAt));

                if (scheduledAppointments.length > 0) {
                    state.currentAppointment = getAppointmentObject(scheduledAppointments[0]);
                } else if (
                    //Provide welcome modal to users who have not uploaded sleep study or ordered HST
                    ['NOT_INITIATED', 'STUDY_IN_CART'].includes(
                        payload.user.patientProfile.homeSleepStudyStatus,
                    ) &&
                    payload.user.patientProfile.uploadedSleepStudyStatus === 'NOT_UPLOADED'
                ) {
                    state.modalsOpen.push('welcomeModal');
                }
            })
            .addMatcher(
                api.endpoints.createSovasageConsult.matchFulfilled,
                (state, { payload }) => {
                    state.pendingMaskFitReview = true;
                },
            )
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload }) => {
                if (hasRole(payload.userRoles, 'MD')) return;

                if (payload?.patientProfile?.treatmentSupportStatus === 'TREATMENT_SUPPORT_ONLY')
                    state.inTreatmentSupportOnly = true;

                if (payload?.appointments?.length > 0) {
                    //Setting current appointment in getMe
                    const scheduledAppointments = payload.appointments
                        .filter((a) => a.appointmentStatus === 'SCHEDULED' && a.confirmed)
                        .sort((a1, a2) => new Date(a1.startAt) - new Date(a2.startAt));

                    if (scheduledAppointments.length > 0) {
                        state.currentAppointment = getAppointmentObject(scheduledAppointments[0]);
                    } else {
                        state.currentAppointment = null;
                    }
                }
            })
            .addMatcher(
                api.endpoints.getRecommendedCPAPBundles.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        if (state.pendingMaskFitReview) {
                            state.modalsOpen = ['sovaSageCompleted'];
                        }
                        state.pendingMaskFitReview = false;
                    }
                },
            )
            .addMatcher(api.endpoints.refreshTokens.matchFulfilled, (state) => {
                state.lastRefresh = Date.now();
            })
            .addMatcher(api.endpoints.getUserHistory.matchFulfilled, (state, { payload }) => {
                let treatmentSupportStatuses = new Set();
                payload?.forEach((r) =>
                    r.changes?.treatmentSupportStatus?.forEach((status) =>
                        treatmentSupportStatuses.add(status),
                    ),
                );
                state.wore30days = treatmentSupportStatuses?.has('WORE_30_DAYS');
                state.wore15days = treatmentSupportStatuses?.has('WORE_15_DAYS');
                state.wore7days = treatmentSupportStatuses?.has('WORE_7_DAYS');
                if (state.inTreatmentSupportOnly === null)
                    state.inTreatmentSupportOnly =
                        treatmentSupportStatuses?.has('TREATMENT_SUPPORT_ONLY');
                if (
                    !localStorage.getItem('wore7days') &&
                    treatmentSupportStatuses?.has('WORE_7_DAYS')
                ) {
                    localStorage.setItem('wore7days', true);
                    state.modalsOpen.push('7days');
                }
                if (
                    !localStorage.getItem('wore15days') &&
                    treatmentSupportStatuses?.has('WORE_15_DAYS')
                ) {
                    localStorage.setItem('wore15days', true);
                    state.modalsOpen.push('15days');
                }
                if (
                    !localStorage.getItem('wore30days') &&
                    treatmentSupportStatuses?.has('WORE_30_DAYS')
                ) {
                    localStorage.setItem('wore30days', true);
                    state.modalsOpen.push('30days');
                }
            })
            .addMatcher(api.endpoints.getUserHistory.matchFulfilled, (state, { payload }) => {
                const reviewedSleepStudyStatusEvent = payload?.find(
                    (item) =>
                        item.action === 'update' &&
                        ((item.changes?.homeSleepStudyStatus &&
                            item.changes?.homeSleepStudyStatus[1] === 'STUDY_REVIEWED') ||
                            (item.changes?.mdStatus &&
                                item.changes?.mdStatus[1] === 'UPLOADED_SLEEP_STUDY_APPROVED')),
                );

                const sleepStudyReviewedEvent = payload?.find(
                    (item) =>
                        item.action === 'update' &&
                        ((item.changes?.homeSleepStudyStatus &&
                            item.changes?.homeSleepStudyStatus[1] === 'STUDY_REVIEWED') ||
                            (item.changes?.mdStatus &&
                                item.changes?.mdStatus[1] === 'ITAMAR_HST_REVIEW_COMPLETE')),
                );

                const treatmentSelectedEvent = payload?.find(
                    (item) =>
                        item.action === 'update' &&
                        ((item.changes?.cpapOrderStatus &&
                            item.changes?.cpapOrderStatus[1] === 'SUPPLIES_ORDERED') ||
                            (item.changes?.dentalReferralStatus &&
                                item.changes?.dentalReferralStatus[1] ===
                                    'INITIAL_CONSULT_COMPLETE')),
                );

                state.showFeedbackBox =
                    reviewedSleepStudyStatusEvent ||
                    sleepStudyReviewedEvent ||
                    treatmentSelectedEvent;
            })
            .addMatcher(api.endpoints.cancelAppointment.matchFulfilled, (state) => {
                state.appointmentBooking = {};
                state.currentAppointment = null;
                state.appointmentWasCancelled = true;
            })
            .addMatcher(api.endpoints.scheduleAppointment.matchFulfilled, (state, { payload }) => {
                state.currentAppointment = getAppointmentObject(payload);
            })
            .addMatcher(api.endpoints.getPatientConsents.matchFulfilled, (state, { payload }) => {
                state.agreedToInsuranceBenefits = payload?.results?.find(
                    (c) => c.documentType === 'assignment-of-benefit-responsibility',
                );
            });
    },
});

export const {
    setReschedulingFlow,
    toggleModal,
    toggle,
    toggleCart,
    toggleNav,
    toggleAccount,
    setLastRefresh,
    setAppointmentBookingData,
    setSupportFilters,
    setSimpleDynamicModalMessage,
    confirmCurrentAppointment,
} = uiSlice.actions;

export default uiSlice.reducer;
