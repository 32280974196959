import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAnswer, nextQuestion } from '../../../features/assessment/assessmentSlice';
import { motion, useAnimationControls } from 'framer-motion';

const AnswerGroup = ({ overrides }) => {
    const [noDisabled, setNoDisabled] = useState(false);
    const [yesDisabled, setYesDisabled] = useState(false);
    const noControls = useAnimationControls();
    const yesControls = useAnimationControls();
    const dispatch = useDispatch();

    const videoEndHandler = (answer) => {
        dispatch(setAnswer(answer === 'yes'));
        dispatch(nextQuestion());
    };

    const playAnswer = (answer) => {
        if (answer === 'no') {
            noControls
                .start({
                    x: ['100%', '0%', '-100%'],
                    transition: { ease: 'easeInOut', duration: 0.85 },
                })
                .then(() => {
                    setYesDisabled(true);
                    videoEndHandler(answer);
                });
        }

        if (answer === 'yes') {
            yesControls
                .start({
                    x: ['-100%', '0%', '100%'],
                    transition: { ease: 'easeInOut', duration: 0.85 },
                })
                .then(() => {
                    setNoDisabled(true);
                    videoEndHandler(answer);
                });
        }
    };

    return (
        <div className="mx-auto max-w-xl">
            <button
                className="group relative w-1/2 overflow-hidden border border-purple-medium text-xl font-semibold transition duration-300 ease-out-quad hover:z-10 hover:bg-purple-medium/20 active:bg-purple-medium/20 disabled:opacity-25 lg:text-5xl"
                onClick={() => {
                    playAnswer('no');
                }}
                disabled={noDisabled}>
                <div className="pb-[100%]"></div>
                <motion.div
                    className="pointer-events-none absolute top-0 left-0 h-full w-full translate-x-full bg-salmon"
                    animate={noControls}></motion.div>
                <span
                    style={{ opacity: yesDisabled ? 0 : 1 }}
                    className="absolute-center transition duration-500 ease-out-quad group-hover:scale-125">
                    {overrides ? overrides[0] : 'No'}
                </span>
            </button>
            <button
                className="group relative w-1/2 -translate-x-[1px] overflow-hidden border border-purple-medium text-xl font-semibold transition duration-300 ease-out-quad hover:z-10 hover:bg-purple-medium/20 active:bg-purple-medium/20 disabled:opacity-25 lg:text-5xl"
                onClick={() => {
                    playAnswer('yes');
                }}
                disabled={yesDisabled}>
                <div className="pb-[100%]"></div>
                <motion.div
                    className="pointer-events-none absolute top-0 left-0 h-full w-full -translate-x-full bg-green-400"
                    animate={yesControls}></motion.div>
                <span
                    style={{ opacity: noDisabled ? 0 : 1 }}
                    className="absolute-center transition duration-500 ease-out-quad group-hover:scale-125">
                    {overrides ? overrides[1] : 'Yes'}
                </span>
            </button>
        </div>
    );
};

export default AnswerGroup;
