import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
    useGetAppointmentTypesQuery,
    useGetAvailablePractitionersQuery,
} from '../../../app/services/appointments';
import { setAppointmentBookingData } from '../../../features/ui/uiSlice';

import Pickers from './Pickers';

const AppointmentBooking = ({ appointmentType, onAppointmentBooked, usingInsurance }) => {
    const dispatch = useDispatch();

    //Redux state values
    const { appointmentBooking, isReschedulingAppointment } = useSelector((state) => state.ui);

    //Use insurance consideration when booking and rebooking
    const bookWithInsurance =
        usingInsurance ||
        appointmentBooking?.appointmentToReschedule?.order?.orderType === 'INSURANCE';

    //Queries
    const { data: practitionersIds, isLoading } = useGetAvailablePractitionersQuery({
        usingInsurance: bookWithInsurance ? true : false,
    });
    const { data: appointmentTypes, isLoading: appointmentTypesLoading } =
        useGetAppointmentTypesQuery();

    //Set appointmentType when rescheduling, or pass Initial Consultation in case of no appointmentType set
    if (isReschedulingAppointment) {
        //Conditional checks to not break once original appointment is cancelled
        appointmentType = appointmentBooking?.appointmentToReschedule?.appointmentType;
    } else if (!appointmentType) {
        appointmentType = 'Initial Consultation';
    }

    const selectedProvider = practitionersIds ? practitionersIds[0] : null;

    useEffect(() => {
        //Return until appointmentTypes is finished fetching, or when appointmentType is set to null when rescheduling
        if (!appointmentTypes || !appointmentType) return;

        //Find the appointmentType matching what the user is booking in the healthie appointment types
        let selectedAppointmentType = appointmentTypes.find((a) => a.name === appointmentType);

        if (!selectedAppointmentType) {
            toast.error('Appointment Type not found');
            return;
        }

        dispatch(
            setAppointmentBookingData([
                { key: 'selectedAppointmentType', value: selectedAppointmentType },
                {
                    key: 'selectedContactType',
                    value: selectedAppointmentType.available_contact_types[0],
                },
            ]),
        );
    }, [appointmentTypes, appointmentType, dispatch]);

    if (isLoading || appointmentTypesLoading || !appointmentBooking.selectedAppointmentType) {
        return <p className="text-center py-6 font-heading text-5xl">Loading...</p>;
    } else if (practitionersIds && practitionersIds.length === 0) {
        return (
            <div className="layout-outlet-root">
                <h4 className="font-heading text-2xl">
                    Sorry, it appears there are no available practitioners in your state.
                </h4>
                <p className="mt-4 text-lg">
                    Please call us at{' '}
                    <a href="tel:+18339464436" className="font-bold">
                        1-833-946-4436
                    </a>{' '}
                    for assistance.
                </p>
            </div>
        );
    } else {
        return (
            <Pickers
                provider_id={selectedProvider}
                //If rescheduling, restrict the array to only the previously booked provider
                provider_ids={isReschedulingAppointment ? [selectedProvider] : practitionersIds}
                selectedAppointmentType={appointmentBooking?.selectedAppointmentType}
                onAppointmentBooked={onAppointmentBooked}
            />
        );
    }
};

export default AppointmentBooking;
