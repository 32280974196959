import { api } from '../auth';

const adminInsuranceAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        addCredentialPayer: builder.mutation({
            query: ({ userId, payerId }) => ({
                url: `/payers/${payerId}/credentials/${userId}`,
                method: 'POST',
            }),
            invalidatesTags: ['User', 'Payers'],
        }),
        addPayer: builder.mutation({
            query: (data) => ({
                url: '/payers',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Payers'],
        }),
        deletePayer: builder.mutation({
            query: (payerId) => ({
                url: `/payers/${payerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Payers'],
        }),
        addOrUpdatePriorAuthRequirement: builder.mutation({
            query: ({ payerId, priorAuthRequirements }) => ({
                url: `/payers/${payerId}/priorauthreq`,
                method: 'POST',
                body: {
                    priorAuthRequirements,
                },
            }),
            invalidatesTags: ['Payers'],
        }),
        deletePriorAuthRequirement: builder.mutation({
            query: ({ id, payerId }) => ({
                url: `/payers/${payerId}/priorauthreq/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Payers'],
        }),
        getDmeReferralDestinations: builder.query({
            query: () => ({
                url: '/payers/dme-referrals',
                method: 'GET',
            }),
        }),
        getInsurances: builder.query({
            query: (userId) => ({
                url: `/users/${userId}/insurance?inactive=true`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'Insurance', id })),
                          { type: 'Insurance', id: 'LIST' },
                      ]
                    : [{ type: 'Insurance', id: 'LIST' }],
        }),
        getNikoPayers: builder.query({
            query: () => '/niko/payers',
        }),
        removeCredentialPayer: builder.mutation({
            query: ({ userId, payerId }) => ({
                url: `/payers/${payerId}/credentials/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User', 'Payers'],
        }),
        updatePayer: builder.mutation({
            query: ({ payerId, data }) => ({
                url: `/payers/${payerId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Payers'],
        }),
        updateStateService: builder.mutation({
            query: (stateService) => ({
                url: `/admin/service/${stateService.state}`,
                method: 'PUT',
                body: stateService,
            }),
        }),
        getItamarInterpretationPools: builder.query({
            query: () => ({
                url: `/payers/itamar-pools`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useAddCredentialPayerMutation,
    useAddPayerMutation,
    useAddOrUpdatePriorAuthRequirementMutation,
    useDeletePayerMutation,
    useDeletePriorAuthRequirementMutation,
    useGetNikoPayersQuery,
    useGetDmeReferralDestinationsQuery,
    useGetInsurancesQuery,
    useRemoveCredentialPayerMutation,
    useUpdatePayerMutation,
    useUpdateStateServiceMutation,
    useGetItamarInterpretationPoolsQuery,
} = adminInsuranceAPI;
