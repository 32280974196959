import { ReactComponent as CartIcon } from '../../images/icons/cart.svg';
import { setSelectedProduct, setSelectedVariation } from '../../features/catalog/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useInsuranceContext } from '../Insurance';
import { Modal } from '..';
import { useNavigate } from 'react-router';
import { toggleInsurance, toggleOnRecommended } from '../../features/cart/cartSlice';
import CpapCoveragePaymentChoice from '../Insurance/CpapCoveragePaymentChoice';

const RecommendedCPAPs = ({ cpaps, user, patientReferredToDME }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //local state
    const [ready, setReady] = useState(false);
    const [referralModalOpen, setReferralModalOpen] = useState(false);
    const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
    const [DMEModalOpen, setDMEModalOpen] = useState(false);

    //redux state values
    const { selectedVariation } = useSelector((state) => state.catalog);
    const { orderItems, usingInsurance } = useSelector((state) => state.cart);

    //context
    const { insurance, insuranceFetching } = useInsuranceContext();

    useEffect(() => {
        if (!insuranceFetching && user) {
            setReady(true);
        }
    }, [insuranceFetching, user]);

    //Determine if the user is referred to DME, if so, open the warning modal
    function handleClick(cpap) {
        dispatch(setSelectedProduct(cpap.catalogItem.id));
        dispatch(setSelectedVariation(cpap.recommendedVariation));

        //If they are referred or needs referral and haven't added a new insurance
        if (
            ['REFERRED', 'NEEDS_REFERRAL'].includes(user?.patientProfile?.cpapOrderStatus) &&
            insurance?.payer?.dmeReferralId !== null
        ) {
            setReferralModalOpen(true);
        }
        //If they have a DME payer but not yet decided on referral/ self pay
        else if (
            insurance &&
            insurance?.payer?.dmeReferralId !== null &&
            usingInsurance !== false
        ) {
            setDMEModalOpen(true);
        }
        //If they have insurance and they have logged in again since last seeing the modal
        else if (insurance && !insurance.payer?.dmeReferralId && usingInsurance === undefined) {
            setInsuranceModalOpen(true);
        } else {
            navigate(`bundle/${cpap.recommendedVariation.id}`);
        }
    }

    const handleInsurancePay = () => {
        dispatch(toggleInsurance(true));
        setInsuranceModalOpen(false);
        navigate(`bundle/${selectedVariation.id}`);
    };

    const handleSelfPay = () => {
        //set self pay
        dispatch(toggleInsurance(false));
        setInsuranceModalOpen(false);
        navigate(`bundle/${selectedVariation.id}`);
    };

    const handleContinue = () => {
        navigate(`bundle/${selectedVariation.id}`);
    };

    return (
        ready && (
            <>
                <div className="flex flex-col space-y-4 my-5 py-2">
                    {cpaps.map((cpap, index) => {
                        const imgPath = cpap.catalogItem.images?.length
                            ? cpap.catalogItem.images[0]
                            : '';
                        const isReactCPAP = cpap.catalogItem.itemData.name
                            .toLowerCase()
                            .includes('react');
                        return (
                            <div
                                className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4"
                                key={`cpap-preview-${index}`}>
                                <div className="relative z-1 sm:w-[240px]">
                                    <span className="absolute -top-4 -right-4 z-9 p-4 text-white rounded-sm bg-[#7168d2] shadow-[0_0px_0px_5px_rgba(0,0,0,0.0.5) font-bold">
                                        {isReactCPAP ? 'Best Value' : 'Most Popular'}
                                    </span>
                                    <img
                                        className="w-9/10 m-0-auto h-auto sm:max-w-[240px]"
                                        alt="cpap-bundle"
                                        src={imgPath}
                                    />
                                </div>
                                <div className="w-full sm:w-3/4 max-w-xl">
                                    <h4 className="mb-3 font-heading text-4xl lg:text-5xl">
                                        {cpap.catalogItem.itemData.name}
                                    </h4>
                                    <p className="mb-4 text-[13px] lg:text-base">
                                        {patientReferredToDME
                                            ? `We’ve submitted a referral for CPAP to ${insurance?.payer.dmeReferral.name}. A representative will connect with you on next steps. If you change your mind and want to order and pay on your own. Click “order now” and we will get things started.`
                                            : cpap.catalogItem.itemData.description}
                                    </p>
                                    <button
                                        onClick={() => {
                                            handleClick(cpap);
                                        }}
                                        className="btn-primary w-full flex justify-between max-w-xl">
                                        <span className="pt-0.5 sm:pt-0">
                                            {cpap.catalogItem.itemData.variations?.some(
                                                (variation) =>
                                                    orderItems?.some(
                                                        (item) => item.variantId === variation.id,
                                                    ),
                                            )
                                                ? 'In Cart'
                                                : 'Order Now'}
                                        </span>
                                        <CartIcon />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    <Modal
                        showClose={true}
                        isOpen={referralModalOpen}
                        closeHandler={() => {
                            setReferralModalOpen(false);
                        }}>
                        <div className="space-y-5 text-center">
                            <h2 className="font-heading text-5xl lg:text-7xl">Reminder!</h2>
                            <p className="text-sm lg:text-lg">
                                You have chosen to be referred to{' '}
                                {insurance?.payer.dmeReferral?.name}. You may still order with us
                                but you will have to self-pay.
                            </p>
                            <button
                                className="btn-primary w-full"
                                onClick={() => {
                                    dispatch(toggleInsurance(false));
                                    navigate(`bundle/${selectedVariation.id}`);
                                }}>
                                Got it
                            </button>
                        </div>
                    </Modal>
                    <Modal
                        showClose={true}
                        isOpen={DMEModalOpen}
                        closeHandler={() => {
                            setDMEModalOpen(false);
                        }}>
                        <>
                            <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                                Your Insurance Coverage Status
                            </h5>
                            <p className="mb-4">
                                It looks like you entered {insurance?.payer.name} insurance. For
                                CPAP, you have two coverage options.
                            </p>
                            <ul>
                                <li className="mb-4">
                                    1. To purchase your CPAP using your {insurance?.payer.name}{' '}
                                    insurance, GEM SLEEP can send a referral to{' '}
                                    {insurance?.payer.name}
                                    's CPAP provider.
                                </li>
                                <li className="mb-4">
                                    2. You can place your order directly through GEM SLEEP without
                                    insurance.
                                </li>
                            </ul>
                        </>
                        <button
                            className="btn-primary w-full"
                            onClick={() => {
                                dispatch(toggleOnRecommended(true));
                                navigate('/dashboard/treatment/comparison');
                            }}>
                            <span>Continue</span>
                        </button>
                    </Modal>
                </div>
                <CpapCoveragePaymentChoice
                    modalOpen={insuranceModalOpen}
                    setModalOpen={setInsuranceModalOpen}
                    handleInsuranceClick={handleInsurancePay}
                    handleSelfPayClick={handleSelfPay}
                    handleContinueClick={handleContinue}
                    startOfCPAPFlow={false}
                />
            </>
        )
    );
};

export default RecommendedCPAPs;
