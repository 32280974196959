import { Form, Formik, useField } from 'formik';
import { FiArrowUpRight } from 'react-icons/fi';
import { bigDateSchema } from '../../utils/schemas';

const DateShardField = ({ className, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <div className={`relative ${className}`}>
            <input
                className="w-full border-b border-b-purple-medium bg-transparent text-3xl outline-none placeholder:text-offwhite/30 lg:text-6xl"
                inputMode="numeric"
                {...field}
                {...props}
            />
            {meta.touched && meta.error && (
                <p className="absolute -bottom-3 mt-4 translate-y-full text-xs font-bold text-red">
                    {meta.error}
                </p>
            )}
        </div>
    );
};

const BigDateInput = ({ submitHandler, defaultValue }) => {
    const date = defaultValue !== '' ? new Date(defaultValue) : '';

    const formSubmitHandler = (data) => {
        const date = new Date(
            Date.UTC(parseInt(data.yyyy), parseInt(data.mm) - 1, parseInt(data.dd)),
        );
        submitHandler(date.toISOString());
    };

    return (
        <Formik
            initialValues={{
                dd: typeof date === 'object' ? date.getDay() : '',
                mm: typeof date === 'object' ? date.getMonth() + 1 : '',
                yyyy: typeof date === 'object' ? date.getFullYear() : '',
            }}
            validationSchema={bigDateSchema}
            onSubmit={formSubmitHandler}>
            {({ dirty, isValid }) => (
                <Form className="relative mx-auto mb-10 flex max-w-4xl items-end justify-center space-x-5 pb-1 lg:pb-2">
                    <DateShardField
                        name="mm"
                        type="text"
                        placeholder="MM"
                        maxLength="2"
                        className="w-full max-w-[25%] lg:max-w-none"
                        autoFocus
                    />
                    <DateShardField
                        name="dd"
                        type="text"
                        placeholder="DD"
                        maxLength="2"
                        className="w-full max-w-[25%] lg:max-w-none"
                    />
                    <DateShardField
                        name="yyyy"
                        type="text"
                        placeholder="YYYY"
                        maxLength="4"
                        className="w-full max-w-[25%] lg:max-w-none"
                    />
                    <button
                        type="submit"
                        disabled={(!dirty && defaultValue === '') || !isValid}
                        className="absolute right-0 bottom-2 h-8 w-8 translate-x-3 rounded-full bg-green-400 text-lg disabled:opacity-50 lg:top-1/2 lg:h-12 lg:w-12 lg:-translate-y-1/2 lg:translate-x-0">
                        <FiArrowUpRight className="absolute-center" />
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default BigDateInput;
