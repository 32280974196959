import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { nextQuestion } from '../../../features/assessment/assessmentSlice';
import sleepingMan from '../../../images/sleeping-man.jpg';

const NeedsRetest = ({ sleepStudyInvalidated }) => {
    const dispatch = useDispatch();
    const { howBroughtToGem } = useSelector((state) => state.assessment.answers);
    const isCoachingPath = howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    return (
        <motion.div
            key="screen-0"
            initial={{ opacity: 0, translateX: '20%' }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: '-20%' }}
            transition={{ ease: 'linear' }}>
            <div className="mx-auto max-w-5xl">
                <div className="mb-8 lg:mb-14">
                    <h2 className="mb-4 font-heading text-5xl font-bold text-white lg:mb-5 lg:text-7xl">
                        It's Time to Re-Test Your Sleep.
                    </h2>
                    <p className="text-2xl text-gray-light">
                        Let's get you set up with a new home sleep test.
                    </p>
                </div>
                <div className="mt-4 w-full lg:flex lg:gap-10">
                    <div className="w-full text-left lg:w-3/5">
                        {isCoachingPath ? (
                            <p className="text-lg text-gray-light">
                                In order to engage in the GEM SLEEP CPAP coaching program, you must
                                have a sleep study that shows a diagnosis of Sleep Apnea. Good news
                                is you can get a new sleep study through GEM SLEEP.{' '}
                            </p>
                        ) : sleepStudyInvalidated ? (
                            <>
                                <p className="text-lg text-gray-light">
                                    Based on your responses, your previous sleep study may no longer
                                    provide an accurate view of your sleep needs. You will need to
                                    complete a new sleep study so we can provide the best treatment
                                    options for you.
                                </p>
                                <p className="my-5 text-lg text-gray-light">
                                    With GEM's home sleep test:
                                </p>
                                <ul className="mx-5 my-5 list-disc text-lg text-gray-light">
                                    <li>
                                        you <b>never have to go into a clinic</b>
                                    </li>
                                    <li>
                                        we will <b>send you a sleep testing kit in days</b>
                                    </li>
                                    <li>
                                        Your results will be <b>reviewed by a GEM clinician</b>. You
                                        will receive a full report and prescription (if necessary).
                                    </li>
                                </ul>
                            </>
                        ) : (
                            <p className="text-lg text-gray-light">
                                To proceed with using GEM SLEEP for treatment we need a valid sleep
                                study. We use an at-home sleep test that collects data on a night of
                                your sleep at home. Your results will be reviewed by one of our
                                clinicians. You will receive a full report, prescription and
                                treatment recommendation.
                            </p>
                        )}
                        <button
                            className="btn-primary mt-8 w-full px-4 text-lg"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'User',
                                    action: 'Needs Retest',
                                    label: `Continue_${
                                        sleepStudyInvalidated
                                            ? 'previous_study_invalid'
                                            : 'no_previous_study_to_upload'
                                    }`,
                                });
                                dispatch(nextQuestion());
                            }}>
                            Continue
                        </button>
                    </div>
                    <div className="relative my-8 w-full lg:mb-0 lg:w-2/5">
                        <div className="relative w-full">
                            <img src={sleepingMan} alt="sleeping man" />
                            <div
                                className="absolute bottom-0 left-0 right-0"
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    backdropFilter: 'blur(5px)',
                                }}>
                                <p className="pl-2 py-1 text-base font-bold lg:text-lg">
                                    GEM Home Sleep Study
                                </p>
                                <p className="py-1 pl-2 font-heading text-lg  font-bold lg:text-2xl">
                                    MANY MAJOR INSURANCES ACCEPTED
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default NeedsRetest;
