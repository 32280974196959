import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetStopBangResponsesQuery } from '../../app/services/auth';
import { BiCheckCircle } from 'react-icons/bi';
import { useGetHomeStatus } from '../../utils/useGetHomeStatus';

export const VariableUpNext = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const { currentAppointment } = useSelector((state) => state.ui);
    const variableContent = useGetHomeStatus();
    const { isLoading } = useGetStopBangResponsesQuery(user?.patientProfile?.stopBangResultId, {
        skip: !user.patientProfile.stopBangResultId || user.stopBangResponses,
    });

    return isLoading ? (
        <div className="bg-white p-7 lg:p-8 drop-shadow-2xl">Loading...</div>
    ) : (
        <div className="bg-white p-7 lg:p-8 drop-shadow-2xl">
            {currentAppointment?.confirmed ? (
                <>
                    <h3 className="mb-3 font-heading text-5xl">
                        🗓
                        <br />
                        Don't forget! You have an upcoming appointment
                    </h3>
                    <button
                        className="w-full rounded-full bg-gem-purple p-3 font-bold text-white"
                        onClick={() => {
                            navigate('appointments/my-appointment');
                        }}>
                        {currentAppointment.start}
                    </button>
                </>
            ) : (
                <>
                    {variableContent.icon && (
                        <div style={{ fontSize: '2rem' }}>{variableContent.icon}</div>
                    )}
                    <h3 className="mb-3 font-heading text-5xl">{variableContent.title}</h3>
                    <p className="mb-6 lg:mb-12 text-sm font-medium">{variableContent.content}</p>
                    {variableContent.actionText ? (
                        variableContent?.actionDestination?.indexOf('http') === 0 ? (
                            <a
                                href={variableContent.actionDestination}
                                className="btn-primary flex w-full items-center justify-center text-center"
                                target="_blank"
                                rel="noreferrer">
                                <span className="mr-3 leading-none">
                                    {variableContent.actionText}
                                </span>
                                <BiCheckCircle className="scale-150" />
                            </a>
                        ) : (
                            <button
                                className="btn-primary w-full"
                                onClick={(ev) => {
                                    if (variableContent?.actionDestination !== '#') {
                                        variableContent?.actionStateObject
                                            ? navigate(variableContent.actionDestination, {
                                                  state: variableContent.actionStateObject,
                                              })
                                            : navigate(variableContent.actionDestination);
                                    }
                                }}>
                                {variableContent.actionText}
                            </button>
                        )
                    ) : null}
                </>
            )}
        </div>
    );
};
