import { useLocation } from 'react-router-dom';
import { AppointmentBooking } from '../../components/Appointments';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppointmentBookingData, setReschedulingFlow } from '../../features/ui/uiSlice';

const RescheduleAppointment = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    // Toggle rescheduling flow for other components
    useEffect(() => {
        if (location.state?.from === 'appointmentRescheduling') {
            dispatch(setReschedulingFlow(true));
            dispatch(
                setAppointmentBookingData({
                    key: 'appointmentToReschedule',
                    value: location.state.appointmentToReschedule,
                }),
            );
        }
    }, [location, dispatch]);

    return (
        <div>
            <h3 className="mb-2 font-heading text-[44px] leading-none lg:mb-3 lg:text-7xl">
                Reschedule your Appointment
            </h3>
            <AppointmentBooking
                usingInsurance={false}
                appointmentType={location.state?.appointmentType}
            />
        </div>
    );
};

export default RescheduleAppointment;
