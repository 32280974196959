import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const OrderConfirmation = ({ withRequiredConsult, itemOrdered }) => {
    const { usingInsurance } = useSelector((state) => state.cart);

    //HST/CPAP check must come before Consult so as not to display consult order confirmation on HST/CPAP orders
    //HST orders with/without Consult
    if (itemOrdered === 'hst') {
        if (withRequiredConsult) {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="mx-auto max-w-2xl pt-16 text-center">
                    <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                        YOU'RE ALL SET!
                    </h2>
                    <p className="mb-6 leading-normal lg:text-lg">
                        We've got your home sleep study order. All you've got to do now is arrive at
                        your scheduled visit time (details provided via email).
                    </p>
                    <p className="mb-6 leading-normal lg:text-lg">
                        During your virtual visit your provider will review your clinical history.
                        We will ship your home sleep test after this visit. If for some reason your
                        provider determines a home sleep test is not right for you, your order will
                        be refunded.
                    </p>
                    <Link to="/dashboard" className="btn-primary mb-6">
                        Return to home
                    </Link>
                </motion.div>
            );
        } else {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="mx-auto max-w-2xl pt-16 text-center">
                    <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                        Thanks! Your Order is Being Processed
                    </h2>
                    <p className="mb-6 leading-normal lg:text-lg">
                        Your order will ship in the next 2-3 business days. We will send you
                        additional shipping information via email.
                    </p>
                    <p className="mb-6 leading-normal lg:text-lg">
                        In the meantime, start prepping for your test, review our Home Sleep Test
                        How to Guide.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.mygemsleep.com/hst-how-to-guide"
                        className="btn-primary mb-6">
                        Take me there!
                    </a>
                    <p className="text-sm">
                        All orders are reviewed by a GEM SLEEP provider prior to shipment. If we
                        have any questions we will contact you directly.
                    </p>
                </motion.div>
            );
        }
        //CPAP orders with/without Consult
    } else if (itemOrdered === 'cpap') {
        if (withRequiredConsult) {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="mx-auto max-w-2xl pt-16 text-center">
                    <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                        Thanks! Your Order is Being Processed
                    </h2>
                    <p className="mb-6 leading-normal lg:text-lg">
                        Your items will ship after your virtual visit.
                    </p>
                    <p className="mb-6 leading-normal lg:text-lg">
                        During your virtual visit your provider will review your clinical history
                        and fit for the best treatment. We will ship your items after this visit.
                    </p>
                    <Link to="/dashboard" className="btn-primary mb-6">
                        Return to home
                    </Link>
                </motion.div>
            );
        } else {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="mx-auto max-w-2xl pt-16 text-center">
                    <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                        Thanks! Your Order is Being Processed
                    </h2>
                    <p className="mb-6 leading-normal lg:text-lg">
                        We've received your order and are working on it now!
                    </p>

                    {usingInsurance ? (
                        <p className="mb-6 leading-normal lg:text-lg">
                            We will contact your insurance company to get an estimate for your CPAP
                            therapy. We will send you an estimate via email, your CPAP WILL NOT ship
                            until we receive approval.
                        </p>
                    ) : (
                        <p className="mb-6 leading-normal lg:text-lg">
                            We'll send your shipping and tracking details via email.
                        </p>
                    )}
                    <Link to="/dashboard" className="btn-primary mb-6">
                        Return to home
                    </Link>
                </motion.div>
            );
        }
        //Consult only orders
    } else if (itemOrdered === 'consult') {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="mx-auto max-w-2xl pt-16 text-center">
                <h2 className="font-heading text-[44px] leading-[0.95] lg:text-7xl">
                    Great! Your appointment with GEM SLEEP has been confirmed.
                </h2>
                <p className="mb-6 leading-normal lg:text-lg">
                    You will receive a confirmation email.
                </p>
                <p className="mb-6 leading-normal lg:text-lg">
                    When it is time to join your appointment, you can launch it from the GEM portal
                    or from within your email.
                </p>
                <p className="mb-6 leading-normal lg:text-lg">
                    If you need to reschedule/cancel your appointment, please do so 24 hours in
                    advance.
                </p>
                <Link to="/dashboard" className="btn-primary mb-6">
                    Return to home
                </Link>
            </motion.div>
        );
        //Default order confirmation screen
    } else {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="mx-auto max-w-2xl pt-16 text-center">
                <h2 className="mb-4 font-heading text-[44px] leading-[0.95] lg:text-7xl">
                    Thanks! Your Order is Being Processed
                </h2>
                <p className="mb-6 leading-normal lg:text-lg">
                    We've received your order and are working on it now!
                </p>
                <p className="mb-6 leading-normal lg:text-lg">
                    We'll send your shipping and tracking details via email.
                </p>

                <p className="mb-6 font-heading leading-[0.95] text-3xl lg:text-5xl">WHAT'S NEXT</p>
                <p className="mb-6 leading-normal lg:text-lg">
                    Used Insurance? We'll verify your benefits, then give you a call to review your
                    coverage and any amounts you may owe.
                </p>
                <Link to="/dashboard" className="btn-primary mb-6">
                    Return to home
                </Link>
            </motion.div>
        );
    }
};

export default OrderConfirmation;
