import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    AppointmentBooking,
    AppointmentBoxListItems,
    ConsultationConfirmation,
} from '../Appointments';

import { appointmentSkus, getAppointmentObject } from '../../utils/constants';
import { setAppointmentBookingData, setReschedulingFlow } from '../../features/ui/uiSlice';

const ConsultBooking = ({ onBookingComplete }) => {
    const dispatch = useDispatch();
    const [screen, setScreen] = useState('interstitial'); //'interstitial', 'booking', 'confirmation'
    const { appointment, orderItems, usingInsurance } = useSelector((state) => state.cart);

    //Find which orderItem is a consult, then check the Map for the appropriate Healthie name
    const appointmentInCart = orderItems.find((item) => appointmentSkus.has(item.sku));
    const appointmentTypeInCart = appointmentSkus.get(appointmentInCart.sku);

    if (appointment && screen === 'interstitial') {
        return (
            <div>
                <h5 className="font-heading text-4xl font-bold mb-2 lg:text-5xl ">
                    Your appointment
                </h5>
                <p className="mb-5 text-sm font-bold text-gem-purple">Session Details</p>
                <ul className="mb-8 space-y-4 text-lg">
                    <AppointmentBoxListItems appointment={getAppointmentObject(appointment)} />
                </ul>
                <nav className="space-between flex items-center space-x-2">
                    <button
                        className="btn-primary flex-1 h-[66px]"
                        onClick={() => {
                            onBookingComplete();
                        }}>
                        Use this appointment
                    </button>
                    <button
                        className="btn-shell flex-1 h-[66px]"
                        onClick={() => {
                            dispatch(
                                setAppointmentBookingData({
                                    key: 'appointmentToReschedule',
                                    value: appointment,
                                }),
                            );
                            setScreen('booking');
                            dispatch(setReschedulingFlow(true));
                        }}>
                        Schedule a new appointment
                    </button>
                </nav>
            </div>
        );
    } else {
        return (
            <>
                {screen === 'interstitial' && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <div className="space-y-5">
                            <h5 className="font-heading text-4xl font-bold lg:text-5xl">
                                Book Your Virtual Visit.
                            </h5>
                            <p className="text-sm lg:text-base">
                                Let's book a date and time for your virtual visit.
                            </p>
                            <button
                                className="btn-primary w-full"
                                onClick={() => {
                                    setScreen('booking');
                                    dispatch(setReschedulingFlow(false));
                                }}>
                                SCHEDULE VIRTUAL VISIT
                            </button>
                        </div>
                    </motion.div>
                )}
                {screen === 'booking' && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <AppointmentBooking
                            usingInsurance={usingInsurance}
                            onAppointmentBooked={() => setScreen('confirmation')}
                            appointmentType={appointmentTypeInCart ? appointmentTypeInCart : null}
                        />
                    </motion.div>
                )}
                {screen === 'confirmation' && (
                    <ConsultationConfirmation onBookingComplete={onBookingComplete} />
                )}
            </>
        );
    }
};

export default ConsultBooking;
