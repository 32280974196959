import { api } from './auth';

export const ordersAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        createSquarePayment: builder.mutation({
            query: (body) => ({
                url: '/purchases/payment',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Cart', 'PatientOrderHistory'],
        }),
        createSovasageConsult: builder.mutation({
            query: () => ({
                url: '/sova-sage',
                method: 'POST',
            }),
        }),
        getCurrentSupplies: builder.query({
            query: (userId) => '/purchases/current-supplies',
        }),
        getOrderHistory: builder.query({
            query: (userId) => `/purchases/orders?userId=${userId}`,
            providesTags: ['PatientOrderHistory'],
        }),
        getRecommendedCPAPBundles: builder.query({
            query: () => ({
                url: `/shop/products/cpap/recommended`,
                method: 'GET',
            }),
            providesTags: ['RecommendedCPAPBundles'],
        }),
        getSquareProducts: builder.query({
            query: () => '/purchases/catalog',
            providesTags: ['SquareCatalog'],
        }),
        getUserRecommendedCPAPBundles: builder.query({
            query: (userId) => ({
                url: `/shop/admin/products/cpap/recommended/${userId}`,
                method: 'GET',
            }),
            providesTags: ['RecommendedCPAPBundles'],
        }),
        enrollInResupply: builder.mutation({
            query: ({ id }) => ({
                url: `/users/${id}/resupply`,
                method: 'POST',
            }),
            providesTags: ['ResupplyStatus'],
            invalidatesTags: (result, error, arg) => ['User', 'ResupplyList'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateSovasageConsultMutation,
    useCreateSquarePaymentMutation,
    useGetCurrentSuppliesQuery,
    useGetOrderHistoryQuery,
    useGetRecommendedCPAPBundlesQuery,
    useGetSquareProductsQuery,
    useGetUserRecommendedCPAPBundlesQuery,
    useEnrollInResupplyMutation,
} = ordersAPI;
