import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { CustomCheckbox, CustomReactSelect, CustomInput } from '../InputComponents';
import { checkoutAddressSchema } from '../../utils/schemas';
import { states } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useUpdateMeMutation } from '../../app/services/auth';

function Address({
    currentlyResupply,
    proceedToPayment,
    setCheckoutStep,
    setShowAddressForm,
    showAddressForm,
    user,
}) {
    const [updateMe, { error: userUpdateError }] = useUpdateMeMutation();

    async function addressSubmitHandler(data) {
        await updateMe(data);
        // this will prevent the user from moving to the next screen if there's an error updating their address
        if (userUpdateError) return;

        //If they are ordering a CPAP or supplies and are not currently enrolled, send to resupply, otherwise proceed to payment
        (cpapInCart || suppliesInCart) && !currentlyResupply
            ? setCheckoutStep('resupply-signup')
            : proceedToPayment();
    }

    const { cpapInCart, isConsultOrder, suppliesInCart, usingInsurance } = useSelector(
        (state) => state.cart,
    );

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h2 className="font-heading text-[44px] leading-none lg:mb-3 lg:text-7xl">
                {isConsultOrder ? 'Enter your billing address' : 'Where should we send it?'}
            </h2>
            <p className="mb-10 text-[13px] lg:mb-14 lg:text-base">
                Enter your {isConsultOrder ? 'billing ' : 'shipping '}
                address below:
            </p>
            {userUpdateError?.data && (
                <p className="font-bold text-red">{JSON.stringify(userUpdateError.data)}</p>
            )}
            {!showAddressForm ? (
                <div>
                    <div className="border bg-white p-7">
                        <p className="lg:text-lg">{`${user.street1}, ${user.street2}`}</p>
                        <p className="lg:text-lg">{`${user.city}, ${user.state} ${user.zip}`}</p>
                    </div>
                    <button
                        className="btn-shell mb-4 w-full -translate-y-[1px] !border !border-black !ring-transparent"
                        onClick={(ev) => {
                            setShowAddressForm(true);
                        }}>
                        Edit my address
                    </button>

                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            (cpapInCart || suppliesInCart) && !currentlyResupply
                                ? setCheckoutStep('resupply-signup')
                                : proceedToPayment();
                        }}>
                        {usingInsurance ? 'Confirm Order' : 'Continue'}
                    </button>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        street1: user.street1 || '',
                        street2: user.street2 || '',
                        city: user.city || '',
                        state: user.state || '',
                        zip: user.zip || '',
                        saveToAccount: true,
                    }}
                    onSubmit={addressSubmitHandler}
                    enableReinitialize={true}
                    validationSchema={checkoutAddressSchema}>
                    {({ dirty, isValid, isSubmitting }) => (
                        <Form className="space-y-6 border-8 border-purple-medium border-opacity-20 p-8 lg:p-14">
                            <CustomInput
                                theme="light"
                                name="street1"
                                label="Address line 1"
                                className="border-b-gray-darker lg:text-lg"
                            />
                            <CustomInput
                                theme="light"
                                name="street2"
                                label="Address line 2"
                                className="border-b-gray-darker lg:text-lg"
                            />
                            <CustomInput
                                theme="light"
                                name="city"
                                label="City"
                                className="border-b-gray-darker lg:text-lg"
                            />
                            <div className="space-y-6 lg:flex lg:space-y-0 lg:space-x-6">
                                <CustomReactSelect
                                    theme="light"
                                    name="state"
                                    placeholder="State"
                                    className="lg:flex-1 lg:text-lg"
                                    options={states}
                                />
                                <div className="lg:flex-1">
                                    <CustomInput
                                        theme="light"
                                        name="zip"
                                        label="Zip"
                                        className="border-b-gray-darker lg:text-lg"
                                    />
                                </div>
                            </div>
                            <CustomCheckbox
                                name="saveToAccount"
                                id="saveToAccount"
                                theme="light"
                                label="Save address to my account"
                            />
                            <button
                                className="btn-primary w-full"
                                type="submit"
                                disabled={!isValid || isSubmitting}>
                                {usingInsurance ? 'Confirm Order' : 'Continue'}
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </motion.div>
    );
}

export default Address;
