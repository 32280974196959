import { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteStatusFilter,
    setAvailableFilters,
    setFilterComponents,
    setStatusFilter,
} from '../../../features/admin/adminSlice';
import { adminSelectCustomStyles, otherFilters } from '../../../utils/constants';

const PatientFilter = ({ id, index, status, setPage, previousStatus, filters }) => {
    const dispatch = useDispatch();
    const { patientFilterComponents, activePatientFilters, availablePatientFilters } = useSelector(
        (state) => state.admin,
    );

    //Reused code, so created as its own function
    const clearOtherSearch = (statusKey) => {
        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: statusKey,
                value: null,
            }),
        );
    };

    function handleStatusChange(selectedOption) {
        //Remove status from pool of available statuses to filter for other filters
        const newAvailableFilters = availablePatientFilters.filter((filter) => {
            if (filter !== selectedOption && filters !== previousStatus) {
                return filter;
            } else return null;
        });

        if (previousStatus?.value) {
            //Set previous status to again be available in filter status options
            newAvailableFilters.push({ ...previousStatus, available: true });

            //If a filter exists, set to initial page
            if (filters.length > 0) {
                setPage(0);
            }

            //Clear out the searches that might have existed from the previous status
            if (otherFilters.includes(previousStatus?.value)) {
                clearOtherSearch(previousStatus?.value);
            } else {
                dispatch(
                    deleteStatusFilter({
                        type: 'activePatientFilters',
                        key: 'patientProfileSearch',
                        value: previousStatus.value,
                    }),
                );
            }
        }

        //Change the selected option to not be available in the filter status options
        newAvailableFilters.push({ ...selectedOption, available: false });
        dispatch(
            setAvailableFilters({ key: 'availablePatientFilters', value: newAvailableFilters }),
        );

        //Replace the current filter with one with updated status
        const newFilters = patientFilterComponents.filter(
            (statusFilter) => statusFilter.filterId !== id,
        );
        newFilters.splice(index, 0, {
            filterId: id,
            status: { ...selectedOption, available: false },
            previousStatus: selectedOption,
            filters: [],
        });

        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: newFilters }));
    }

    //Handles searches outside the patient profile
    function handleOtherSearch(option, statusKey) {
        if (option?.length === 0) {
            clearOtherSearch(statusKey);
        } else if (option?.length === 1) {
            dispatch(
                setStatusFilter({
                    type: 'activePatientFilters',
                    key: statusKey,
                    value: option[0].value,
                }),
            );
        } else if (option?.length > 1) {
            const multipleOptionsToSearch = { in: option.flatMap((filter) => filter.value) };
            dispatch(
                setStatusFilter({
                    type: 'activePatientFilters',
                    key: statusKey,
                    value: multipleOptionsToSearch,
                }),
            );
        }
    }

    //Handles searches inside the patient profile
    function handlePatientProfileSearch(option) {
        //Start with the current categories and filters
        const newPatientProfileSearch = { ...activePatientFilters.patientProfileSearch };

        //If the filters are changed to zero, delete the entire status category from the filters
        if (option?.length === 0) {
            delete newPatientProfileSearch[status.value];
        }
        //otherwise set the filters that are now in state to be dispatched
        else if (option?.length === 1) {
            newPatientProfileSearch[status.value] = option[0].value;
        } else if (option?.length > 1) {
            if (status.value === 'referFromOrganization') {
                newPatientProfileSearch['referFromOrganization'] = {
                    id: { in: option.flatMap((filter) => filter.value.id) },
                };
            } else {
                newPatientProfileSearch[status.value] = {
                    in: option.flatMap((filter) => filter.value),
                };
            }
        }

        //dispatch the changes
        dispatch(
            setStatusFilter({
                type: 'activePatientFilters',
                key: 'patientProfileSearch',
                value: newPatientProfileSearch,
            }),
        );
    }

    function handleFilterChange(selectedOption) {
        setPage(0);
        //Determine if it is not a patient profile search and handle it
        if (otherFilters.includes(status.value)) {
            handleOtherSearch(selectedOption, status.value);
            // Otherwise create categories and filters for patient profile
        } else {
            handlePatientProfileSearch(selectedOption);
        }

        //Replace the current filter with one with updated filters
        const newFilters = patientFilterComponents.filter(
            (statusFilter) => statusFilter.filterId !== id,
        );
        newFilters.splice(index, 0, {
            filterId: id,
            status: status,
            previousStatus: status,
            filters: selectedOption,
        });
        dispatch(setFilterComponents({ key: 'patientFilterComponents', value: newFilters }));
    }

    // Run dispatches if the filter is created by the LoadQuery component
    useEffect(() => {
        if (!status.value) return;
        if (otherFilters.includes(status.value)) {
            handleOtherSearch(filters, status.value);
        } else {
            handlePatientProfileSearch(filters);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Select
                options={availablePatientFilters.filter((filter) => filter.available)}
                className="basis-[94%] md:basis-[47%] md:pr-1"
                onChange={handleStatusChange}
                placeholder={'Select Status'}
                styles={adminSelectCustomStyles}
                value={status.value ? status : null}
            />
            <Select
                isDisabled={!status.value}
                options={status.statusOptions}
                className="basis-[94%] max-w-[94%] md:max-w-[47%] my-2 md:my-0 md:basis-[47%] md:pl-1"
                onChange={handleFilterChange}
                isMulti
                placeholder={!status.value ? '' : 'Select options'}
                styles={adminSelectCustomStyles}
                value={filters}
            />
        </>
    );
};

export default PatientFilter;
