import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { Formik, Form, useField } from 'formik';
import {
    previousQuestion,
    selectCurrentQuestionData,
    setAnythingElse,
    setFinalModalOpen,
    setResultScreen,
} from '../../features/assessment/assessmentSlice';
import { IoCloseSharp } from 'react-icons/io5';
import { AssessmentResult, Question } from '../../components/Assessment';
import { Modal } from '../../components';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import assessmentCalculatingAnimation from '../../utils/assessment-calculating.json';

export const TextareaField = (props) => {
    const [field, meta] = useField(props);

    return (
        <div>
            <textarea {...field} {...props}></textarea>
            {meta.touched && meta.error && (
                <p className="mt-4 text-xs font-bold text-red">{JSON.stringify(meta.error)}</p>
            )}
        </div>
    );
};

const CalculatingAssessment = () => {
    const dispatch = useDispatch();
    const assessmentState = useSelector((state) => state.assessment);

    useEffect(() => {
        let timeoutId = null;

        if (assessmentState.skipResultScreen) {
            dispatch(setResultScreen());
        } else {
            timeoutId = window.setTimeout(() => {
                dispatch(setResultScreen());
            }, 5000);
        }

        return () => {
            window.clearTimeout(timeoutId);
        };
        //eslint-disable-next-line
    }, [dispatch]);

    return (
        <motion.div
            initial={{ opacity: 0, translateX: '20%' }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: '-20%' }}
            transition={{ ease: 'linear' }}
            className="mx-auto max-w-2xl text-center">
            <Lottie
                animationData={assessmentCalculatingAnimation}
                loop={true}
                className="mx-auto max-w-md"
            />
            <h2 className="font-heading text-3xl text-white lg:text-8xl">Computing...</h2>
            <p className="text-gray-light lg:text-2xl">(Beep, boop, boop&hellip;)</p>
        </motion.div>
    );
};

const Assessment = () => {
    const navigate = useNavigate();
    const assessmentState = useSelector((state) => state.assessment);
    const currentQuestionData = useSelector(selectCurrentQuestionData);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' });

        if (assessmentState.currentQuestionIndex === 0) {
            ReactGA.event({
                category: 'User',
                action: 'Started assessment',
            });
        } else {
            if (assessmentState.questions[assessmentState.currentQuestionIndex]?.answerKey) {
                ReactGA.event({
                    category: 'User',
                    action: 'Viewed a question',
                    label: assessmentState.questions[assessmentState.currentQuestionIndex]
                        .answerKey,
                });
            }
        }

        //eslint-disable-next-line
    }, [assessmentState.currentQuestionIndex]);

    useEffect(() => {
        if (assessmentState.showResultScreen)
            ReactGA.event({
                category: 'User',
                action: 'Completed assessment',
            });
    }, [assessmentState.showResultScreen]);

    return (
        <div className="container">
            <nav
                className={
                    assessmentState.showSaveScreen
                        ? 'mb-4 xs:mb-8 flex justify-center !pb-0 pt-4 xs:pt-8 lg:pt-16'
                        : 'flex items-end justify-between py-4 pt-8 lg:py-9 lg:pt-16'
                }>
                {!assessmentState.showSaveScreen && (
                    <button
                        onClick={() => {
                            let testIndex = assessmentState.currentQuestionIndex;

                            testIndex--;

                            while (assessmentState.questions[testIndex]?.skip) testIndex--;

                            testIndex === -1 ? navigate(-1) : dispatch(previousQuestion());
                        }}
                        disabled={assessmentState.currentQuestionIndex === 0}
                        className={`block transition duration-300 ${
                            assessmentState.currentQuestionIndex === 0 ? 'opacity-0' : 'opacity-100'
                        }`}>
                        <LeftArrow />
                    </button>
                )}
                <Link to="/" className="mx-auto inline-block">
                    <Logo className="w-11 lg:w-16" />
                </Link>
                {!assessmentState.showSaveScreen && (
                    <NavLink
                        to="/"
                        className="group flex items-center font-heading leading-none tracking-widest text-offwhite">
                        <span className="translate-y-[1px] opacity-50 transition group-hover:-translate-x-2 group-hover:opacity-100">
                            exit
                        </span>
                        <IoCloseSharp className="text-3xl" />
                    </NavLink>
                )}
            </nav>

            <motion.div
                className={`mb-8 sm:mb-16 h-0.5 w-full bg-purple-medium ${
                    assessmentState.showSaveScreen ? 'hidden' : 'block'
                }`}>
                <div
                    className="h-0.5 bg-green-400 transition-all"
                    style={{
                        width:
                            Math.ceil(
                                (100 * (assessmentState.currentQuestionIndex + 1)) /
                                    assessmentState.questions.length,
                            ) + '%',
                    }}></div>
            </motion.div>

            <AnimatePresence mode="wait">
                {!assessmentState.showResultScreen && assessmentState.showSaveScreen && (
                    <CalculatingAssessment />
                )}
                {assessmentState.showResultScreen && <AssessmentResult />}
                {!assessmentState.showSaveScreen && !assessmentState.finalModalOpen && (
                    <Question
                        key={`question-${assessmentState.currentQuestionIndex}`}
                        questionData={currentQuestionData}
                    />
                )}
            </AnimatePresence>

            <Modal
                showClose={true}
                isOpen={assessmentState.finalModalOpen}
                closeHandler={() => {
                    ReactGA.event({
                        category: 'User',
                        action: 'Viewed "Anything Else" modal',
                        label: 'anythingelse_close',
                    });
                    dispatch(setFinalModalOpen(false));
                    dispatch(setAnythingElse(''));
                }}>
                <h2 className="mb-2 font-heading text-3xl text-gray-darker lg:mb-4 lg:text-5xl">
                    Is there anything else you would like us to know?
                </h2>
                <Formik
                    initialValues={{ anythingElse: '' }}
                    onSubmit={(data) => {
                        if (data.anythingElse.length > 0) {
                            ReactGA.event({
                                category: 'User',
                                action: 'Answered a Question',
                                label: 'anythingelse_fill',
                            });
                        } else {
                            ReactGA.event({
                                category: 'User',
                                action: 'Answered a Question',
                                label: 'anythingelse_proceed',
                            });
                        }
                        dispatch(setFinalModalOpen(false));
                        dispatch(setAnythingElse(data.anythingElse));
                    }}>
                    <Form>
                        <TextareaField
                            name="anythingElse"
                            id="anythingElse"
                            className="mb-8 h-40 w-full border border-gray-darker p-5 lg:mb-12 lg:px-6"
                            placeholder="Optional."
                        />
                        <button className="btn-primary w-full" type="submit">
                            Finish
                        </button>
                    </Form>
                </Formik>
            </Modal>
        </div>
    );
};

export default Assessment;
