import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { modalSelectCustomStyles } from '../../../utils/constants';
import { useAddOrderItemMutation } from '../../../app/services/admin/orders';
import { adminItemsSelector } from '../../../features/catalog/catalogSlice';
import Modal from '../../Modal/Modal';
import QuantityComponent from './QuantityComponent';

function AddItem({ patientId, orderId }) {
    const { catalogItems } = useSelector((state) => state.catalog);

    const [modalOpen, setModalOpen] = useState(false);
    const [addOrderItem] = useAddOrderItemMutation();
    const [category, setCategory] = useState(null);
    const [item, setItem] = useState(null);
    const [variation, setVariation] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [skuToSearch, setSkuToSearch] = useState('');

    const addItemOptions = useSelector(adminItemsSelector);

    //Makes sure that changing category removes the item from selection
    function handleCategoryChange(value) {
        setCategory(value);
        if (value?.items?.length === 1) {
            handleItemChange(value.items[0]);
        } else {
            setItem(null);
            setVariation(null);
        }
    }

    function handleItemChange(value) {
        setItem(value);
        if (value?.variations.length === 1) {
            setVariation(value?.variations[0]);
        } else setVariation(null);
    }

    //Clears out all the state so that reopening another Add Item does not hold data
    function handleClose() {
        setModalOpen(false);
        setCategory(null);
        setItem(null);
        setVariation(null);
        setQuantity(1);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const submitValues = {
            quantity: quantity,
            sku: variation.value.itemVariationData.sku,
        };

        addOrderItem({ orderId: orderId, userId: patientId, values: submitValues })
            .then(() => {
                toast.success('Item Added to Order');
                setModalOpen(false);
            })
            .catch((error) => toast.error(error));
    }

    function handleSkuSearch(e) {
        const itemBySku = catalogItems?.find((o) =>
            o?.itemData?.variations?.find(
                (v) => v.itemVariationData?.sku.toLowerCase() === skuToSearch.toLowerCase(),
            ),
        );
        if (!itemBySku) return toast.error('No item found');
        const category = addItemOptions.find((item) => item.id === itemBySku.itemData.categoryId);
        const item = category.items.find((item) => item.id === itemBySku.id);
        handleCategoryChange(category);
        handleItemChange(item);
        setVariation(
            item.variations.find(
                (variation) => variation.sku.toLowerCase() === skuToSearch.toLowerCase(),
            ),
        );
    }
    return (
        <>
            <button
                className="btn-primary-small w-1/3 ml-[1%] mb-2"
                onClick={() => setModalOpen(true)}>
                Add Item
            </button>
            <Modal showClose={false} isOpen={modalOpen} closeHandler={() => handleClose()}>
                <p className="font-heading text-2xl block leading-none my-8 bg-gray-light text-center py-2">
                    ADD ITEM
                </p>
                <div className="grid grid-cols-[180px_1fr] gap-x-4 h-[220px]">
                    <div className="space-y-2">
                        <input
                            type="text"
                            id="sku"
                            value={skuToSearch}
                            placeholder="SKU"
                            className="pl-2 border rounded h-[35px] w-[180px]"
                            onChange={(e) => setSkuToSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSkuSearch();
                                else if (e.key === 'Escape') setSkuToSearch('');
                            }}
                        />
                        <button className="btn-primary-small w-[180px]" onClick={handleSkuSearch}>
                            Search by SKU
                        </button>
                        {item?.value?.images?.length > 0 && (
                            <img
                                src={item.value.images[0]}
                                alt="Item"
                                className="mx-auto w-[120px]"
                            />
                        )}
                    </div>
                    <form className="w-full flex flex-col justify-between" onSubmit={handleSubmit}>
                        <div>
                            <div className="flex items-center justify-between">
                                <Select
                                    options={addItemOptions}
                                    onChange={handleCategoryChange}
                                    placeholder={'Select Category'}
                                    styles={modalSelectCustomStyles}
                                    value={category}
                                    menuPortalTarget={document.body}
                                    className="w-1/2"
                                />

                                <QuantityComponent quantity={quantity} setQuantity={setQuantity} />
                            </div>
                            {category && (
                                <Select
                                    options={category.items}
                                    onChange={handleItemChange}
                                    placeholder={'Select Item'}
                                    styles={modalSelectCustomStyles}
                                    value={item}
                                    menuPortalTarget={document.body}
                                    className="w-full pt-2"
                                />
                            )}
                            {item?.variations?.length > 0 && (
                                <Select
                                    options={item.variations}
                                    onChange={setVariation}
                                    placeholder={'Select Variation'}
                                    styles={modalSelectCustomStyles}
                                    value={variation}
                                    menuPortalTarget={document.body}
                                    className="w-full pt-2"
                                    getOptionLabel={(option) => option.label + ' ' + option.sku}
                                />
                            )}
                        </div>
                        <div className="flex">
                            <button
                                disabled={!variation}
                                type="submit"
                                className="btn-primary-small w-full text-base lg:text-lg">
                                Add Item
                            </button>
                            <button
                                type="reset"
                                onClick={() => handleClose()}
                                className="btn-secondary-small w-full text-base lg:text-lg ml-2">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
}

export default AddItem;
