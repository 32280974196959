import { Field, Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import {
    useResendVerificationEmailMutation,
    useUpdateUserMutation,
    useGetPatientFeedbackQuery,
    useManualEmailVerificationMutation,
} from '../../../app/services/admin/admin';
import {
    useRequestPasswordResetMutation,
    useUpdatePatientProfileMutation,
} from '../../../app/services/auth';
import {
    accountCreationStatuses,
    cpapOrderStatuses,
    dentalReferralStatusOptions,
    formatBirthdate,
    formatDate,
    formatPatientProfile,
    homeSleepStudyStatuses,
    mdStatuses,
    states,
    supplyOrdersStatuses,
    treatmentSupportStatuses,
    uploadedSleepStudyStatusOptions,
} from '../../../utils/constants';
import { CustomInput, CustomReactSelect } from '../../InputComponents';

import { updatePatientSchema } from '../../../utils/schemas';
import { useGetPatientOrderHistoryQuery } from '../../../app/services/admin/orders';

const GEMTab = ({ patient, refetch }) => {
    const [updateUser, { error }] = useUpdateUserMutation();
    const [updatePatientProfile, { error: patientProfileUpdateError }] =
        useUpdatePatientProfileMutation();
    const [requestPasswordReset, { isLoading }] = useRequestPasswordResetMutation();
    const [resendVerificationEmail] = useResendVerificationEmailMutation();
    const [maunallyVerifyEmail] = useManualEmailVerificationMutation();

    //Load order history to determine what stage the patient is at
    const { data: orderHistory } = useGetPatientOrderHistoryQuery(patient.id);
    const hasOrderedCpap = orderHistory?.some(
        (order) =>
            order.orderStatus === 'COMPLETED' &&
            order.orderItems.some((p) => p.itemCategory === 'CPAP_SUPPLIES'),
    );

    //Load patient feedback to display
    const { data: feedback } = useGetPatientFeedbackQuery(patient?.id);

    const initialValues = {
        firstName: patient.firstName,
        lastName: patient.lastName,
        birthdate: formatBirthdate(patient.birthdate),
        email: patient.unverifiedEmail || patient.email,
        phone: patient.phone,
        street1: patient.street1 || '',
        street2: patient.street2 || '',
        city: patient.city || '',
        state: patient.state || '',
        zip: patient.zip || '',
    };

    const passwordResetHandler = async (ev) => {
        const result = await requestPasswordReset({
            email: patient.unverifiedEmail || patient.email,
            redirectUrl: window.location.origin + '/reset-password',
        });

        result.error
            ? toast.error('Password reset unsuccessful', { theme: 'dark' })
            : toast.success('Password reset email sent', { theme: 'dark' });
    };

    const submitHandler = async (data) => {
        const keysToDelete = [];

        for (const key in data) {
            if (key === 'birthdate') {
                const bd = new Date(data[key]).toISOString().split('T')[0] + 'T00:00:00.000Z';

                if (bd === patient[key]) keysToDelete.push(key);
            }

            if (data[key] === patient[key]) keysToDelete.push(key);
        }

        // Only send fields that have been changed to the API
        const payload = Object.fromEntries(
            Object.entries(data).filter(([key]) => !keysToDelete.includes(key)),
        );

        if (payload.hasOwnProperty('birthdate'))
            payload['birthdate'] =
                new Date(payload['birthdate']).toISOString().split('T')[0] + 'T00:00:00.000Z';

        if (Object.keys(payload).indexOf('email') > -1)
            payload.redirectUrl = window.location.origin + '/sign-in';

        await updateUser({ id: patient.id, ...payload });

        toast.success('Submitted!', { theme: 'dark' });
    };

    const profileUpdateHandler = async (data) => {
        await updatePatientProfile({
            id: patient.patientProfile.id,
            userId: patient.id,
            patientFields: { ...data },
        });

        toast.success('Patient profile updated', { theme: 'colored' });
    };

    const verificationEmailHandler = async (ev) => {
        const result = await resendVerificationEmail({
            userId: patient.id,
            redirectUrl: window.location.origin + '/sign-in',
        });

        result.error
            ? toast.error('Verification email not sent', { theme: 'dark' })
            : toast.success('Verification email sent to ' + patient.email, {
                  theme: 'dark',
              });
    };

    const manuallyVerifyHandler = async (ev) => {
        const result = await maunallyVerifyEmail(patient.id);
        if (!result.error) {
            toast.success('Email manually verified');
            refetch();
        } else {
            toast.error('Failed to manually verify email', { theme: 'dark' });
        }
    };

    return (
        <motion.div
            className=""
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="xl:flex xl:space-x-10">
                <div className="flex-1">
                    {error?.data && (
                        <p className="font-bold text-red">{JSON.stringify(error.data)}</p>
                    )}
                    {patientProfileUpdateError?.data && (
                        <p className="font-bold text-red">
                            {JSON.stringify(patientProfileUpdateError.data)}
                        </p>
                    )}
                    {patient.patientProfile && (
                        <div className="mb-10 text-sm grid grid-cols-3 gap-4">
                            <div className="col-span-3 lg:col-span-2">
                                <p className="mb-4 text-xl font-bold">Patient profile data:</p>

                                {formatPatientProfile(patient.patientProfile)}
                            </div>
                            <div className="space-y-4 col-span-3 lg:col-span-1 mt-4 lg:mt-0">
                                <p className="text-xl font-bold">Other actions</p>
                                <button
                                    className="btn-primary-small text-xl w-full"
                                    onClick={passwordResetHandler}
                                    disabled={isLoading}>
                                    Send password reset email
                                </button>
                                {!patient.verifiedAt && (
                                    <button
                                        className="btn-primary-small text-xl w-full"
                                        onClick={verificationEmailHandler}
                                        disabled={isLoading}>
                                        Resend verification email
                                    </button>
                                )}
                                {!patient.verifiedAt && (
                                    <button
                                        className="btn-primary-small text-xl w-full"
                                        onClick={manuallyVerifyHandler}
                                        disabled={isLoading}>
                                        Manually verify user
                                    </button>
                                )}
                            </div>

                            <hr className="border-gray-six col-span-3 my-5" />

                            <div className="col-span-3 space-y-2">
                                <p className="mb-4 text-xl font-bold">Patient NPS:</p>
                                {feedback?.results?.map((feedback) => (
                                    <ul className="grid grid-cols-3 gap-4">
                                        <li>
                                            <span className="text-gray-light">Score</span>:{' '}
                                            {feedback.npsScore ?? 'N/A'}
                                        </li>
                                        <li>
                                            <span className="text-gray-light">Date Captured</span>:{' '}
                                            {feedback.createdAt
                                                ? formatDate(feedback.createdAt)
                                                : 'N/A'}
                                        </li>
                                        <li>
                                            <span className="text-gray-light">Open Feedback</span>:{' '}
                                            {feedback.comment ?? 'N/A'}
                                        </li>
                                    </ul>
                                ))}
                            </div>

                            <hr className="border-gray-six col-span-3 my-5" />

                            <Formik
                                initialValues={{
                                    accountCreationStatus:
                                        patient.patientProfile.accountCreationStatus,
                                    dentalReferralStatus:
                                        patient.patientProfile.dentalReferralStatus,
                                    homeSleepStudyStatus:
                                        patient.patientProfile.homeSleepStudyStatus,
                                    cpapOrderStatus: patient.patientProfile.cpapOrderStatus,
                                    mdStatus: patient.patientProfile.mdStatus,
                                    treatmentSupportStatus:
                                        patient.patientProfile.treatmentSupportStatus,
                                    supplyOrdersStatus: patient.patientProfile.supplyOrdersStatus,
                                    uploadedSleepStudyStatus:
                                        patient.patientProfile.uploadedSleepStudyStatus,
                                }}
                                onSubmit={profileUpdateHandler}
                                enableReinitialize={true}>
                                {({ dirty, isValid, isSubmitting }) => (
                                    <Form className="col-span-3">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Account status:
                                                </span>
                                                <Field
                                                    name="accountCreationStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {accountCreationStatuses.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Dental referral status:
                                                </span>
                                                <Field
                                                    name="dentalReferralStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {dentalReferralStatusOptions.map((opt, idx) => (
                                                        <option key={idx} value={opt.value}>
                                                            {opt.label}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>

                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Home Sleep Study Status:
                                                </span>
                                                <Field
                                                    name="homeSleepStudyStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {homeSleepStudyStatuses.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    CPAP order status:
                                                </span>
                                                <Field
                                                    name="cpapOrderStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {cpapOrderStatuses.map((opt, idx) => (
                                                        <option
                                                            key={idx}
                                                            value={opt}
                                                            disabled={
                                                                hasOrderedCpap && opt === 'REFERRED'
                                                            }>
                                                            {opt}
                                                            {hasOrderedCpap &&
                                                                opt === 'REFERRED' &&
                                                                ' (Already Ordered)'}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Clinical Status:
                                                </span>
                                                <Field
                                                    name="mdStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {mdStatuses.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x- col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Treatment Support Status:
                                                </span>
                                                <Field
                                                    name="treatmentSupportStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {treatmentSupportStatuses.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Supply Order Status:
                                                </span>
                                                <Field
                                                    name="supplyOrdersStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {supplyOrdersStatuses.map((opt, idx) => (
                                                        <option key={idx} value={opt}>
                                                            {opt}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className="flex items-center justify-between space-x-4 col-span-2 lg:col-span-1">
                                                <span className="text-gray-light">
                                                    Uploaded Sleep Study Status:
                                                </span>
                                                <Field
                                                    name="uploadedSleepStudyStatus"
                                                    as="select"
                                                    className="flex-1 border-b border-white/25 bg-transparent p-4 py-2 pl-0 hover:cursor-pointer w-full">
                                                    {uploadedSleepStudyStatusOptions.map(
                                                        (opt, idx) => (
                                                            <option key={idx} value={opt.value}>
                                                                {opt.label}
                                                            </option>
                                                        ),
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <button
                                            className="btn-primary mt-6 w-full"
                                            type="submit"
                                            disabled={!dirty || !isValid || isSubmitting}>
                                            Update patient profile
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                    <p className="mb-4 text-xl font-bold">Update patient information</p>
                    <Formik
                        validationSchema={updatePatientSchema}
                        initialValues={initialValues}
                        onSubmit={submitHandler}
                        enableReinitialize={true}>
                        {({ dirty, isValid, isSubmitting }) => (
                            <Form className="grid grid-cols-2 gap-6">
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        First name
                                    </span>
                                    <CustomInput
                                        name="firstName"
                                        label="First Name"
                                        rootClass="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        Last name
                                    </span>
                                    <CustomInput
                                        name="lastName"
                                        label="Last Name"
                                        rootClass="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">DOB</span>
                                    <CustomInput name="birthdate" label="DOB" rootClass="flex-1" />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        {patient.unverifiedEmail ? 'Email (unverified)' : 'Email'}
                                    </span>
                                    <CustomInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        autoComplete="username"
                                        rootClass="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        Phone
                                    </span>
                                    <CustomInput name="phone" label="Phone" rootClass="flex-1" />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        Street 1
                                    </span>
                                    <CustomInput
                                        name="street1"
                                        label="Street 1"
                                        rootClass="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        Street 2
                                    </span>
                                    <CustomInput
                                        name="street2"
                                        label="Street 2"
                                        rootClass="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        City
                                    </span>
                                    <CustomInput name="city" label="City" rootClass="flex-1" />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">
                                        State
                                    </span>
                                    <CustomReactSelect
                                        name="state"
                                        placeholder="State"
                                        options={states}
                                        className="flex-1"
                                    />
                                </div>
                                <div className="col-span-2 md:col-span-1 flex items-center space-x-4">
                                    <span className="w-16 md:w-32 font-heading text-gray">Zip</span>
                                    <CustomInput name="zip" label="Zip" rootClass="flex-1" />
                                </div>

                                <button
                                    className="btn-primary w-full col-span-2"
                                    type="submit"
                                    disabled={!dirty || !isValid || isSubmitting}>
                                    Update patient
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </motion.div>
    );
};

export default GEMTab;
